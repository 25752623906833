import React, { useState } from "react"
import { Box, Typography } from "@mui/material"
import {
  PopoverStyled,
  SearchAndActionStyled,
  TabsContainer,
} from "./chats.styles"
import ChatButton from "./components/chat-button/chat-button"
import { Close } from "@mui/icons-material"
import SearchAndAction from "../../components/search-and-filter/search-and-filter"
import { TabContext, TabPanel } from "@mui/lab"
import CustomTabs from "../../components/custom-tabs/custom-tabs"
import ChatCard from "./components/chat-card/chat-card"
import outgoingCall from "../../assets/imgs/phone-outgoing.svg"
import ChatDetails from "./components/chat-details/chat-details"
import { ChatCardData } from "./types"

const Chats: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setTimeout(() => {
      resetSelectedChat()
    }, 500)
  }

  const [activeTab, setActiveTab] = useState("All")

  const [selectedChat, setSelectedChat] = useState<ChatCardData | null>(null)

  const resetSelectedChat = () => {
    setSelectedChat(null)
  }

  const tabs = ["All", "Unread", "Answered", "Not answered "]

  const handleTabChange = (
    event: React.SyntheticEvent,
    newActiveTab: string
  ) => {
    setActiveTab(newActiveTab)
  }

  const chats: ChatCardData[] = [
    {
      id: 1,
      unread: true,
      icon: outgoingCall,
      title: "John Doe",
      datetime: "2024-08-20 12:12:12",
      content:
        "Amet minim mollit non deserunt ullamco est sit aliqua dolor do...",
    },
    {
      id: 2,
      unread: true,
      icon: outgoingCall,
      title: "(443) 457 - 8761",
      datetime: "2024-02-12 14:12:12",
      content:
        "Amet minim mollit non deserunt ullamco est sit aliqua dolor do...",
    },
    {
      id: 3,
      unread: false,
      icon: outgoingCall,
      title: "(443) 457 - 8761",
      datetime: "2024-02-12 16:12:12",
      content:
        "Amet minim mollit non deserunt ullamco est sit aliqua dolor do...",
    },
  ]

  return (
    <>
      <PopoverStyled
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {!selectedChat && (
          <>
            <Box className="chats-header">
              <Box className="d-flex align-items-center justify-content-between">
                <Typography className="title">Chat Messages</Typography>
                <span className="close-icon" onClick={handleClose}>
                  <Close />
                </span>
              </Box>
              <SearchAndActionStyled>
                <SearchAndAction searchPlaceholder="Search chat here..." />
              </SearchAndActionStyled>
            </Box>
            <Box className="chats-body">
              <TabsContainer className="d-flex flex-column overflow-hidden">
                <TabContext value={activeTab}>
                  <Box className="tab-list d-flex w-100 align-items-center">
                    <CustomTabs handleTabChange={handleTabChange} tabs={tabs} />
                  </Box>
                  <TabPanel className="overflow-y-auto" value="All">
                    {chats.map((chat) => (
                      <ChatCard onClick={(data: ChatCardData) => setSelectedChat(data)} data={chat} />
                    ))}
                  </TabPanel>
                </TabContext>
              </TabsContainer>
            </Box>
          </>
        )}
        {selectedChat && <ChatDetails
          selectedChat={selectedChat}
          onBack={resetSelectedChat}
          onClose={handleClose}
        />}
      </PopoverStyled>
      <ChatButton
        onClick={handleClick}
        open={open}
        count={chats.filter((x) => x.unread).length}
      />
    </>
  )
}

export default Chats
