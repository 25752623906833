import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { EditContactStyled, SwipeableDrawerStyled } from './edit-contacts.styles'
import TextInputFormField from '../../components/form-fields/text-input-field/text-input-form-field'
import SelectFormField from '../../components/form-fields/select-field/select-form-field'
import { useForm } from 'react-hook-form'
import { Close } from '@mui/icons-material'
import ModalForm from '../../components/modal-form/modal-form'
import { CONTACT_TYPES } from '../../constants'
import { ModalFormButtonsBox } from '../../components/modal-form/modal-form.styles'
import { Button } from '../../components/button/button'
import { useAppDispatch } from '../../store/hooks'
import { addNotification } from "../../store/slices/ui-slice"
import { v4 as uuid } from "uuid"
import { EditContactProps } from './types'
import { useDoctorsQuery, useOfficesQuery, useUpdateContactMutation } from '../../generated/graphql'


const EditContact: React.FC<EditContactProps> = ({
    id,
    name,
    doctorId,
    officeId,
    isVisible,
    callerPhoneNumber,
    onClose,
    refetch
}) => {

    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState<boolean | undefined>(false)
    const openNotifications = () => {
    }

    const DrawerPaperProps: any = {
        style: { position: 'absolute' }
    }

    const DrawerModalProps: any = {
        container: document.querySelector('.app'),
        style: { position: 'absolute' }
    }

    const { control, watch, formState } = useForm<{
        officeId: string,
        doctorId: string,
        type: string,
        name: string,
    }>({
        mode: "all",
        defaultValues: {
            name: name ?? "",
            officeId: officeId ?? "",
            doctorId: doctorId ?? "",
        }
    })
    console.log('cal', callerPhoneNumber)
    const { data } = useDoctorsQuery({
        variables: { first: 1000, page: 1 }
    })

    const { data: officesData } = useOfficesQuery({
        variables: { first: 1000, page: 1 }
    })

    const doctorOptions = data?.doctors.data.map(doctor => {
        return { label: doctor.name, value: doctor.id }
    })

    const officesOptions = officesData?.offices.data.map(office => {
        return { label: office.name, value: office.id }
    })

    const [addContact] = useUpdateContactMutation({
        variables: {
            ...watch(),
            id: id?.toString(),
            officeId: Number(watch().officeId),
            doctorId: Number(watch().doctorId),
            phoneNumber: callerPhoneNumber
        }
    })

    const handleSubmit = async () => {
        try {
            setIsLoading(true)
            await addContact()
            refetch && refetch()
            dispatch(
                addNotification({
                    id: uuid(),
                    type: "success",
                    message: "Contact updated sucessfully.",
                })
            )
        } catch (err: any) {
            dispatch(
                addNotification({
                    id: uuid(),
                    type: "error",
                    message: err?.message,
                })
            )
        } finally {
            setIsLoading(false)
        }
        onClose && onClose()
    }

    const isValid = formState?.isValid

    return (
        <>
            <SwipeableDrawerStyled
                anchor="right"
                open={isVisible}
                onClose={onClose}
                onOpen={openNotifications}
                PaperProps={DrawerPaperProps}
                ModalProps={DrawerModalProps}
                transitionDuration={300}
            >
                <EditContactStyled>
                    <Box className="d-flex align-items-center justify-content-between header">
                        <Typography className="title">
                            Add to contacts
                        </Typography>
                        <span className="close-icon d-inline-flex" onClick={onClose}>
                            <Close />
                        </span>
                    </Box>
                    <Box className="fields-wrapper">
                        <ModalForm>
                            <TextInputFormField
                                control={control}
                                label="Name"
                                placeholder="Enter here"
                                name="name"
                            />
                            <SelectFormField
                                name="type"
                                control={control}
                                placeholder="Select here"
                                label="Type"
                                options={CONTACT_TYPES.map(x => { return { label: x, value: x } })}
                            />
                            <SelectFormField
                                name="officeId"
                                control={control}
                                placeholder="Select here"
                                label="Office"
                                options={officesOptions || []}
                            />
                            <SelectFormField
                                name="doctorId"
                                control={control}
                                placeholder="Select here"
                                label="Doctor"
                                options={doctorOptions || []}
                            />
                            <ModalFormButtonsBox>
                                <Button disabled={!isValid} isLoading={isLoading} onClick={handleSubmit}>Save</Button>
                            </ModalFormButtonsBox>
                        </ModalForm>
                    </Box>
                </EditContactStyled>
            </SwipeableDrawerStyled>
        </>
    )
}

export default EditContact
