import React from "react"
import Box from '@mui/material/Box';
import { ProjectStatsIconWithCountStyled } from './project-stats-icon-with-count.styles';
import { ProjectStatsIconWithCountProps } from "../../../../types/types";

const ProjectStatsIconWithCount: React.FC<ProjectStatsIconWithCountProps> = ({ icon, count }) => {
    return (
        <ProjectStatsIconWithCountStyled className="d-flex">
            <Box className="d-inline-block icon-container">
                <img src={icon} alt="Project stat" width="20" height="18px" className="d-block" />
                {count !== undefined && <Box className="d-inline-flex justify-content-center align-items-center notification-badge">{count}</Box>}
            </Box>
        </ProjectStatsIconWithCountStyled>
    )
}

export default ProjectStatsIconWithCount