//lib
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { useForm } from "react-hook-form";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

//src
import logo from "../../assets/imgs/logo.png";
import { SignUpStyled } from "./signup.styles";
import { Button } from "../../components/button/button";
import TextInputFormField from "../../components/form-fields/text-input-field/text-input-form-field";
import PhoneNumberFormField from "../../components/form-fields/phone-number-field/phone-number-form-field";
import { signUpFormValidation } from "../../utils/validations";
import { useAppDispatch } from "../../store/hooks";
import { addNotification } from "../../store/slices/ui-slice";
import { setUserInSession } from "../../utils/common-utils";
import { useSignUpMutation } from "../../generated/graphql";

const Signup: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [state, setState] = useState<{ email: string; token: string }>({
    email: "",
    token: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { control, watch, formState, setValue } = useForm({
    mode: "all",
    resolver: yupResolver(signUpFormValidation()),
  });

  const [signUp] = useSignUpMutation({
    variables: { ...watch(), token: state.token },
  });

  const onSignup = async () => {
    try {
      setIsLoading(true);
      const res = await signUp();
      if (res?.data?.signUp.token && res?.data?.signUp.user) {
        setUserInSession(res.data.signUp);
        dispatch(
          addNotification({
            id: uuid(),
            type: "success",
            message: "Signed up successfully.",
          })
        );
        navigate("/dashboard");
      } else {
        const error = res?.data?.signUp.error;
        dispatch(
          addNotification({
            id: uuid(),
            type: "error",
            message: error || "Something went wrong, please try again.",
          })
        );
      }
    } catch (err: any) {
      dispatch(
        addNotification({
          id: uuid(),
          type: "error",
          message: err?.message,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const params = new URL(document.location.toString()).searchParams;
    const email = params.get("email");
    const token = params.get("token");
    if (!email || !token) {
      onLoginClick();
    }
    if (email) {
      setState((s: any) => {
        return { ...s, email };
      });
      setValue("email", email);
    }
    if (token) {
      setState((s: any) => {
        return { ...s, token };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoginClick = () => {
    navigate("/login");
  };

  const isValid = formState?.isValid;
  return (
    <SignUpStyled>
      <div className="logo">
        <img src={logo} alt={"asd"} />
      </div>
      <div className="heading">
        <Typography
          variant="h1"
          color={"primary"}
          sx={{ margin: "35px 0px 20px 0px" }}
        >
          Signup
        </Typography>
      </div>

      <div className="form">
        <TextInputFormField
          name="first_name"
          label="First Name"
          control={control}
          placeholder="Enter here"
        />
        <TextInputFormField
          name="last_name"
          label="Last Name"
          control={control}
          placeholder="Enter here"
        />
        <TextInputFormField
          name="email"
          label="Email"
          control={control}
          placeholder="Enter here"
          InputProps={{
            readOnly: Boolean(state.email),
          }}
        />

        <PhoneNumberFormField
          name="phone_number"
          label={"Phone Number"}
          control={control}
        />

        <TextInputFormField
          name="password"
          label="Password"
          control={control}
          inputType="password"
          placeholder="Enter here"
        />
        <TextInputFormField
          name="confirm"
          label="Confirm Password"
          inputType="password"
          control={control}
          placeholder="Enter here"
        />
      </div>

      <div className="btn">
        <Button
          sx={{ width: "100%" }}
          isLoading={isLoading}
          disabled={!isValid}
          onClick={onSignup}
        >
          Signup
        </Button>
      </div>

      <div className="caption-cta">
        <Typography variant="body3" color={"secondary"}>
          Already have an account?
        </Typography>
        <Button variant="text" onClick={onLoginClick}>
          Login
        </Button>
      </div>
    </SignUpStyled>
  );
};

export default Signup;
