import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'white',
  boxShadow: 'none',
}));

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  padding: '13px 16px',
  minHeight: 'auto !important',
}));

export const Logo = styled('img')({
  height: '22px',
  display: 'block',
});

export const StyledMenu = {
  padding: 0
}

export const StyledMenuIteam = {
  fontSize: '12px',
  minHeight: 'auto'
}