import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { MenuDropdownProps } from "../../types/types";
import { MenuDropdownStyled } from "./menu.styles";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const MenuDropdown: React.FC<MenuDropdownProps> = ({ items, linkToOpen, data, isOpened }) => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (e: React.MouseEvent<HTMLElement>) => {
    isOpened && isOpened(true)
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    isOpened && isOpened(false)
    setAnchorEl(null);
  };

  return (
    <MenuDropdownStyled>
      <div
        onClick={handleMenu}
        className={`${anchorEl ? "dropdown-opened" : ""
          } d-flex align-items-center cs-dropdown`}
      >
        {linkToOpen ? linkToOpen : <MoreVertIcon className="action-icon" />}
      </div>
      <Menu
        className="cs-dropdown-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            disableRipple={true}
            disableTouchRipple={true}
            disabled={item.isDisabled}
            onClick={() => { item.action(data); handleClose() }}
          >
            {item.frontIcon || ""}
            <Typography className={item.key === "logout" ? "color-danger" : ""}>
              {item.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </MenuDropdownStyled>
  );
};

export default MenuDropdown;
