import { Box, TableBody, TableFooter, TableHead } from "@mui/material";
import styled from "styled-components";

export const TableComponentStyled = styled.div`
    padding: 10px 0;
    .MuiPaper-root {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: none;
    }

    .text-bold-warning {
        font-weight: 600;
        font-size: 14px;
        color: #B89724;
    }
    .text-bold-success {
        font-weight: 600;
        font-size: 14px;
        color: #24B845;
    }
    .text-bold-inactive {
        font-weight: 600;
        font-size: 14px;
        color: #70767B;
    }
`

export const TableHeadStyled = styled(TableHead)`
    .MuiTableCell-root {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 12px;
        background-color: #E2E1FF;
        color: #606060;
        height: 30px;
        padding: 0 16px;
        vertical-align: middle;
        text-align: left;
    }
`

export const TableBodyStyled = styled(TableBody)`
    position: relative;
    .MuiTableRow-root:not(.loader-row) {
        .MuiTableCell-root {
            padding: 0 16px;
            height: 48px;
            vertical-align: middle;
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            font-size: 14px;
            color: #202020;
        }
    }
    .MuiTableRow-root.loader-row {
        .MuiTableCell-root {
            padding: 0;
            margin: 0;
            height: 0;
        }
    }
`

export const TableFooterStyled = styled(TableFooter)`
    .MuiTableRow-root {
        background: #f9f9f9;
        .MuiTablePagination-root {
            border: none !important;
            padding-top: 43px !important;
            padding-bottom: 32px !important;
            .MuiToolbar-root {
                padding: 0;
                align-items: center;
                justify-content: space-between;
                .MuiTablePagination-spacer {
                    display: none;
                }
                .MuiTablePagination-displayedRows {
                    font-weight: 400;
                    font-size: 14px;
                    color: #404040;
                    margin: 0;
                }
            }
        }
    }
`

export const TablePaginationPagesControlsStyled = styled(Box)`
    flex-shrink: 0;
    height: 49px;
    background: #F0F4FA;
    display: inline-flex;
    align-items: center;
    border-radius: 10px;
    gap: 12px;
    .MuiButtonBase-root {
        color: #2460B8 !important;
        &.single-page {
            width: 32px;
            height: 32px;
            font-weight: 400;
            font-size: 14px;
            border-radius: 10px;
            &.active {
                color: #fff !important;
                background: #2460B8;
            }
        }
    }
`

export const LoaderWrapper = styled(Box)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
`