import styled from "styled-components";
import searchIcon from "../../assets/icons/search-icon.svg"

export const SearchAndActionStyled = styled.div`
    padding: 8px 0 12px 0;

    .search-input {
        width: 261px;
        height: 40px;
        border-radius: 10px;
        border: 1px solid #CFCFCF;
        padding: 10px 35px 10px 12px;
        outline: none;
        background-image: url(${searchIcon});
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: calc(100% - 12px);
        background-size: 18px;
        font-weight: 400;
        font-size: 14px;
    }

    &.admin-search {
        .search-input {
            width: 427px;
        }
    }

    .buttons-wrapper {
        gap: 8px;
        .filter-button {
            width: 40px;
            height: 40px;
            border: 1px solid #CFCFCF;
            outline: none;
            background-color: #ffffff;
            border-radius: 10px;
        }

        .pri-button {
            padding: 10px 18px;
            background: #2460B8;
            border-radius: 8px;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 16px;
            color: #FFFFFF;
            outline: none;
            border: none;
            width: 141px;
            height: 44px;
            text-align: center;
            cursor: pointer;
        }
    }
`