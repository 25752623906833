import React, { useEffect, useState } from "react"
import SearchAndAction from "../../../../../components/search-and-filter/search-and-filter"
import TableComponent from "../../../../../components/table-component/table-component"
import MenuDropdown from "../../../../../components/menu/menu"
import { TableMenuDropdownStyled } from "./accounts.styles"
import { ACCOUNT_STATUSES, ACTION_TYPES } from "../../../../../constants"
import RevokeAccessModal from "./revoke-access-modal/revoke-access-modal"
import InviteUsersModal from "./invite-users-modal/invite-users-modal"
import GiveAccessModal from "./give-access-modal/give-access-modal"
import { columns } from "./constants"
import { PaginatorInfo } from "../../../../../types/types"
import useDebouncedCallback from "../../../../../hooks/useDebouncedCallback"
import { Account, useUsersQuery } from "../../../../../generated/graphql"


const Accounts: React.FC = () => {
  const [modalData, setModalData] = useState<Account | undefined>()
  const [accountsData, setAccountsData] = useState<any[] | undefined>([])
  const [paginatorInfo, setPaginatorInfo] = useState<PaginatorInfo>()

  const [actionType, setActionType] = useState<string>("")

  const handleAction = () => {
    setModalData(undefined)
    setActionType(ACTION_TYPES.INVITE_USER)
  }

  const resetActionType = () => {
    // To make sure fading out effect is completed first
    setTimeout(() => {
      setActionType("")
    }, 500)
  }

  const { loading, error, data, refetch } = useUsersQuery({
    variables: { first: 10, page: 1 },
    notifyOnNetworkStatusChange: true
  })

  const handleSearch = useDebouncedCallback((e: React.BaseSyntheticEvent) => {
    refetch({
      page: 1,
      first: 10,
      search: e.target.value
    })
  })

  const STATUSES: any = {
    "pending": <span className="text-bold-warning">Pending</span>,
    "active": <span className="text-bold-success">Active</span>,
    "not active": <span className="text-bold-inactive">Not Active</span>,
  }

  useEffect(() => {
    if (data) {
      const accountsDataMapped: any[] | undefined = data?.users?.data.map(account => {
        const dropdownData = [
          {
            label: account.status === ACCOUNT_STATUSES.ACTIVE ? "Access revoke" : "Give access",
            key: account.status === ACCOUNT_STATUSES.ACTIVE ? "access-revoke" : "give-access",
            action: (data: any) => {
              setModalData(data)
              setActionType(account.status === ACCOUNT_STATUSES.ACTIVE ? ACTION_TYPES.REVOKE_ACCESS : ACTION_TYPES.GIVE_ACCESS)
            },
            isDisabled: false,
          },
        ]
        const teams = account?.teams?.length
        return {
          ...account,
          noOfTeams: (Number(teams) < 10 ? String(teams).padStart(2, '0') : 0),
          status: STATUSES[account.status],
          actions: (
            account.status !== ACCOUNT_STATUSES.PENDING && <TableMenuDropdownStyled>
              <MenuDropdown items={dropdownData} data={account} />
            </TableMenuDropdownStyled>
          ),
        }
      })
      setAccountsData(accountsDataMapped)
      setPaginatorInfo({
        ...(data?.users?.paginatorInfo || {}),
        onPageChange: newPage => {
          refetch({
            first: 10,
            page: newPage
          })
        }
      })
    }
  }, [data])

  if (error) return <p>Error: {error.message}</p>

  return (
    <>
      <SearchAndAction
        showActionButton={true}
        actionButtonTitle="Invite user"
        onSearch={handleSearch}
        actionButtonFn={handleAction}
      />
      <TableComponent
        isLoading={loading}
        paginatorInfo={paginatorInfo}
        columns={columns}
        data={accountsData}
      />
      {actionType === ACTION_TYPES.INVITE_USER && (
        <InviteUsersModal refetch={refetch} onClose={resetActionType} />
      )}
      {actionType === ACTION_TYPES.REVOKE_ACCESS && modalData && (
        <RevokeAccessModal refetch={refetch} onClose={resetActionType} data={modalData} />
      )}
      {actionType === ACTION_TYPES.GIVE_ACCESS && modalData && (
        <GiveAccessModal refetch={refetch} onClose={resetActionType} data={modalData} />
      )}
    </>
  )
}

export default Accounts
