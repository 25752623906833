import React from "react";
import { appTheme } from "./custom-theme";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material";

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const theme = createTheme(appTheme);
  return (
    <>
      <StyledThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
      </StyledThemeProvider>
    </>
  );
};

export default ThemeProvider;
