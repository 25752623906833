export const columns = [
  {
    id: "name",
    label: "Team name",
  },
  {
    id: "type",
    label: "Type",
  },
  {
    id: "extensions",
    label: "Extensions",
  },
  {
    id: "locations",
    label: "Locations",
  },
  {
    id: "actions",
    label: "",
  },
]
