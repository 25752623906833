import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import MainPage from "../pages/calls-dashboard/calls-dashboard";
import ProtectedRoute from "../utils/ProtectedRoute";
import AppLayout from "../layouts/app-layout";
import { useAppDispatch } from "../store/hooks";
import { setAdmin } from "../store/slices/admin-slice";

const UserRoutes: React.FC = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      setAdmin(false)
    )
  }, [])
  return (
    <Routes>
      <Route path="/" element={
        <ProtectedRoute>
          <AppLayout>
            <MainPage />
          </AppLayout>
        </ProtectedRoute>
      } />
    </Routes>
  );
};

export default UserRoutes;
