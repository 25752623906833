import { Alert, Snackbar } from "@mui/material";
import { NotificationProps } from "../../types/types";

const Notification = (props: NotificationProps) => {
  //   const [open, setOpen] = useState(true);
  const { message, type, onClose } = props;
  return (
    <Snackbar open autoHideDuration={6000} onClose={() => onClose()}>
      <Alert
        onClose={onClose}
        severity={type}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
