import styled from "styled-components";

export const LoginStyled = styled.div`
  padding: 18px;
  .logo {
    margin-top: 40px;
    img {
      height: 39px;
      width: 135px;
    }
  }

  .heading {
    /* margin-top: 35px; */
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .forgot-cta {
    display: flex;
    justify-content: end;
    margin-top: -5px;
    .btn-style {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }
  .btn {
    margin: 20px 0px 10px 0px;
  }

  .caption-cta {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }
`;
