import styled from "styled-components";
import Box from '@mui/material/Box';


export const AdminDashboardContainer = styled.div`
    padding: 12px 16px;
`

export const TabsContainer = styled(Box)`
    .MuiTabs-flexContainer {
        gap: 8px;
    }
    .tab-list {
        border-bottom: 1px solid #e0e0e0;
    }
    .MuiTab-root {
        min-width: 74px;
        height: 42px;
        font-weight: 500;
        color: #70767B;
        font-size: 12px;
        text-transform: none;
        &.Mui-selected {
            color: #2460B8 !important;
            font-weight: 600 !important;
        }
    }
`