import React, { useState, useEffect } from 'react'
import { v4 as uuid } from "uuid";

import ModalComponent from "../../../../../../components/modal-component/modal-component"
import { Button } from "../../../../../../components/button/button"
import { RemoveDoctorModalBodyStyled } from './remove-doctor-modal.styles'
import { Typography } from '@mui/material'
import { useAppDispatch } from '../../../../../../store/hooks'
import { addNotification } from '../../../../../../store/slices/ui-slice'
import { RemoveDoctorModalProps } from './types';

const ModalBody: React.FC<RemoveDoctorModalProps> = ({ data, refetch, onClose }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const dispatch = useAppDispatch();

    const handleClick = async () => {
        try {
            refetch && refetch()
            dispatch(
                addNotification({
                    id: uuid(),
                    type: "success",
                    message: "Doctor removed successfully.",
                })
            );
        } catch (err: any) {
            dispatch(
                addNotification({
                    id: uuid(),
                    type: "error",
                    message: err?.message,
                })
            );
        } finally {
            setIsLoading(false)
        }
        onClose && onClose()
    }
    return (
        <RemoveDoctorModalBodyStyled>
            <Typography className='content'>Are you sure to delete of <b>{`${data.firstName} ${data.lastName}`}</b> account and information?</Typography>
            <Button className="confirm-button" isLoading={isLoading} onClick={handleClick}>Confirm</Button>
        </RemoveDoctorModalBodyStyled>
    )
}

const RemoveDoctorModal: React.FC<RemoveDoctorModalProps> = ({ data, onClose, refetch }) => {
    const [openModal, setOpenModal] = useState<boolean>(false)

    useEffect(() => {
        setOpenModal(true)
    }, [])

    const handleClose = () => {
        setOpenModal(false)
        onClose && onClose()
    }

    return (
        <div>
            <ModalComponent
                title="Remove Doctor"
                content={<ModalBody data={data} refetch={refetch} onClose={onClose} />}
                open={openModal}
                onClose={handleClose}
            />
        </div>
    )
}

export default RemoveDoctorModal
