import { Box } from "@mui/material";
import styled from "styled-components";

export const ChatCardStyled = styled(Box)`
    background-color: #F5F5F5;
    cursor: pointer;
    &.unread {
        background-color: #F0F4FA;
    }
    border-radius: 10px;
    padding: 12px;
    display: flex;
    gap: 8px;
    .icon-holder {
        .icon {
            width: 32px;
            height: 32px;
            background-color: #fff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 16px;
                height: 16px;
                display: block;
            }
        }
    }
    .content-holder {
        .phone-number-and-time {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 3px;
            .phone-number {
                color: #202020;
                font-weight: 500;
                font-size: 12px;
            }
            .time {
                color: #6E7378;
                font-weight: 500;
                font-size: 10px;
            }
        }
        .message {
            font-size: 11px;
            font-weight: 400;
            color: #6E7378;
        }
    }
`