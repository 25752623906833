import { Controller } from "react-hook-form"
import TagsInputField from "./tags-input-field"
import { AdditionalFormFieldProps, TagsFieldProps } from "../common-types"

export const TagsInputFormField: React.FC<TagsFieldProps & AdditionalFormFieldProps> = (props) => {
  const { name, defaultValue, control } = props
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TagsInputField
          {...props}
          value={value}
          onChange={onChange}
          error={error}
        />
      )}
    />
  )
}

export default TagsInputFormField
