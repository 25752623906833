import * as yup from "yup";

const validationMessage = {
  nameReg: () => `Only letters, number and underscores are allowed`,
  required: (name: string) => `${name} is a required field.`,
  lettersReg: () => `Only letters are allowed`,
  maxStrSize: (name: string) => `${name} must be at most 31 characters`,
  minStrSize: (name: string) => `${name} must be at least 3 characters`,
  mustMatch: () => 'Password and Confirm password must match',
  email: (name: string) => `${name} must be a valid email`
};

export const signUpFormValidation = () => {
  return yup.object().shape({
    first_name: yup
      .string()
      .matches(/^[A-z]+$/g, { excludeEmptyString: true, message: validationMessage.lettersReg() })
      .required(validationMessage.required("First Name"))
      .max(31, validationMessage.maxStrSize("First Name"))
      .min(3, validationMessage.minStrSize("First Name")),
    last_name: yup
      .string()
      .matches(/^[A-z]+$/g, { excludeEmptyString: true, message: validationMessage.lettersReg() })
      .required(validationMessage.required("Last Name"))
      .max(31, validationMessage.maxStrSize("Last Name"))
      .min(3, validationMessage.minStrSize("Last Name")),

    email: yup.string().email(validationMessage.email("Email")).required("Please enter a valid email."),
    phone_number: yup
      .string()
      .min(4, validationMessage.minStrSize("Phone Number"))
      .required("Phone Number is a required Field"),

    password: yup.string().required(validationMessage.required("Password")),
    confirm: yup.string().oneOf([yup.ref('password')], validationMessage.mustMatch()).required(),
  });
};

export const loginFormValidation = () => {
  return yup.object().shape({
    email: yup.string().email(validationMessage.email("Email")).required("Please enter a valid email."),
    password: yup.string().required(validationMessage.required("Password")),
  });
};

export const forgotPasswordValidation = () => {
  return yup.object().shape({
    email: yup.string().email(validationMessage.email("Email")).required("Please enter a valid email."),
  });
};

export const addEditTeamFormValidation = () => {
  return yup.object().shape({
    name: yup.string().required(validationMessage.required('Name')),
    type: yup.string().required(validationMessage.required("Type")),
    extensions: yup.string().required(validationMessage.required('Extensions')),
    locations: yup.string().required(validationMessage.required('Locations')),
    users: yup.string().nullable(),
    administrator: yup.string().nullable()
  });
};

export const addAccountFormValidation = () => {
  return yup.object().shape({
    administrator: yup.array().required()
  });
};

export const addContactFormValidation = () => {
  return yup.object().shape({
    phone_number: yup.string()
      .min(4, validationMessage.minStrSize("Phone number"))
      .required(validationMessage.required("Phone number"))
  })
}

export const inviteUsersToAccountsFormValidation = () => {
  return yup.object().shape({
    invitee: yup.string()
      .email(validationMessage.email("Email"))
      .required(validationMessage.required("Email"))
  })
}

export const addEditDoctorFormValidation = () => {
  return yup.object().shape({
    first_name: yup.string().required(validationMessage.required('First Name')),
    last_name: yup.string().required(validationMessage.required('Last Name')),
    title: yup.string().required(validationMessage.required("Title")),
    zip_code: yup.string().required(validationMessage.required("Zip Code")),
    liscense_number: yup.string().required(validationMessage.required("Liscense Number")),
    email: yup.string()
      .email(validationMessage.email("Email"))
      .required(validationMessage.required("Email")),
    doctor_phone_number: yup.string()
      .min(4, validationMessage.minStrSize("Phone number"))
      .required(validationMessage.required("Phone number"))
  });
};

export const updatePasswordValidation = () => {
  return yup.object().shape({
    password: yup.string().required(validationMessage.required("Password")),
    confirm: yup.string().oneOf([yup.ref('password')], validationMessage.mustMatch()).required(),
  });
};