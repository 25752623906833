import React, { useEffect, useRef } from 'react'
import { Box, Typography } from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'
import CloseIcon from '@mui/icons-material/Close'
import { ChatDetailsStyled } from './chat-details.styles'
import messageIcon from '../../../../assets/icons/message-icon.svg'
import { ChatCardData, ChatDetailsData } from '../../types'
import moment from 'moment'

const data: ChatDetailsData[] = [
  {
    id: 1,
    chatId: 1,
    text: 'Hello!',
    isSender: true,
    date: "2024-08-21 21:00:12"
  },
  {
    id: 2,
    chatId: 1,
    text: 'Hi there!',
    isSender: false,
    date: "2024-08-01 21:00:12"
  },
  {
    id: 3,
    chatId: 1,
    text: 'How are you?',
    isSender: true,
    date: "2024-08-01 21:00:12"
  },
  {
    id: 4,
    chatId: 1,
    text: 'Good, thanks!',
    isSender: false,
    date: "2024-02-01 21:00:12"
  }
]

const ChatDetails: React.FC<{
  onClose: () => void
  onBack: () => void
  selectedChat: ChatCardData
}> = ({
  onClose,
  onBack,
  selectedChat
}) => {
    const chatId = selectedChat.id
    const messages: ChatDetailsData[] = data.filter(x => x.chatId === chatId).sort((a, b) => moment(a.date).diff(moment(b.date)))

    const chatsBodyRef = useRef<HTMLDivElement>(null)

    // Scroll to bottom when the component mounts or when new messages are added
    useEffect(() => {
      if (chatsBodyRef.current) {
        chatsBodyRef.current.scrollTop = chatsBodyRef.current.scrollHeight
      }
    }, [messages])

    const formatTime = (date: string) => {
      if (moment(date).isSame(moment(), 'day')) {
        return moment(date).format('h:mma')
      }
      return moment(date).format('dddd h:mma')
    }

    const formatDividerDate = (date: string) => {
      if (moment(date).isSame(moment(), 'day')) {
        return 'Today'
      }
      return moment(date).format('MMMM Do, YYYY')
    }

    return (
      <ChatDetailsStyled>
        <Box className="chats-header">
          <Box className="d-flex align-items-center">
            <Box className="d-flex align-items-center back-and-title-wrapper">
              <span className="back-icon" onClick={onBack}>
                <ArrowBackRoundedIcon />
              </span>
              <Typography className="title">{selectedChat.title}</Typography>
            </Box>
            <Box className="d-flex align-items-center ml-auto minimize-and-close-wrapper">
              <span className="minimize-icon" onClick={onClose}>
                <RemoveRoundedIcon />
              </span>
              <span className="close-icon" onClick={onClose}>
                <CloseIcon />
              </span>
            </Box>
          </Box>
        </Box>
        <Box className="chats-body" ref={chatsBodyRef}>
          {messages.map((message, index) => {
            const showDivider =
              index === 0 ||
              !moment(messages[index - 1].date).isSame(message.date, 'day')

            return (
              <React.Fragment key={message.id}>
                {showDivider && (
                  <Box className="time-divider">
                    <span></span>
                    <Typography>{formatDividerDate(message.date)}</Typography>
                    <span></span>
                  </Box>
                )}
                <Box className={`message-container ${message.isSender ? 'sent-message' : ''}`}>
                  <Box className="chat-message">
                    <Box className="message-header">
                      <Typography className="sender-name">{message.isSender ? 'You' : selectedChat.title}</Typography>
                      <Typography className="sending-time">{formatTime(message.date)}</Typography>
                    </Box>
                    <Box className="message-body">
                      <Typography className="message-content">{message.text}</Typography>
                    </Box>
                  </Box>
                </Box>
              </React.Fragment>
            )
          })}
        </Box>
        <Box className="chats-footer">
          <input className="message-input" placeholder="Send a message" />
          <button className="send-message-btn">
            <img src={messageIcon} alt="Send message" />
          </button>
        </Box>
      </ChatDetailsStyled>
    )
  }

export default ChatDetails
