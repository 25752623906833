import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type Account = {
  __typename?: 'Account';
  email: Scalars['String']['output'];
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  teams?: Maybe<Array<AccountTeam>>;
};

export type AccountTeam = {
  __typename?: 'AccountTeam';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Call = {
  __typename?: 'Call';
  callType: Scalars['String']['output'];
  callingTime: Scalars['String']['output'];
  cod: Scalars['Boolean']['output'];
  contactId: Scalars['Int']['output'];
  contactName: Scalars['String']['output'];
  contactPhoneNumber: Scalars['String']['output'];
  doctorId: Scalars['Int']['output'];
  doctorName: Scalars['String']['output'];
  extensionName: Scalars['String']['output'];
  extensionNumber: Scalars['String']['output'];
  hasAttachment: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  invoiceCount: Scalars['Int']['output'];
  invoiceNetTotal: Scalars['Float']['output'];
  isAnswered: Scalars['Boolean']['output'];
  isOngoing: Scalars['Boolean']['output'];
  isOutgoing: Scalars['Boolean']['output'];
  isReturned: Scalars['Boolean']['output'];
  officeId: Scalars['Int']['output'];
  officeName: Scalars['String']['output'];
  orderShippedCount: Scalars['Int']['output'];
  orderStartedCount: Scalars['Int']['output'];
  route: Scalars['String']['output'];
  salesPerson: Scalars['String']['output'];
  scanner: Scalars['String']['output'];
  statusChips: Array<StatusChip>;
  value: Scalars['Int']['output'];
  voicemailLeft: Scalars['Boolean']['output'];
};

export type CallControl = {
  __typename?: 'CallControl';
  isAnswered: Scalars['Boolean']['output'];
  isOngoing: Scalars['Boolean']['output'];
  isOutgoing: Scalars['Boolean']['output'];
  isReturned: Scalars['Boolean']['output'];
  voicemailLeft: Scalars['Boolean']['output'];
};

export type CallListResponse = {
  __typename?: 'CallListResponse';
  list: Array<Call>;
  total: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type CallsOverView = {
  __typename?: 'CallsOverView';
  incomingCallsTotal: Scalars['Int']['output'];
  missedCallsTotal: Scalars['Int']['output'];
  ongoingCallsTotal: Scalars['Int']['output'];
  outgoingCallsTotal: Scalars['Int']['output'];
  returnedCallsTotal: Scalars['Int']['output'];
};

export type ContactDoctor = {
  __typename?: 'ContactDoctor';
  name: Scalars['String']['output'];
};

export type ContactOffice = {
  __typename?: 'ContactOffice';
  name: Scalars['String']['output'];
};

export type CreateUpdateTeamResponse = {
  __typename?: 'CreateUpdateTeamResponse';
  data: Team;
  details?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

export type DeleteTeamResponse = {
  __typename?: 'DeleteTeamResponse';
  details?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

export type Doctor = {
  __typename?: 'Doctor';
  created: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  customerAccountId: Scalars['String']['output'];
  deleted: Scalars['Boolean']['output'];
  doctorCode: Scalars['String']['output'];
  email: Scalars['String']['output'];
  externalReference: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  lastUpdated: Scalars['String']['output'];
  lastUpdatedBy: Scalars['String']['output'];
  licenceNo: Scalars['String']['output'];
  middleName: Scalars['String']['output'];
  mobile: Scalars['String']['output'];
  name: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  suffix: Scalars['String']['output'];
  telephone: Scalars['String']['output'];
  timeStamp: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type DoctorsResponse = {
  __typename?: 'DoctorsResponse';
  data: Array<Doctor>;
  paginatorInfo: PaginatorInfo;
};

export type Invitee = {
  __typename?: 'Invitee';
  invited_by?: Maybe<Scalars['String']['output']>;
  invitee?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type InviteeResponse = {
  __typename?: 'InviteeResponse';
  details?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  invitee?: Maybe<Invitee>;
  message?: Maybe<Scalars['String']['output']>;
};

export type LabtracData = {
  __typename?: 'LabtracData';
  cod: Scalars['Boolean']['output'];
  creditHold: Scalars['Boolean']['output'];
  invoiceCount: Scalars['Int']['output'];
  invoiceNetTotal: Scalars['Float']['output'];
  ordersShippedCount: Scalars['Int']['output'];
  ordersStartedCount: Scalars['Int']['output'];
  route: Scalars['String']['output'];
  salesPerson: Scalars['String']['output'];
  scanner: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  error: Scalars['String']['output'];
  token: Scalars['String']['output'];
  user: LoginUser;
};

export type LoginUser = {
  __typename?: 'LoginUser';
  email: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  id: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createTeam: CreateUpdateTeamResponse;
  deleteTeam: DeleteTeamResponse;
  invitee: InviteeResponse;
  login: LoginResponse;
  resetPassword: ResetPasswordResponse;
  signUp: SignUpResponse;
  updateContact: UpdateContactResponse;
  updatePassword: UpdatePasswordResponse;
  updateTeam: CreateUpdateTeamResponse;
  updateUserStatus: UpdateUserStatusResponse;
};


export type MutationCreateTeamArgs = {
  administrator: Array<Scalars['String']['input']>;
  company_id: Scalars['Int']['input'];
  extensions: Scalars['String']['input'];
  locations: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  users: Array<Scalars['String']['input']>;
};


export type MutationDeleteTeamArgs = {
  id: Scalars['Int']['input'];
};


export type MutationInviteeArgs = {
  invited_by: Scalars['Int']['input'];
  invitee: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationSignUpArgs = {
  confirm: Scalars['String']['input'];
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone_number: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationUpdateContactArgs = {
  doctorId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdatePasswordArgs = {
  confirmPassword: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationUpdateTeamArgs = {
  administrator: Array<Scalars['String']['input']>;
  company_id: Scalars['Int']['input'];
  extensions: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  locations: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  users: Array<Scalars['String']['input']>;
};


export type MutationUpdateUserStatusArgs = {
  id: Scalars['ID']['input'];
  status: Scalars['String']['input'];
};

export type Notification = {
  __typename?: 'Notification';
  doctorName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
  time: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type NotificationsListResponse = {
  __typename?: 'NotificationsListResponse';
  list: Array<Notification>;
  total: Scalars['Int']['output'];
};

export type Office = {
  __typename?: 'Office';
  address: Scalars['String']['output'];
  altRun2: Scalars['String']['output'];
  altRun3: Scalars['String']['output'];
  codePrefix: Scalars['String']['output'];
  created: Scalars['String']['output'];
  deleted: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  fax: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  practiceGroupId: Scalars['String']['output'];
  runNo: Scalars['String']['output'];
  telephone: Scalars['String']['output'];
};

export type OfficesResponse = {
  __typename?: 'OfficesResponse';
  data: Array<Office>;
  paginatorInfo: PaginatorInfo;
};

export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  currentPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  callList: Array<CallListResponse>;
  callsOverView: Array<CallsOverView>;
  doctors: DoctorsResponse;
  notificationsList: NotificationsListResponse;
  offices: OfficesResponse;
  teams: TeamsResponse;
  users: UsersResponse;
};


export type QueryCallListArgs = {
  callerIdNum?: InputMaybe<Scalars['String']['input']>;
  containerId?: InputMaybe<Scalars['String']['input']>;
  fromDate?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  showInbound?: InputMaybe<Scalars['Boolean']['input']>;
  showInternal?: InputMaybe<Scalars['Boolean']['input']>;
  showOutbound?: InputMaybe<Scalars['Boolean']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDoctorsArgs = {
  first: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOfficesArgs = {
  first: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTeamsArgs = {
  first: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUsersArgs = {
  first: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ResetPassword = {
  __typename?: 'ResetPassword';
  email: Scalars['String']['output'];
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  data: ResetPassword;
  details?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  status: Scalars['Int']['output'];
};

export type SignUpResponse = {
  __typename?: 'SignUpResponse';
  error: Scalars['String']['output'];
  token: Scalars['String']['output'];
  user: SignUpUser;
};

export type SignUpUser = {
  __typename?: 'SignUpUser';
  email: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  id: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type StatusChip = {
  __typename?: 'StatusChip';
  label: Scalars['String']['output'];
  variant: Scalars['String']['output'];
};

export type Team = {
  __typename?: 'Team';
  company_id?: Maybe<Scalars['Int']['output']>;
  extensions: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  locations: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  users?: Maybe<Array<User>>;
};

export type TeamsResponse = {
  __typename?: 'TeamsResponse';
  data: Array<Team>;
  paginatorInfo: PaginatorInfo;
};

export type UpdateContactResponse = {
  __typename?: 'UpdateContactResponse';
  doctor: ContactDoctor;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  office: ContactOffice;
  phoneNumber: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type UpdatePassword = {
  __typename?: 'UpdatePassword';
  email: Scalars['String']['output'];
};

export type UpdatePasswordResponse = {
  __typename?: 'UpdatePasswordResponse';
  data: UpdatePassword;
  details?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  status: Scalars['Int']['output'];
};

export type UpdateUserStatusResponse = {
  __typename?: 'UpdateUserStatusResponse';
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  is_administrator?: Maybe<Scalars['Int']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
};

export type UsersResponse = {
  __typename?: 'UsersResponse';
  data: Array<Account>;
  paginatorInfo: PaginatorInfo;
};

export type InviteeMutationVariables = Exact<{
  invited_by: Scalars['Int']['input'];
  invitee: Scalars['String']['input'];
}>;


export type InviteeMutation = { __typename?: 'Mutation', invitee: { __typename?: 'InviteeResponse', message?: string | null, error?: string | null, details?: string | null, invitee?: { __typename?: 'Invitee', invited_by?: string | null, invitee?: string | null, token?: string | null, status?: string | null } | null } };

export type UpdateUserStatusMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  status: Scalars['String']['input'];
}>;


export type UpdateUserStatusMutation = { __typename?: 'Mutation', updateUserStatus: { __typename?: 'UpdateUserStatusResponse', id?: number | null, first_name?: string | null, last_name?: string | null, email?: string | null, status?: string | null } };

export type SignUpMutationVariables = Exact<{
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  confirm: Scalars['String']['input'];
  phone_number: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;


export type SignUpMutation = { __typename?: 'Mutation', signUp: { __typename?: 'SignUpResponse', error: string, token: string, user: { __typename?: 'SignUpUser', id: string, first_name: string, last_name: string, email: string, status: string } } };

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', token: string, error: string, user: { __typename?: 'LoginUser', id: string, first_name: string, last_name: string, email: string } } };

export type UpdatePasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  confirmPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;


export type UpdatePasswordMutation = { __typename?: 'Mutation', updatePassword: { __typename?: 'UpdatePasswordResponse', message: string, error?: string | null, details?: string | null, status: number, data: { __typename?: 'UpdatePassword', email: string } } };

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'ResetPasswordResponse', message: string, error?: string | null, details?: string | null, status: number, data: { __typename?: 'ResetPassword', email: string } } };

export type UpdateContactMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['Int']['input']>;
  doctorId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UpdateContactMutation = { __typename?: 'Mutation', updateContact: { __typename?: 'UpdateContactResponse', id: string, name: string, type: string, phoneNumber: string, office: { __typename?: 'ContactOffice', name: string }, doctor: { __typename?: 'ContactDoctor', name: string } } };

export type CreateTeamMutationVariables = Exact<{
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  extensions: Scalars['String']['input'];
  company_id: Scalars['Int']['input'];
  locations: Scalars['String']['input'];
  users: Array<Scalars['String']['input']> | Scalars['String']['input'];
  administrator: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type CreateTeamMutation = { __typename?: 'Mutation', createTeam: { __typename?: 'CreateUpdateTeamResponse', status?: number | null, message?: string | null, details?: Array<string | null> | null, data: { __typename?: 'Team', id: string, name: string, type: string, extensions: string, locations: string, users?: Array<{ __typename?: 'User', id: string, email: string, is_administrator?: number | null }> | null } } };

export type UpdateTeamMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  extensions: Scalars['String']['input'];
  company_id: Scalars['Int']['input'];
  locations: Scalars['String']['input'];
  users: Array<Scalars['String']['input']> | Scalars['String']['input'];
  administrator: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type UpdateTeamMutation = { __typename?: 'Mutation', updateTeam: { __typename?: 'CreateUpdateTeamResponse', status?: number | null, message?: string | null, details?: Array<string | null> | null, data: { __typename?: 'Team', id: string, name: string, type: string, extensions: string, locations: string, users?: Array<{ __typename?: 'User', id: string, email: string, is_administrator?: number | null }> | null } } };

export type DeleteTeamMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteTeamMutation = { __typename?: 'Mutation', deleteTeam: { __typename?: 'DeleteTeamResponse', message?: string | null, error?: string | null, details?: string | null, status?: number | null } };

export type UsersQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UsersResponse', data: Array<{ __typename?: 'Account', id: string, first_name?: string | null, last_name?: string | null, email: string, status: string, teams?: Array<{ __typename?: 'AccountTeam', id?: number | null, name?: string | null }> | null }>, paginatorInfo: { __typename?: 'PaginatorInfo', currentPage: number, lastPage: number, total: number } } };

export type CallsOverViewQueryVariables = Exact<{ [key: string]: never; }>;


export type CallsOverViewQuery = { __typename?: 'Query', callsOverView: Array<{ __typename?: 'CallsOverView', ongoingCallsTotal: number, missedCallsTotal: number, returnedCallsTotal: number, incomingCallsTotal: number, outgoingCallsTotal: number }> };

export type CallListQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  fromDate?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
  containerId?: InputMaybe<Scalars['String']['input']>;
  callerIdNum?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
  showInbound?: InputMaybe<Scalars['Boolean']['input']>;
  showOutbound?: InputMaybe<Scalars['Boolean']['input']>;
  showInternal?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CallListQuery = { __typename?: 'Query', callList: Array<{ __typename?: 'CallListResponse', total: number, totalPages: number, list: Array<{ __typename?: 'Call', id: number, doctorId: number, officeId: number, contactId: number, doctorName: string, officeName: string, contactName: string, contactPhoneNumber: string, extensionName: string, extensionNumber: string, callingTime: string, callType: string, hasAttachment: boolean, isOutgoing: boolean, isOngoing: boolean, isAnswered: boolean, isReturned: boolean, voicemailLeft: boolean, invoiceCount: number, invoiceNetTotal: number, orderStartedCount: number, orderShippedCount: number, value: number, cod: boolean, route: string, scanner: string, salesPerson: string, statusChips: Array<{ __typename?: 'StatusChip', variant: string, label: string }> }> }> };

export type DoctorsQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type DoctorsQuery = { __typename?: 'Query', doctors: { __typename?: 'DoctorsResponse', data: Array<{ __typename?: 'Doctor', id: string, reference: string, externalReference: string, name: string, deleted: boolean, created: string, createdBy: string, lastUpdated: string, lastUpdatedBy: string, timeStamp: string, doctorCode: string, licenceNo: string, title: string, firstName: string, middleName: string, lastName: string, suffix: string, email: string, telephone: string, mobile: string, customerAccountId: string }>, paginatorInfo: { __typename?: 'PaginatorInfo', currentPage: number, lastPage: number, total: number } } };

export type NotificationsListQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationsListQuery = { __typename?: 'Query', notificationsList: { __typename?: 'NotificationsListResponse', total: number, list: Array<{ __typename?: 'Notification', id: string, type: string, doctorName: string, phoneNumber: string, time: string, message?: string | null }> } };

export type OfficesQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type OfficesQuery = { __typename?: 'Query', offices: { __typename?: 'OfficesResponse', data: Array<{ __typename?: 'Office', id: string, name: string, telephone: string, fax: string, email: string, deleted: boolean, created: string, runNo: string, altRun2: string, altRun3: string, practiceGroupId: string, codePrefix: string, address: string }>, paginatorInfo: { __typename?: 'PaginatorInfo', currentPage: number, lastPage: number, total: number } } };

export type TeamsQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type TeamsQuery = { __typename?: 'Query', teams: { __typename?: 'TeamsResponse', data: Array<{ __typename?: 'Team', id: string, name: string, type: string, extensions: string, locations: string, company_id?: number | null, users?: Array<{ __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null, email: string, is_administrator?: number | null }> | null }>, paginatorInfo: { __typename?: 'PaginatorInfo', currentPage: number, lastPage: number, total: number } } };


export const InviteeDocument = gql`
    mutation invitee($invited_by: Int!, $invitee: String!) {
  invitee(invited_by: $invited_by, invitee: $invitee) {
    message
    invitee {
      invited_by
      invitee
      token
      status
    }
    error
    details
  }
}
    `;
export type InviteeMutationFn = Apollo.MutationFunction<InviteeMutation, InviteeMutationVariables>;

/**
 * __useInviteeMutation__
 *
 * To run a mutation, you first call `useInviteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteeMutation, { data, loading, error }] = useInviteeMutation({
 *   variables: {
 *      invited_by: // value for 'invited_by'
 *      invitee: // value for 'invitee'
 *   },
 * });
 */
export function useInviteeMutation(baseOptions?: Apollo.MutationHookOptions<InviteeMutation, InviteeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteeMutation, InviteeMutationVariables>(InviteeDocument, options);
      }
export type InviteeMutationHookResult = ReturnType<typeof useInviteeMutation>;
export type InviteeMutationResult = Apollo.MutationResult<InviteeMutation>;
export type InviteeMutationOptions = Apollo.BaseMutationOptions<InviteeMutation, InviteeMutationVariables>;
export const UpdateUserStatusDocument = gql`
    mutation updateUserStatus($id: ID!, $status: String!) {
  updateUserStatus(id: $id, status: $status) {
    id
    first_name
    last_name
    email
    status
  }
}
    `;
export type UpdateUserStatusMutationFn = Apollo.MutationFunction<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>;

/**
 * __useUpdateUserStatusMutation__
 *
 * To run a mutation, you first call `useUpdateUserStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserStatusMutation, { data, loading, error }] = useUpdateUserStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateUserStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>(UpdateUserStatusDocument, options);
      }
export type UpdateUserStatusMutationHookResult = ReturnType<typeof useUpdateUserStatusMutation>;
export type UpdateUserStatusMutationResult = Apollo.MutationResult<UpdateUserStatusMutation>;
export type UpdateUserStatusMutationOptions = Apollo.BaseMutationOptions<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>;
export const SignUpDocument = gql`
    mutation signUp($first_name: String!, $last_name: String!, $email: String!, $password: String!, $confirm: String!, $phone_number: String!, $token: String!) {
  signUp(
    first_name: $first_name
    last_name: $last_name
    email: $email
    password: $password
    confirm: $confirm
    phone_number: $phone_number
    token: $token
  ) {
    error
    user {
      id
      first_name
      last_name
      email
      status
    }
    token
  }
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      confirm: // value for 'confirm'
 *      phone_number: // value for 'phone_number'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    user {
      id
      first_name
      last_name
      email
    }
    token
    error
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation updatePassword($email: String!, $password: String!, $confirmPassword: String!, $token: String!) {
  updatePassword(
    email: $email
    password: $password
    confirmPassword: $confirmPassword
    token: $token
  ) {
    message
    data {
      ... on UpdatePassword {
        email
      }
    }
    error
    details
    status
  }
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      confirmPassword: // value for 'confirmPassword'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($email: String!) {
  resetPassword(email: $email) {
    message
    data {
      ... on ResetPassword {
        email
      }
    }
    error
    details
    status
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const UpdateContactDocument = gql`
    mutation updateContact($id: ID!, $name: String, $type: String, $phoneNumber: String, $officeId: Int, $doctorId: Int) {
  updateContact(
    id: $id
    name: $name
    type: $type
    phoneNumber: $phoneNumber
    officeId: $officeId
    doctorId: $doctorId
  ) {
    id
    name
    type
    phoneNumber
    office {
      name
    }
    doctor {
      name
    }
  }
}
    `;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      phoneNumber: // value for 'phoneNumber'
 *      officeId: // value for 'officeId'
 *      doctorId: // value for 'doctorId'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, options);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const CreateTeamDocument = gql`
    mutation createTeam($name: String!, $type: String!, $extensions: String!, $company_id: Int!, $locations: String!, $users: [String!]!, $administrator: [String!]!) {
  createTeam(
    name: $name
    type: $type
    extensions: $extensions
    company_id: $company_id
    locations: $locations
    users: $users
    administrator: $administrator
  ) {
    status
    message
    details
    data {
      ... on Team {
        id
        name
        type
        extensions
        locations
        users {
          id
          email
          is_administrator
        }
      }
    }
  }
}
    `;
export type CreateTeamMutationFn = Apollo.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      extensions: // value for 'extensions'
 *      company_id: // value for 'company_id'
 *      locations: // value for 'locations'
 *      users: // value for 'users'
 *      administrator: // value for 'administrator'
 *   },
 * });
 */
export function useCreateTeamMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument, options);
      }
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const UpdateTeamDocument = gql`
    mutation UpdateTeam($id: ID!, $name: String!, $type: String!, $extensions: String!, $company_id: Int!, $locations: String!, $users: [String!]!, $administrator: [String!]!) {
  updateTeam(
    id: $id
    name: $name
    type: $type
    extensions: $extensions
    company_id: $company_id
    locations: $locations
    users: $users
    administrator: $administrator
  ) {
    status
    message
    details
    data {
      ... on Team {
        id
        name
        type
        extensions
        locations
        users {
          id
          email
          is_administrator
        }
      }
    }
  }
}
    `;
export type UpdateTeamMutationFn = Apollo.MutationFunction<UpdateTeamMutation, UpdateTeamMutationVariables>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      extensions: // value for 'extensions'
 *      company_id: // value for 'company_id'
 *      locations: // value for 'locations'
 *      users: // value for 'users'
 *      administrator: // value for 'administrator'
 *   },
 * });
 */
export function useUpdateTeamMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamMutation, UpdateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(UpdateTeamDocument, options);
      }
export type UpdateTeamMutationHookResult = ReturnType<typeof useUpdateTeamMutation>;
export type UpdateTeamMutationResult = Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<UpdateTeamMutation, UpdateTeamMutationVariables>;
export const DeleteTeamDocument = gql`
    mutation deleteTeam($id: Int!) {
  deleteTeam(id: $id) {
    message
    error
    details
    status
  }
}
    `;
export type DeleteTeamMutationFn = Apollo.MutationFunction<DeleteTeamMutation, DeleteTeamMutationVariables>;

/**
 * __useDeleteTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMutation, { data, loading, error }] = useDeleteTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTeamMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamMutation, DeleteTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamMutation, DeleteTeamMutationVariables>(DeleteTeamDocument, options);
      }
export type DeleteTeamMutationHookResult = ReturnType<typeof useDeleteTeamMutation>;
export type DeleteTeamMutationResult = Apollo.MutationResult<DeleteTeamMutation>;
export type DeleteTeamMutationOptions = Apollo.BaseMutationOptions<DeleteTeamMutation, DeleteTeamMutationVariables>;
export const UsersDocument = gql`
    query users($first: Int!, $page: Int!, $search: String) {
  users(first: $first, page: $page, search: $search) {
    data {
      id
      first_name
      last_name
      email
      status
      teams {
        id
        name
      }
    }
    paginatorInfo {
      currentPage
      lastPage
      total
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables> & ({ variables: UsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export function useUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const CallsOverViewDocument = gql`
    query callsOverView {
  callsOverView {
    ongoingCallsTotal
    missedCallsTotal
    returnedCallsTotal
    incomingCallsTotal
    outgoingCallsTotal
  }
}
    `;

/**
 * __useCallsOverViewQuery__
 *
 * To run a query within a React component, call `useCallsOverViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallsOverViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallsOverViewQuery({
 *   variables: {
 *   },
 * });
 */
export function useCallsOverViewQuery(baseOptions?: Apollo.QueryHookOptions<CallsOverViewQuery, CallsOverViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CallsOverViewQuery, CallsOverViewQueryVariables>(CallsOverViewDocument, options);
      }
export function useCallsOverViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CallsOverViewQuery, CallsOverViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CallsOverViewQuery, CallsOverViewQueryVariables>(CallsOverViewDocument, options);
        }
export function useCallsOverViewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CallsOverViewQuery, CallsOverViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CallsOverViewQuery, CallsOverViewQueryVariables>(CallsOverViewDocument, options);
        }
export type CallsOverViewQueryHookResult = ReturnType<typeof useCallsOverViewQuery>;
export type CallsOverViewLazyQueryHookResult = ReturnType<typeof useCallsOverViewLazyQuery>;
export type CallsOverViewSuspenseQueryHookResult = ReturnType<typeof useCallsOverViewSuspenseQuery>;
export type CallsOverViewQueryResult = Apollo.QueryResult<CallsOverViewQuery, CallsOverViewQueryVariables>;
export const CallListDocument = gql`
    query callList($limit: Int!, $page: Int!, $fromDate: String, $toDate: String, $containerId: String, $callerIdNum: String, $tenantId: String, $showInbound: Boolean, $showOutbound: Boolean, $showInternal: Boolean) {
  callList(
    limit: $limit
    page: $page
    fromDate: $fromDate
    toDate: $toDate
    containerId: $containerId
    callerIdNum: $callerIdNum
    tenantId: $tenantId
    showInbound: $showInbound
    showOutbound: $showOutbound
    showInternal: $showInternal
  ) {
    list {
      id
      doctorId
      officeId
      contactId
      doctorName
      officeName
      contactName
      contactPhoneNumber
      extensionName
      extensionNumber
      callingTime
      statusChips {
        variant
        label
      }
      callType
      hasAttachment
      isOutgoing
      isOngoing
      isAnswered
      isReturned
      voicemailLeft
      invoiceCount
      invoiceNetTotal
      orderStartedCount
      orderShippedCount
      value
      cod
      route
      scanner
      salesPerson
    }
    total
    totalPages
  }
}
    `;

/**
 * __useCallListQuery__
 *
 * To run a query within a React component, call `useCallListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      containerId: // value for 'containerId'
 *      callerIdNum: // value for 'callerIdNum'
 *      tenantId: // value for 'tenantId'
 *      showInbound: // value for 'showInbound'
 *      showOutbound: // value for 'showOutbound'
 *      showInternal: // value for 'showInternal'
 *   },
 * });
 */
export function useCallListQuery(baseOptions: Apollo.QueryHookOptions<CallListQuery, CallListQueryVariables> & ({ variables: CallListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CallListQuery, CallListQueryVariables>(CallListDocument, options);
      }
export function useCallListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CallListQuery, CallListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CallListQuery, CallListQueryVariables>(CallListDocument, options);
        }
export function useCallListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CallListQuery, CallListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CallListQuery, CallListQueryVariables>(CallListDocument, options);
        }
export type CallListQueryHookResult = ReturnType<typeof useCallListQuery>;
export type CallListLazyQueryHookResult = ReturnType<typeof useCallListLazyQuery>;
export type CallListSuspenseQueryHookResult = ReturnType<typeof useCallListSuspenseQuery>;
export type CallListQueryResult = Apollo.QueryResult<CallListQuery, CallListQueryVariables>;
export const DoctorsDocument = gql`
    query doctors($first: Int!, $page: Int!, $search: String) {
  doctors(first: $first, page: $page, search: $search) {
    data {
      id
      reference
      externalReference
      name
      deleted
      created
      createdBy
      lastUpdated
      lastUpdatedBy
      timeStamp
      doctorCode
      licenceNo
      title
      firstName
      middleName
      lastName
      suffix
      email
      telephone
      mobile
      customerAccountId
    }
    paginatorInfo {
      currentPage
      lastPage
      total
    }
  }
}
    `;

/**
 * __useDoctorsQuery__
 *
 * To run a query within a React component, call `useDoctorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useDoctorsQuery(baseOptions: Apollo.QueryHookOptions<DoctorsQuery, DoctorsQueryVariables> & ({ variables: DoctorsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DoctorsQuery, DoctorsQueryVariables>(DoctorsDocument, options);
      }
export function useDoctorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DoctorsQuery, DoctorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DoctorsQuery, DoctorsQueryVariables>(DoctorsDocument, options);
        }
export function useDoctorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DoctorsQuery, DoctorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DoctorsQuery, DoctorsQueryVariables>(DoctorsDocument, options);
        }
export type DoctorsQueryHookResult = ReturnType<typeof useDoctorsQuery>;
export type DoctorsLazyQueryHookResult = ReturnType<typeof useDoctorsLazyQuery>;
export type DoctorsSuspenseQueryHookResult = ReturnType<typeof useDoctorsSuspenseQuery>;
export type DoctorsQueryResult = Apollo.QueryResult<DoctorsQuery, DoctorsQueryVariables>;
export const NotificationsListDocument = gql`
    query notificationsList {
  notificationsList {
    list {
      id
      type
      doctorName
      phoneNumber
      time
      message
    }
    total
  }
}
    `;

/**
 * __useNotificationsListQuery__
 *
 * To run a query within a React component, call `useNotificationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsListQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsListQuery, NotificationsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsListQuery, NotificationsListQueryVariables>(NotificationsListDocument, options);
      }
export function useNotificationsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsListQuery, NotificationsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsListQuery, NotificationsListQueryVariables>(NotificationsListDocument, options);
        }
export function useNotificationsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NotificationsListQuery, NotificationsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NotificationsListQuery, NotificationsListQueryVariables>(NotificationsListDocument, options);
        }
export type NotificationsListQueryHookResult = ReturnType<typeof useNotificationsListQuery>;
export type NotificationsListLazyQueryHookResult = ReturnType<typeof useNotificationsListLazyQuery>;
export type NotificationsListSuspenseQueryHookResult = ReturnType<typeof useNotificationsListSuspenseQuery>;
export type NotificationsListQueryResult = Apollo.QueryResult<NotificationsListQuery, NotificationsListQueryVariables>;
export const OfficesDocument = gql`
    query offices($first: Int!, $page: Int!, $search: String) {
  offices(first: $first, page: $page, search: $search) {
    data {
      id
      name
      telephone
      fax
      email
      deleted
      created
      runNo
      altRun2
      altRun3
      practiceGroupId
      codePrefix
      address
    }
    paginatorInfo {
      currentPage
      lastPage
      total
    }
  }
}
    `;

/**
 * __useOfficesQuery__
 *
 * To run a query within a React component, call `useOfficesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useOfficesQuery(baseOptions: Apollo.QueryHookOptions<OfficesQuery, OfficesQueryVariables> & ({ variables: OfficesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OfficesQuery, OfficesQueryVariables>(OfficesDocument, options);
      }
export function useOfficesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OfficesQuery, OfficesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OfficesQuery, OfficesQueryVariables>(OfficesDocument, options);
        }
export function useOfficesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OfficesQuery, OfficesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OfficesQuery, OfficesQueryVariables>(OfficesDocument, options);
        }
export type OfficesQueryHookResult = ReturnType<typeof useOfficesQuery>;
export type OfficesLazyQueryHookResult = ReturnType<typeof useOfficesLazyQuery>;
export type OfficesSuspenseQueryHookResult = ReturnType<typeof useOfficesSuspenseQuery>;
export type OfficesQueryResult = Apollo.QueryResult<OfficesQuery, OfficesQueryVariables>;
export const TeamsDocument = gql`
    query teams($first: Int!, $page: Int!, $search: String) {
  teams(first: $first, page: $page, search: $search) {
    data {
      id
      name
      type
      extensions
      locations
      company_id
      users {
        id
        first_name
        last_name
        email
        is_administrator
      }
    }
    paginatorInfo {
      currentPage
      lastPage
      total
    }
  }
}
    `;

/**
 * __useTeamsQuery__
 *
 * To run a query within a React component, call `useTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTeamsQuery(baseOptions: Apollo.QueryHookOptions<TeamsQuery, TeamsQueryVariables> & ({ variables: TeamsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamsQuery, TeamsQueryVariables>(TeamsDocument, options);
      }
export function useTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamsQuery, TeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamsQuery, TeamsQueryVariables>(TeamsDocument, options);
        }
export function useTeamsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TeamsQuery, TeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamsQuery, TeamsQueryVariables>(TeamsDocument, options);
        }
export type TeamsQueryHookResult = ReturnType<typeof useTeamsQuery>;
export type TeamsLazyQueryHookResult = ReturnType<typeof useTeamsLazyQuery>;
export type TeamsSuspenseQueryHookResult = ReturnType<typeof useTeamsSuspenseQuery>;
export type TeamsQueryResult = Apollo.QueryResult<TeamsQuery, TeamsQueryVariables>;