import styled from 'styled-components';

export const RenderCallerDetailsStyled = styled.div`
    display: inline-flex;
    align-items: center;
    width: 45%;
    flex-shrink: 0;

    .ml--7 {
        margin-left: -7px;
    }
    .call-type {
        display: inline-block;
        width: 43px;
        height: 43px;
    }
    .name-text {
        font-weight: 500;
        font-size: 12px;
        margin: 0;
        color: #202020;
    }
    .phone-number-text {
        font-weight: 500;
        font-size: 11px;
        margin: 0;
        color: #6E7378; 
    }
    .date-time-text {
        font-weight: 400;
        font-size: 11px;
        margin: 0;
        color: #6E7378;
    }
`
