type AuthData = {
  token: string
  user: {
    first_name: string
    last_name: string
    id: string
    email: string
  }
}

export const setUserInSession = (data: AuthData) => {
  localStorage.setItem("token", data.token)
  localStorage.setItem("firstName", data.user.first_name)
  localStorage.setItem("lastName", data.user.last_name)
  localStorage.setItem("id", data.user.id)
  localStorage.setItem("email", data.user.email)
}

export const clearUserSession = () => {
  localStorage.removeItem("token")
  localStorage.removeItem("firstName")
  localStorage.removeItem("lastName")
  localStorage.removeItem("id")
  localStorage.removeItem("email")
}

export const getSessionUser = () => {
  const token = localStorage.getItem("token")
  const firstName = localStorage.getItem("firstName")
  const lastName = localStorage.getItem("lastName")

  const id = localStorage.getItem("id")
  const email = localStorage.getItem("email")

  return {
    token: token,
    user: {
      firstName,
      lastName,
      id: id,
      email,
    },
  }
}

export const isSomething = (data: any) => {
  if (data === null || data === undefined) return false

  if (typeof data === "number") return true

  if (typeof data === "string" && data.length === 0) return false

  if (typeof data === "object" && Object.keys(data).length === 0) return false

  if (Array.isArray(data) && data.length === 0) return false

  return true
}
