import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Avatar } from '@mui/material';

import { RenderCallerDetailsStyled } from './render-caller-details.styles';
import { RenderCallerDetailsProps } from '../../../../types/types';

import phoneOngoing from '../../../../assets/imgs/phone-ongoing.gif'
import outgoingCall from '../../../../assets/imgs/phone-outgoing.svg';
import incomingCall from '../../../../assets/imgs/phone-incoming.svg';
import phoneReturned from '../../../../assets/imgs/phone-returned.svg';
import missedCircle from '../../../../assets/imgs/missed-circle.svg'
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js'

interface PhoneStatusIconProps {
    type: 'ongoing' | 'outgoing' | 'incoming' | 'missed' | 'returned' | string;
    isOutgoing?: boolean;
    isOngoing?: boolean;
    isAnswered?: boolean;
    isReturned?: boolean;
    voicemailLeft?: boolean;
}

const typeToComponentMap: Record<PhoneStatusIconProps['type'], JSX.Element> = {
    ongoing: <img src={phoneOngoing} alt="Phone Ongoing" className="d-block w-100 ml--7" />,
    outgoing: (
      <Avatar sx={{ bgcolor: '#F0F4FA', width: 32, height: 32 }}>
        <img src={outgoingCall} alt="Phone Outgoing" className="d-block" width="16" height="16" />
      </Avatar>
    ),
    incoming: (
      <Avatar sx={{ bgcolor: '#F0F4FA', width: 32, height: 32 }}>
        <img src={incomingCall} alt="Phone Incoming" className="d-block" width="16" height="16" />
      </Avatar>
    ),
    missed: (
      <img src={missedCircle} alt="Phone Missed" className="d-block w-100 ml--7" width="32" height="32" />
    ),
    returned: (
      <Avatar sx={{ bgcolor: '#F0F4FA', width: 32, height: 32 }}>
        <img src={phoneReturned} alt="Phone Return" className="d-block" width="16" height="16" />
      </Avatar>
    ),
};


const PhoneStatusIcon: React.FC<PhoneStatusIconProps> = ({ isOngoing, voicemailLeft, isAnswered, type }) => {
  // if(!typeToComponentMap[type]) throw new Error(`Invalid type: ${type}`);
  if(isOngoing) {
    return typeToComponentMap.ongoing || null;
  } else if (isAnswered === true) {
    return typeToComponentMap['incoming'] || null;
  } else if (isAnswered === false) {
    return typeToComponentMap['missed'] || null;
  } else {
    return typeToComponentMap['incoming'] || null;
  }
};


const RenderCallerDetails: React.FC<RenderCallerDetailsProps> = ({ isOngoing, isAnswered, voicemailLeft, type, contactName, contactPhoneNumber, callingTime }) => {
    const phoneNumber = new AsYouType('US').input("+"+contactPhoneNumber)
    
    return (
        <RenderCallerDetailsStyled>
            <Box className="call-type">
            <PhoneStatusIcon
              type={type}
              isOngoing={isOngoing}
              isAnswered={isAnswered}
              voicemailLeft={voicemailLeft}
            />
            </Box>
            <Box>
                <Typography className="name-text">{contactName}</Typography>
                <Typography className="phone-number-text">{phoneNumber}</Typography>
                <Typography className="date-time-text">{callingTime}</Typography>
            </Box>
        </RenderCallerDetailsStyled>
    );
};

export default RenderCallerDetails;
