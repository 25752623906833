import React from "react"
import filterIcon from "../../assets/icons/filter-icon.svg"
import { SearchAndActionStyled } from "./search-and-filter.styled"
import { useAppSelector } from "../../store/hooks"
import { SearchAndActionProps } from "./types"


const SearchAndAction: React.FC<SearchAndActionProps> = ({
    showFilterButton,
    filterButtonAction,
    showActionButton,
    actionButtonTitle,
    actionButtonFn,
    searchPlaceholder,
    onSearch
}) => {
    const isAdmin = useAppSelector(state => state.admin.isAdmin)

    return (
        <SearchAndActionStyled className={`d-flex align-items-center justify-content-between search-and-action-root ${isAdmin ? 'admin-search' : ''}`}>
            <input className="search-input" onInput={onSearch} placeholder={searchPlaceholder || "Search here..."} />
            <div className="buttons-wrapper d-flex align-items-center">
                {showFilterButton && <button onClick={filterButtonAction} className="filter-button">
                    <img src={filterIcon} alt="Filter" className="d-block mx-auto" />
                </button>}
                {showActionButton && <button onClick={actionButtonFn} className="pri-button">
                    {actionButtonTitle}
                </button>}
            </div>
        </SearchAndActionStyled>
    )
}

export default SearchAndAction