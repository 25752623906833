import styled from "styled-components";
import { Button as MuiButton } from "@mui/material";

export const ButtonStyled = styled(MuiButton)`
  display: flex;
  gap: 5px;
  height: 32px;
  font-weight: 600;
  box-shadow: none;
  border: 2px solid transparent;
  text-transform: none;
  :hover {
    box-shadow: none;
  }

  .MuiButton-startIcon {
    margin: unset;
  }

  .MuiButton-endIcon {
    margin: unset;
  }
`;
