import { graphql, HttpResponse } from "msw";
import { USERS_MOCK } from "../login/mock";

let currentId = USERS_MOCK.length;

export const signupHandler = graphql.mutation(
  "SignUp",
  async ({ variables }) => {
    const { firstName, lastName, email, password, confirm, phoneNumber } =
      variables;

    // Check if the email already exists
    const existingUser = USERS_MOCK.find((user) => user.email === email);
    if (existingUser) {
      return HttpResponse.json({
        errors: [{ message: "Email already exists" }],
      });
    }

    // Create a new user
    const newUser = {
      id: (++currentId).toString(),
      firstName,
      lastName,
      email,
      password, // Note: This is just for the mock. Do not store plain text passwords in a real app!
      token: `token${currentId}`,
      confirm,
      phoneNumber,
    };

    USERS_MOCK.push(newUser);

    const data = {
      signUp: {
        user: {
          id: newUser.id,
          firstName: newUser.firstName,
          lastName: newUser.lastName,
          email: newUser.email,
        },
        token: newUser.token,
      },
    };

    return HttpResponse.json({ data });
  }
);
