import styled from 'styled-components';


export const WarningIconIconLinkStyled = styled.div`
    display: inline-flex;
    align-items: center;
    border-left: 1px solid transparent;
    padding: 0 0 0 8px;

    .warning-icon {
        width: 17.11px;
        height: 15px;
    }
`

