import { TEAMS_MOCK } from '../../mock-data/teams-mock'
import { graphql, HttpResponse } from "msw"

let currentId = TEAMS_MOCK.length

export const addTeamHandler = graphql.mutation(
  "AddTeam",
  async ({ variables }) => {
    const { name, type, extensions, locations, accounts } =
      variables

    // Create a new user
    const newTeam = {
      id: (++currentId),
      name,
      type,
      extensions,
      locations,
      accounts,
    }

    TEAMS_MOCK.push(newTeam)

    const data = {
      addTeam: {
        data: {
          id: newTeam.id,
          name: newTeam.name,
          type: newTeam.type,
          extensions: newTeam.extensions,
          locations: newTeam.locations,
          accounts: newTeam.accounts,
        },
        message: "Team has been added successfully.",
        status: true,
      },
    }

    return HttpResponse.json({ data })
  }
)

export const editTeamHandler = graphql.mutation(
  "EditTeam",
  async ({ variables }) => {
    const { id, name, type, extensions, locations, accounts } = variables;

    const teamIndex = TEAMS_MOCK.findIndex(team => team.id == id);

    if (teamIndex === -1) {
      return HttpResponse.json({
        data: {
          editTeam: {
            data: null,
            message: "Team not found.",
            status: false,
          },
        },
      });
    }

    TEAMS_MOCK[teamIndex] = {
      id,
      name,
      type,
      extensions,
      locations,
      accounts,
    };

    const data = {
      editTeam: {
        data: TEAMS_MOCK[teamIndex],
        message: "Team has been updated successfully.",
        status: true,
      },
    };

    return HttpResponse.json({ data });
  }
);