import React, { useState } from 'react';
import { Button, Typography, InputBase } from '@mui/material';
import { Card } from './message-card.styles';

interface CallComponentProps {
    doctorName: string;
    phoneNumber: string;
    messageTime: string;
    message?: string,
    onDismiss: () => void;
    onReply: (replyMessage: string) => void;
}

const MessageComponent: React.FC<CallComponentProps> = ({ doctorName, phoneNumber, messageTime, message, onDismiss, onReply }) => {
    const [reply, setReply] = useState('');
    const [isReplying, setIsReplying] = useState(false);

    const handleReplyClick = () => {
        setIsReplying(true);
    };

    const handleSendClick = () => {
        onReply(reply);
        setReply('');
        setIsReplying(false);
    };

    return (
        <Card>
            <div className="card">
                <div className="image" />
                <div className="content">
                    <div className="header">
                        <div className="d-flex flex-column message-detail">
                            <Typography variant="h6" className="doctor-name">{doctorName}</Typography>
                            <Typography variant="body1" className="phone-number">{phoneNumber}</Typography>
                        </div>
                        <Typography variant="body2" className="message-time d-flex align-items-center">{messageTime}</Typography>
                    </div>

                    <div className="description">
                        {message}
                    </div>

                    {isReplying && (
                        <div className="reply-section">
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                className="reply-input"
                                placeholder="Write here"
                                inputProps={{ 'aria-label': 'Write here' }}
                                value={reply}
                                onChange={(e) => setReply(e.target.value)}
                                multiline
                                rows={2}
                            />
                            <Button variant="contained" color="primary" onClick={handleSendClick}>Send</Button>
                        </div>
                    )}

                    <div className="actions">
                        {!isReplying && (
                            <React.Fragment>
                                <Button className="dismiss" variant="outlined" color="secondary" onClick={onDismiss}>Dismiss</Button>
                                <Button className="reply-back" variant="contained" color="primary" onClick={handleReplyClick}>Reply</Button>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default MessageComponent;
