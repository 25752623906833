import { Popover } from "@mui/material";
import styled from "styled-components";

export const SplitTextIntoPopoverStyled = styled.div`
    &.popover-opened {
        opacity: 60%;
    }
    .extra-text-counter {
        color: #2460B8;
    }
`

export const PopoverStyled = styled(Popover)`
    .MuiPaper-root {
        width: 281px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 12px;
        .MuiTypography-root {
            font-weight: 400;
            font-size: 13px;
            color: #202020;
        }
    }
`