import { CONTACTS_MOCK } from '../../mock-data/contacts-mock'
import { graphql, HttpResponse } from "msw"

let currentId = CONTACTS_MOCK.length

export const createContactHandler = graphql.mutation(
  "CreateContact",
  async ({ variables }) => {
    const { first_name, last_name, type, phone_number, office_id, doctor_id } =
      variables

    // Create a new user
    const newContact = {
      id: (++currentId),
      first_name,
      last_name,
      type,
      phone_number,
      office_id,
      doctor_id
    }

    CONTACTS_MOCK.push(newContact)

    const data = {
      createContact: {
        id: newContact.id,
        type: newContact.type,
        phone_number: newContact.phone_number,
        office_id: newContact.office_id,
        doctor_id: newContact.doctor_id
      },
    }

    return HttpResponse.json({ data })
  }
)