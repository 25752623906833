import React, { Fragment } from 'react';
import Divider from '@mui/material/Divider';

import CallCountWithIcon from '../call-count-with-icon/call-count-with-icon';

interface CallTypeWithCount {
  callType: 'ongoing' | 'missed' | 'returned' | 'incoming' | 'outgoing',
  count: number
}

interface CallTypeOverviewProps {
  data: CallTypeWithCount[]
}

const CallTypeOverview: React.FC<CallTypeOverviewProps> = ({ data }) => {
  return (
    <div className="d-flex">
      {data.map((o, index) => (
        <Fragment key={index}>
          <CallCountWithIcon type={o.callType} count={o.count} />
          {(index + 1) < data.length && <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />}
        </Fragment>
      ))}
    </div>
  );
};

export default CallTypeOverview;