import React, { useEffect, useState } from "react"
import SearchAndAction from "../../../../../components/search-and-filter/search-and-filter"
import TableComponent from "../../../../../components/table-component/table-component"
import { columns } from "./constants"
import AddEditDoctorModal from "./add-edit-doctor-modal/add-edit-doctor-modal"
import RemoveDoctorModal from "./remove-doctor-modal/remove-doctor-modal"
import { PaginatorInfo } from "../../../../../types/types"
import useDebouncedCallback from "../../../../../hooks/useDebouncedCallback"
import { ACTION_TYPES } from "../../../../../constants"
import { TableMenuDropdownStyled } from "./doctors.styles"
import MenuDropdown from "../../../../../components/menu/menu"
import { Doctor, useDoctorsQuery } from "../../../../../generated/graphql"

const Doctors: React.FC = () => {
  const [modalData, setModalData] = useState<Doctor | undefined>()
  const [doctorsData, setDoctorsData] = useState<any[] | undefined>([])
  const [paginatorInfo, setPaginatorInfo] = useState<PaginatorInfo>()

  const [actionType, setActionType] = useState<string>("")

  const handleAction = () => {
    setModalData(undefined)
    setActionType(ACTION_TYPES.ADD)
  }

  const resetActionType = () => {
    // To make sure fading out effect is completed first
    setTimeout(() => {
      setActionType("")
    }, 500)
  }

  const { loading, error, data, refetch } = useDoctorsQuery({
    variables: { first: 10, page: 1 },
    notifyOnNetworkStatusChange: true
  })

  const handleSearch = useDebouncedCallback((e: React.BaseSyntheticEvent) => {
    refetch({
      page: 1,
      first: 10,
      search: e.target.value
    })
  })

  const dropdownData = [
    {
      label: "Edit",
      key: "edit",
      action: (data: any) => {
        setModalData(data)
        setActionType(ACTION_TYPES.EDIT)
      },
      isDisabled: false,
    },
    {
      label: "Delete",
      key: "delete",
      action: (data: any) => {
        setModalData(data)
        setActionType(ACTION_TYPES.DELETE)
      },
      isDisabled: false,
    },
  ]

  useEffect(() => {
    if (data) {
      const doctorsDataMapped: any[] | undefined = data?.doctors?.data?.map(doctor => {
        return {
          ...doctor,
          actions: (
            <TableMenuDropdownStyled>
              <MenuDropdown items={dropdownData} data={doctor} />
            </TableMenuDropdownStyled>
          ),
        }
      })
      setDoctorsData(doctorsDataMapped)
      setPaginatorInfo({
        ...(data?.doctors?.paginatorInfo || {}),
        onPageChange: newPage => {
          refetch({
            first: 10,
            page: newPage
          })
        }
      })
    }
  }, [data])

  if (error) return <p>Error: {error.message}</p>

  return (
    <>
      <SearchAndAction
        showActionButton={true}
        actionButtonTitle="Add doctor"
        onSearch={handleSearch}
        actionButtonFn={handleAction}
      />
      <TableComponent
        isLoading={loading}
        paginatorInfo={paginatorInfo}
        columns={columns}
        data={doctorsData}
      />
      {(actionType === ACTION_TYPES.ADD || actionType === ACTION_TYPES.EDIT) && (
        <AddEditDoctorModal refetch={refetch} onClose={resetActionType} data={modalData} />
      )}
      {actionType === ACTION_TYPES.DELETE && modalData && (
        <RemoveDoctorModal refetch={refetch} onClose={resetActionType} data={modalData} />
      )}
    </>
  )
}

export default Doctors
