import React, { useEffect } from "react"
import { Route, Routes } from "react-router-dom"
import AdminDashboard from "../pages/admin/admin-dashboard/admin-dashboard"
import AppLayout from "../layouts/app-layout"
import { useAppDispatch } from "../store/hooks"
import { setAdmin } from "../store/slices/admin-slice"
import ProtectedRoute from "../utils/ProtectedRoute"

const AdminRoutes: React.FC = () => {
  const dispatch = useAppDispatch()

  // for the time being, unless we don't implement auth for admin
  useEffect(() => {
    dispatch(
      setAdmin(true)
    )
  }, [])

  return (
    <Routes>
      <Route path="/" element={
        <ProtectedRoute>
          <AppLayout>
            <AdminDashboard />
          </AppLayout>
        </ProtectedRoute>
      } />
    </Routes>
  )
}

export default AdminRoutes
