import React, { useState, useEffect } from "react"
import { v4 as uuid } from "uuid"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import ModalComponent from "../../../../../../components/modal-component/modal-component"
import { Button } from "../../../../../../components/button/button"
import { TextInputFormField } from "../../../../../../components/form-fields/text-input-field/text-input-form-field"
import { addEditDoctorFormValidation } from "../../../../../../utils/validations/index"
import ModalForm from "../../../../../../components/modal-form/modal-form"
import { ModalFormButtonsBox } from "../../../../../../components/modal-form/modal-form.styles"
import { addNotification } from "../../../../../../store/slices/ui-slice"
import { useAppDispatch } from "../../../../../../store/hooks"
import PhoneNumberFormField from "../../../../../../components/form-fields/phone-number-field/phone-number-form-field"
import { AddEditDoctorModalProps } from "./types"


const ModalBody: React.FC<AddEditDoctorModalProps> = ({
  data,
  refetch,
  type,
  onClose,
}) => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { control, watch, formState } = useForm({
    mode: "all",
    resolver: yupResolver(addEditDoctorFormValidation()),
  })

  const handleSubmit = async () => {
    setIsLoading(true)
    if (type === "Add") {
      try {
        dispatch(
          addNotification({
            id: uuid(),
            type: "success",
            message: "Doctor added successfully.",
          })
        )
      } catch (err: any) {
        dispatch(
          addNotification({
            id: uuid(),
            type: "error",
            message: err?.message,
          })
        )
      } finally {
        setIsLoading(false)
      }
    } else {
      try {
        refetch && refetch()
        dispatch(
          addNotification({
            id: uuid(),
            type: "success",
            message: "Doctor data updated successfully.",
          })
        )
      } catch (err: any) {
        dispatch(
          addNotification({
            id: uuid(),
            type: "error",
            message: err?.message,
          })
        )
      } finally {
        setIsLoading(false)
      }
    }
    onClose && onClose()
  }

  const isValid = formState?.isValid

  return (
    <ModalForm>
      <TextInputFormField
        name="first_name"
        label="First Name"
        control={control}
        defaultValue={data?.firstName}
        placeholder="Enter here"
      />
      <TextInputFormField
        name="last_name"
        label="Last Name"
        control={control}
        defaultValue={data?.lastName}
        placeholder="Enter here"
      />
      <TextInputFormField
        name="title"
        label="Title"
        control={control}
        defaultValue={data?.title}
        placeholder="Enter here"
      />
      <TextInputFormField
        name="zip_code"
        label="Zip Code"
        control={control}
        defaultValue={''}
        placeholder="Enter here"
      />
      <TextInputFormField
        name="liscense_number"
        label="License Number"
        control={control}
        defaultValue={data?.licenceNo}
        placeholder="Enter here"
      />
      <TextInputFormField
        name="email"
        label="Email"
        control={control}
        defaultValue={data?.email}
        placeholder="Enter here"
      />

      <PhoneNumberFormField
        name="doctor_phone_number"
        label={"Doctor Phone Number"}
        defaultValue={data?.telephone}
        control={control}
      />
      <ModalFormButtonsBox>
        <Button
          disabled={!isValid}
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          {data ? "Save changes" : "Add doctor"}
        </Button>
      </ModalFormButtonsBox>
    </ModalForm>
  )
}

const AddEditDoctorModal: React.FC<AddEditDoctorModalProps> = ({
  data,
  refetch,
  onClose,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false)

  useEffect(() => {
    setOpenModal(true)
  }, [])

  const handleClose = () => {
    setOpenModal(false)
    onClose && onClose()
  }

  return (
    <div>
      <ModalComponent
        title={`${data ? "Edit" : "Add"} doctor`}
        content={
          <ModalBody
            refetch={refetch}
            data={data}
            type={`${data ? "Edit" : "Add"}`}
            onClose={handleClose}
          />
        }
        open={openModal}
        onClose={handleClose}
      />
    </div>
  )
}

export default AddEditDoctorModal
