import { useCallback, useRef } from 'react'

function useDebouncedCallback(callback: (...args: any[]) => void, delay: number = 500) {
    const debounceRef = useRef<NodeJS.Timeout | null>(null)

    const debouncedCallback = useCallback((...args: any[]) => {
        if (debounceRef.current) {
            clearTimeout(debounceRef.current)
        }

        debounceRef.current = setTimeout(() => {
            callback(...args)
        }, delay)
    }, [callback, delay])

    return debouncedCallback
}

export default useDebouncedCallback
