import styled from 'styled-components';
import Box from '@mui/material/Box';

export const StyledBox = styled(Box)`
  .tab {
    min-width: auto;
    min-height: auto;
  }
`;


export const TabIndicatorProps = {
  sx: { backgroundColor: '#2460B8' },
};