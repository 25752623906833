import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { LoaderWrapper, TableBodyStyled, TableComponentStyled, TableFooterStyled, TableHeadStyled, TablePaginationPagesControlsStyled } from "./table-component.styles"
import { TableComponentProps } from '../../types/types'
import { Button, CircularProgress, IconButton, TablePagination } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import React from 'react'

interface TablePaginationActionsProps {
    count: number
    page: number
    rowsPerPage: number
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme()
    const { count, page, rowsPerPage, onPageChange } = props

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1)
    }

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>, page: number) => {
        onPageChange(event, page)
    }

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1)
    }

    const pages: number[] = Array.from(Array(Math.ceil(count / rowsPerPage)).keys())

    return (
        <TablePaginationPagesControlsStyled>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            {pages.map((p, i) => <Button
                key={i}
                onClick={(e) => handleButtonClick(e, p)}
                className={`${page === p ? 'active' : ''} single-page`}
            >
                {p + 1}
            </Button>)}
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
        </TablePaginationPagesControlsStyled>
    )
}

const Loader: React.FC<{ columns: any[] }> = ({ columns }) => {
    return (
        <TableRow className="loader-row">
            <TableCell colSpan={columns.length}>
                <LoaderWrapper>
                    <CircularProgress />
                </LoaderWrapper>
            </TableCell>
        </TableRow>
    )
}

const TableComponent: React.FC<TableComponentProps> = ({ paginatorInfo, columns, data, isLoading }) => {
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        paginatorInfo?.onPageChange && paginatorInfo?.onPageChange(newPage + 1)
    }

    return (
        <TableComponentStyled>
            <TableContainer component={Paper}>
                <Table>
                    <TableHeadStyled>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHeadStyled>
                    <TableBodyStyled>
                        {isLoading && <Loader columns={columns} />}
                        {data?.map((row, rowIndex) => (
                            <TableRow
                                key={rowIndex}
                            >
                                {columns.map((column) => {
                                    const value = row[column.id]
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                            {column.render ? column.render(value) : value}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        ))}
                        {(!data?.length) && <TableRow>
                            <TableCell colSpan={columns.length} align='center'>No records found!</TableCell>
                        </TableRow>}
                    </TableBodyStyled>
                    {paginatorInfo && Boolean(data?.length) && <TableFooterStyled>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                colSpan={columns.length}
                                count={paginatorInfo?.total}
                                rowsPerPage={10}
                                labelDisplayedRows={({ from, to, count }) => `Showing ${(to - from) + 1} from ${count} data`}
                                page={Number(paginatorInfo?.currentPage) - 1}
                                slotProps={{
                                    select: {
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={() => { console.log('handleChangeRowsPerPage') }}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooterStyled>}
                </Table>
            </TableContainer>

        </TableComponentStyled >
    )
}

export default TableComponent