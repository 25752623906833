import styled from 'styled-components';


export const ProjectStatsIconWithCountStyled = styled.div`
    display: flex;
    gap: 29px;
    .icon-container {
        position: relative;
    }
    .notification-badge {
        position: absolute;
        top: -7px;
        right: -13px;
        width: 20px;
        height: 20px;
        background-color: #70767B;
        z-index: 1;
        font-weight: 500;
        font-size: 10px;
        border-radius: 100%;
        color: #ffffff;
        border: 1.5px solid #FFFFFF;
    }
`

