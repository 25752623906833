export const columns = [
    {
      id: "email",
      label: "User Email",
    },
    {
      id: "first_name",
      label: "First Name",
    },
    {
      id: "last_name",
      label: "Last Name",
    },
    {
      id: "noOfTeams",
      label: "No. of Teams",
    },
    {
      id: "status",
      label: "Status"
    },
    {
      id: "actions",
      label: "",
    },
  ]