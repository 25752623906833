import HistoryCard from "../history-card/history-card"
import { HistoryCardsStyled } from "./history-cards.styles"
import SearchAndAction from "../../../../components/search-and-filter/search-and-filter"
import { Typography } from "@mui/material"
import { useEffect, useState } from "react"
import usePusher from "../../../../utils/usePusher"
import EditContact from "../../../edit-contacts/edit-contacts"
import EditContactContext from "../../../../context/edit-contact-context"
import { useCallListQuery } from "../../../../generated/graphql"

interface HistoryCardProps {
    refetchCallsOverview: () => void
}

const HistoryCards: React.FC<HistoryCardProps> = ({ refetchCallsOverview }) => {
    const [state, setState] = useState<{ calls: any[] }>({
        calls: []
    })
    const [editContactData, setEditContactData] = useState<any>()

    const { loading, error, data, refetch } = useCallListQuery({
        variables: { limit: 10, page: 1 },
    })

    const events = usePusher<{
        id: string,
    }>('labtrac-backend', 'call-event')

    useEffect(() => {
        setState({ calls: data?.callList[0].list ?? [] })
    }, [data])

    useEffect(() => {
        if (events[0] && events[0].id) {
            let calls = state.calls.map((o) => {
                const event = events.find((e) => e.id === o.id)

                return event ? event : o
            })

            for (let event of events) {
                if (!calls.find(({ id }) => event.id === id)) {
                    calls = [event, ...calls]
                }
            }
            calls = calls.sort((a, b) => {
                return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            })
            refetchCallsOverview()
            setState({
                calls
            })
        }

    }, [JSON.stringify(events)])

    if (loading) return <p>Loading...</p>
    if (error) return <p>Error: {error.message}</p>

    const calls = state.calls.sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    })

    const handleCloseDrawer = () => {
        setEditContactData(null)
    }

    return (
        <>
            <EditContactContext.Provider value={{ editContactData, setEditContactData }}>
                <SearchAndAction showFilterButton={true} searchPlaceholder="Search calls here..." />
                <HistoryCardsStyled className="d-flex flex-column">
                    {calls.map((o, i) => (
                        <HistoryCard key={i} data={o} refetchCalls={refetch} />
                    ))}
                    {!(state.calls) && <Typography className="text-center">No records found.</Typography>}
                </HistoryCardsStyled>
                {/* Modal Here */}
                <EditContact
                    id={editContactData?.id}
                    doctorId={editContactData?.doctorId}
                    officeId={editContactData?.officeId}
                    name={editContactData?.name}
                    callerPhoneNumber={editContactData?.callerPhoneNumber}
                    onClose={handleCloseDrawer}
                    isVisible={Boolean(editContactData)}
                    refetch={editContactData?.refetch}
                />
            </EditContactContext.Provider>
        </>
    )
}

export default HistoryCards