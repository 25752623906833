import { useEffect } from "react";
import React from 'react';
import { useAppSelector } from "../store/hooks";
import { isSomething } from "../utils/common-utils";
import NotificaionsWrapper from "../components/notification/notification-wrapper";
import HeaderBar from "../components/header-bar/header-bar";
import { AppLayoutProps } from "../types/types";


const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
    const notifications = useAppSelector((state) => state.UI.notifications);
    const isAdmin = useAppSelector((state) => state.admin.isAdmin);

    useEffect(() => {
        if (isAdmin) {
            document.body.classList.add('admin-layout')
        } else {
            document.body.classList.remove('admin-layout')
        }
    }, [isAdmin])

    return (
        <div className="app">
            <div className="app-wrapper">
                {isSomething(notifications) && <NotificaionsWrapper />}
                <HeaderBar />
                {children}
            </div>
        </div>
    )
}

export default AppLayout