import { configureStore } from "@reduxjs/toolkit";

import UIReducer from "./slices/ui-slice";
import AdminReducer from "./slices/admin-slice"

export const store = configureStore({
  reducer: {
    UI: UIReducer,
    admin: AdminReducer,
  },
});
