export const USERS_MOCK = [
  {
    id: "1",
    firstName: "john",
    lastName: "Doe",
    email: "john@example.com",
    password: "password123", // In a real scenario, passwords should be hashed
    token: "token123",
  },
  // Add more mock users as needed
];

export let currentId = USERS_MOCK.length;
