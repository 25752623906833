import styled from "styled-components";

export const ForgotPasswordStyled = styled.div`
  padding: 18px;
  .logo {
    margin-top: 40px;
    img {
      height: 39px;
      width: 135px;
    }
  }

  .heading {
    display: flex;
    align-items: center;
    margin-left: -10px;
    button {
      margin-top: 15px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .btn {
    margin: 20px 0px 10px 0px;
  }

  .caption-cta {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }
`;
