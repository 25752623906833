export const columns = [
    {
      id: "name",
      label: "Name",
    },
    {
      id: "title",
      label: "Title",
    },
    {
      id: "licenceNo",
      label: "License No.",
    },
    {
      id: "email",
      label: "Email"
    },
    {
      id: "telephone",
      label: "Phone No.",
    },
    {
      id: "actions",
      label: "",
    },
  ]
  