import styled from 'styled-components';

export const MenuDropdownStyled = styled.div`
    .cs-dropdown {
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        background-color: #F0F4FA;
        gap: 8px;
        padding: 7px 14px 7px 10px;
        border-radius: 10px;
        .placeholder-img {
            border-radius: 50%;
            width: 18px;
            height: 18px;
            display: block;
        }

        .action-icon {
            color: #000;
        }

        .dropdown-title {
            font-size: 12px;
            font-weight: 500;
            color: #2460B8;
        }

        .dropdown-icon {
            width: 16px;
            margin-left: -4px;
            height: 16px;
            display: block;
            color: #2460B8;
        }
    }

    .dropdown-opened {
        box-shadow: 0 0 0 1px #2460B8;
    }
`