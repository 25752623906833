import React, { useState, useEffect } from 'react'
import ModalComponent from "../../../../../../components/modal-component/modal-component"
import { Box, Typography } from "@mui/material"
import { ButtonsBox, GrayBox, TeamDetailsModalContentStyled } from "./show-details-modal.styles"
import { Button } from "../../../../../../components/button/button"
import { ShowDetailsModalProps } from './types'
import { User } from '../../../../../../generated/graphql'

const ModalBody: React.FC<ShowDetailsModalProps> = ({ data, onAddAccount, onEditTeam }) => {
    const { users, admins } = data.users?.reduce(
        (acc: any, user: User) => {
            if (user.is_administrator) {
                acc.admins.push(user)
            }
            acc.users.push(user)
            return acc
        },
        { users: [] as User[], admins: [] as User[] }
    ) || { users: [], admins: [] }

    return (
        <TeamDetailsModalContentStyled>
            <GrayBox className="type-and-extension d-flex align-items-center">
                <div>
                    <Typography className="title">Team type</Typography>
                    <Typography className="content">{data.type}</Typography>
                </div>
                <div>
                    <Typography className="title">Extensions</Typography>
                    <Typography className="content">{data.extensions}</Typography>
                </div>
            </GrayBox>
            <GrayBox>
                <div>
                    <Typography className="title">Locations</Typography>
                    <Typography className="content">{data.locations}</Typography>
                </div>
            </GrayBox>
            {admins.length > 0 && <GrayBox>
                <div>
                    <Typography className="title">Administrators</Typography>
                    <Box className="tags">
                        {admins?.map((user: User, index: number) =>
                            <Typography key={index} className="tag">{user.email}</Typography>
                        )}
                    </Box>
                </div>
            </GrayBox>}
            {users.length > 0 && <GrayBox>
                <div>
                    <Typography className="title">Accounts</Typography>
                    <Box className="tags">
                        {users?.map((user: User, index: number) =>
                            <Typography key={index} className="tag">{user.email}</Typography>
                        )}
                    </Box>
                </div>
            </GrayBox>}
            <ButtonsBox>
                <Button variant="outlined" onClick={() => { onAddAccount(data) }}>Add account</Button>
                <Button onClick={() => { onEditTeam(data) }}>Edit</Button>
            </ButtonsBox>
        </TeamDetailsModalContentStyled>
    )
}

const ShowDetailsModal: React.FC<ShowDetailsModalProps> = ({ data, onClose, onAddAccount, onEditTeam }) => {
    const [openModal, setOpenModal] = useState<boolean>(false)

    useEffect(() => {
        setOpenModal(true)
    }, [data])

    const handleClose = () => {
        setOpenModal(false)
        onClose && onClose()
    }

    return (
        <div>
            <ModalComponent
                width="577px"
                title={data.name}
                content={<ModalBody data={data} onEditTeam={onEditTeam} onAddAccount={onAddAccount} onClose={onClose} />}
                open={openModal}
                onClose={handleClose}
            />
        </div>
    )
}

export default ShowDetailsModal
