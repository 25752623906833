import styled from "styled-components";

export const TextFieldStyle = styled.div`
  width: 100%;
  margin: 5px 0px;

  .MuiInputBase-root {
    width: 100%;
    height: 36px;
    background: #fff;
    font-size: 12px;
    line-height: 15px;
    color: #2b3952;

    input {
      z-index: 1;
    }
    &.Mui-focused {
      fieldset {
        &.MuiOutlinedInput-notchedOutline {
          border-color: #00b0cc !important;
        }
      }
    }
    &.Mui-disabled {
      cursor: default;
    }
  }

  .Mui-disabled {
    -webkit-text-fill-color: #b9bec7 !important;
  }

  .MuiFormHelperText-root {
    margin-left: 3px;
  }
`;
