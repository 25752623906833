import { Box, Typography } from "@mui/material"
import { ChatCardStyled } from "./chat-card.styles"
import moment from 'moment'
import { ChatCardData } from "../../types"

const ChatCard: React.FC<{
    data: ChatCardData,
    onClick: (data: ChatCardData) => void
}> = ({
    data,
    onClick
}) => {
        const handleChatCardClick = () => {
            onClick(data)
        }

        return (
            <ChatCardStyled onClick={handleChatCardClick} className={`${data.unread ? 'unread' : ''}`}>
                {data.icon && <Box className="icon-holder">
                    <span className="icon">
                        <img src={data.icon} alt="Phone" />
                    </span>
                </Box>}
                <Box className="content-holder">
                    <Box className="phone-number-and-time">
                        <Typography className="phone-number">{data.title}</Typography>
                        <Typography className="time">{moment(data.datetime).isValid() ? moment(data.datetime).fromNow() : data.datetime}</Typography>
                    </Box>
                    <Typography className="message">{data.content}</Typography>
                </Box>
            </ChatCardStyled>
        )
    }

export default ChatCard