import React from 'react';

import { ProjectStatsStyled } from './project-stats.styles';
import ProjectStatsIconWithCount from '../project-stats-icon-with-count/project-stats-icon-with-count';
import { ProjectStatsProps } from '../../../../types/types';

import briefcaseClosedIcon from '../../../../assets/icons/briefcase-closed.svg'
import briefcaseOpenedIcon from '../../../../assets/icons/briefcase-opened.svg'
import attachmentIcon from '../../../../assets/icons/attachment.svg'
import scannerIcon from '../../../../assets/icons/scanner.svg'

const ProjectStats: React.FC<ProjectStatsProps> = ({ hasAttachment, orderStartedCount, orderShippedCount, scanner}) => {
    return (
        <ProjectStatsStyled className="d-flex justify-content-start">
        <div className="d-flex project-stats">
            <ProjectStatsIconWithCount icon={briefcaseClosedIcon} count={orderShippedCount || 0} />
            <ProjectStatsIconWithCount icon={briefcaseOpenedIcon} count={orderStartedCount || 0} />
            {hasAttachment && <ProjectStatsIconWithCount icon={attachmentIcon} />}
        </div>
        {scanner && <>
            <img src={scannerIcon} alt="Scanner stat" width="22" height="22px" className="d-block scanner" />
            <span className="name">Scanner</span>
        </>}
        </ProjectStatsStyled>
    );
};

export default ProjectStats;
