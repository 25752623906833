import { USERS_MOCK } from "./mock";
import { graphql, HttpResponse } from "msw";

export const loginHandler = graphql.mutation("Login", async ({ variables }) => {
  const { email, password } = variables;

  // Find the user
  const user = USERS_MOCK.find(
    (user) => user.email === email && user.password === password
  );
  if (!user) {
    return HttpResponse.json({
      errors: [{ message: "Invalid email or password" }],
    });
  }

  const data = {
    login: {
      user: {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        token: user.token,
      },
      token: user.token,
    },
  };

  return HttpResponse.json({ data });
});
