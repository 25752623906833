import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import GuestRoutes from "./routes/guest-routes";
import UserRoutes from "./routes/user-routes";
import AdminRoutes from "./routes/admin-routes";

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/*" element={<GuestRoutes />} />
      <Route path="/dashboard" element={<UserRoutes />} />
      <Route path="/admin/*" element={<AdminRoutes />} />
      <Route path="*" element={<>Forbidden</>} />
    </Routes>
  );
};

export default App;