import React, { useState, useEffect } from "react"
import { v4 as uuid } from "uuid"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import ModalComponent from "../../../../../../components/modal-component/modal-component"
import { Button } from "../../../../../../components/button/button"
import { TextInputFormField } from "../../../../../../components/form-fields/text-input-field/text-input-form-field"
import { addEditTeamFormValidation } from "../../../../../../utils/validations/index"
import TagsInputFormField from "../../../../../../components/form-fields/tags-input-field/tags-input-form-field"
import ModalForm from "../../../../../../components/modal-form/modal-form"
import { ModalFormButtonsBox } from "../../../../../../components/modal-form/modal-form.styles"
import { addNotification } from "../../../../../../store/slices/ui-slice"
import { useAppDispatch } from "../../../../../../store/hooks"
import { AddEditTeamModalProps } from './types';
import { useCreateTeamMutation, User, useUpdateTeamMutation } from "../../../../../../generated/graphql"

const ModalBody: React.FC<AddEditTeamModalProps> = ({
  data,
  refetch,
  type,
  onClose,
}) => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { control, watch, formState } = useForm({
    mode: "all",
    resolver: yupResolver(addEditTeamFormValidation()),
  })

  const [addTeam] = useCreateTeamMutation({
    variables: {
      ...watch(),
      users: watch().users?.split(",") || [],
      administrator: [],
      company_id: 1,
    },
  })

  const [updateTeam] = useUpdateTeamMutation({
    variables: {
      ...watch(),
      users: watch().users?.split(",").filter(x => x) || [],
      id: `${data?.id}`,
      administrator: [],
      company_id: 1,
    },
  })

  const handleSubmit = async () => {
    setIsLoading(true)
    if (type === "Add") {
      try {
        const res = await addTeam()
        refetch && refetch()
        const message = res?.data?.createTeam.message || "Team added successfully."
        const details = res?.data?.createTeam.details || []
        const finalMessage = details.length > 0
          ? `${message} but there is no user for these ${details?.join(", ")}`
          : message
        dispatch(
          addNotification({
            id: uuid(),
            type: details.length > 0 ? "warning" : "success",
            message: finalMessage,
          })
        )
      } catch (err: any) {
        dispatch(
          addNotification({
            id: uuid(),
            type: "error",
            message: err?.message,
          })
        )
      } finally {
        setIsLoading(false)
      }
    } else {
      try {
        const res = await updateTeam()
        refetch && refetch()
        const message = res?.data?.updateTeam.message || "Team updated successfully."
        const details = res?.data?.updateTeam.details || []
        const finalMessage = details.length > 0
          ? `${message} but there is no user for these ${details?.join(", ")}`
          : message
        dispatch(
          addNotification({
            id: uuid(),
            type: details.length > 0 ? "warning" : "success",
            message: finalMessage,
          })
        )
      } catch (err: any) {
        dispatch(
          addNotification({
            id: uuid(),
            type: "error",
            message: err?.message,
          })
        )
      } finally {
        setIsLoading(false)
      }
    }
    onClose && onClose()
  }

  const isValid = formState?.isValid

  return (
    <ModalForm>
      <TextInputFormField
        name="name"
        label="Team Name"
        control={control}
        defaultValue={data?.name}
        placeholder="Enter here"
      />
      <TextInputFormField
        name="type"
        label="Team Type"
        control={control}
        defaultValue={data?.type}
        placeholder="Enter here"
      />
      <TagsInputFormField
        name="extensions"
        label="Extensions"
        control={control}
        defaultValue={data?.extensions}
        placeholder="Enter here"
      />
      <TagsInputFormField
        name="locations"
        label="Locations"
        control={control}
        defaultValue={data?.locations}
        placeholder="Enter here"
      />
      <TagsInputFormField
        name="users"
        label="Accounts"
        control={control}
        defaultValue={data?.users?.map((user: User) => user.email).join(",")}
        placeholder="Enter here"
      />
      <ModalFormButtonsBox>
        <Button
          disabled={!isValid}
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          {data ? "Save changes" : "Add team"}
        </Button>
      </ModalFormButtonsBox>
    </ModalForm>
  )
}

const AddEditTeamModal: React.FC<AddEditTeamModalProps> = ({
  data,
  refetch,
  onClose,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false)

  useEffect(() => {
    setOpenModal(true)
  }, [])

  const handleClose = () => {
    setOpenModal(false)
    onClose && onClose()
  }

  return (
    <div>
      <ModalComponent
        title={`${data ? "Edit" : "Add"} team`}
        content={
          <ModalBody
            refetch={refetch}
            data={data}
            type={`${data ? "Edit" : "Add"}`}
            onClose={handleClose}
          />
        }
        open={openModal}
        onClose={handleClose}
      />
    </div>
  )
}

export default AddEditTeamModal
