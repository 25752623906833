import styled from 'styled-components';

export const HistoryCardStyled = styled.div`
    .card-content {
        flex-grow: 1;
        padding: 0 10px;
        .call-details-wrapper {
            display: flex;
        }
        .divider {
            border: none;
            margin: 11px 0px;
            border-Bottom: 1px solid #CFCFCF;
            height: 1px;
            width: 100%;
        }
    }
    .container {
        padding: 12px 10px 16px;
        background-color: #ffffff;
        border: 1px solid transparent;
        box-shadow: 0px 0px 12px 0px #00000026;
        border-radius: 10px;
    }
    .ongoing-call-container {
        background-color: #27B82405;
        border: 1px solid #27B824;
        box-shadow: 0px 0px 4px 0px #F0F0F066;
    }
    .status-chip {
        gap: 8px;
    }
`
