import React from 'react';

import { BadgeStyled } from './status-chip.styles';

interface StatusChipProps {
    variant: string,
    label: string
}

const StatusChip: React.FC<StatusChipProps> = ({ variant, label }) => {
    return (
        <BadgeStyled className={variant === 'success' ? 'success-badge' : 'danger-badge'}>
            {label}
        </BadgeStyled>
    );
};

export default StatusChip;
