import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { BoxStyled, ModalComponentStyled } from './modal-component.styles';
import { Close } from '@mui/icons-material';
import Fade from '@mui/material/Fade';
import { Box } from '@mui/material';
import { ModalComponentProps } from '../../types/types';


const ModalComponent: React.FC<ModalComponentProps> = ({ title, content, open, onClose, width }) => {
    return (
        <ModalComponentStyled>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
            >
                <Fade in={open}>
                    <BoxStyled width={width}>
                        <div className="title-container d-flex align-items-center justify-content-space-between">
                            {title && <Typography id="modal-modal-title">
                                {title}
                            </Typography>}
                            <span className="close-icon" onClick={onClose}>
                                <Close />
                            </span>
                        </div>
                        <Box className="content-container">
                            {content}
                        </Box>
                    </BoxStyled>
                </Fade>
            </Modal>
        </ModalComponentStyled>
    )
}

export default ModalComponent