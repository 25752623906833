//lib
import { useEffect, useState } from "react";
import Notification from "./notification";

//src
import { isSomething } from "../../utils/common-utils";
import { deleteNotification } from "../../store/slices/ui-slice";
import { NotificationType } from "../../store/slices/ui.slice.type";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

const NotificaionsWrapper = () => {
  const [notification, setNotification] = useState<NotificationType | null>();

  const dispatch = useAppDispatch();
  const notifications = useAppSelector((state) => state.UI.notifications);

  useEffect(() => {
    if (isSomething(notifications) && !isSomething(notification))
      setTimeout(() => {
        setNotification(notifications[0]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  const clearNotification = () => {
    setNotification(null);
    dispatch(deleteNotification({ id: notification?.id }));
  };

  return (
    <div>
      {!!notification && (
        <Notification
          key={notification.id}
          message={notification.message}
          type={notification.type}
          onClose={() => {
            clearNotification();
          }}
        />
      )}
    </div>
  );
};

export default NotificaionsWrapper;
