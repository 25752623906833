import React from "react";
import ReactDOM from "react-dom/client";
import { setContext } from '@apollo/client/link/context';

import "./index.css";
import App from "./App";
import { worker } from "./mocks/browser";
import {
  ApolloClient,
  ApolloProvider,
  DefaultOptions,
  InMemoryCache,
  ApolloLink,
  HttpLink
} from "@apollo/client";
import ThemeProvider from "./theme/theme-provider";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

let reactAppApiUrl = process.env.REACT_APP_API_URL ||  'https://services-primevox-dev.vetsuite.io/graphql'
let reactAppUseMock = process.env.REACT_APP_USE_MOCK || 'false'

const useMockServer = reactAppUseMock
  ? reactAppUseMock === "true"
  : true;

if (useMockServer) {
  worker.start();
}

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  };
});
const httpLink = new HttpLink({
  uri: reactAppApiUrl || "/graphql",
});


const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

async function enableMocking() {
  if (!useMockServer) {
    // Because this function needs to return a promise that's why in case mock server
    // is disabled, we are returning an empty promise so the .then() doesn't breaks
    return new Promise((myResolve) => {
      myResolve(true);
    });
  }

  const { worker } = await import("./mocks/browser");

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start();
}

enableMocking().then(() => {
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <React.StrictMode>
          <ApolloProvider client={client}>
            <ThemeProvider>
              <App />
            </ThemeProvider>
          </ApolloProvider>
        </React.StrictMode>
      </BrowserRouter>
    </Provider>
  );
});
