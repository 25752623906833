import Pusher, { Channel } from 'pusher-js/with-encryption';
import { useState, useEffect } from 'react';

const pusherKey = String(process.env.REACT_APP_PUSHER_KEY)
const pusherCluster = String(process.env.REACT_APP_PUSHER_CLUSTER)
let pusher = new Pusher(pusherKey, {
    cluster: pusherCluster,
});

const channels : {[key: string]: Channel} = {}

const bindings : {[key: string]: any} = {}
let eventMessages : {[key: string]: any} = {}

// const channel = pusher.subscribe('labtrac-backend');

// channel.bind('call-event', (data: any) => {
//   console.log("CHANGING")
// });

const usePusher = <T>(channelName: string, eventName: string) => {
  const [state, setData] = useState<T[]>([]);

  useEffect(() => {
    if (!channels[channelName]) {
        channels[channelName] = pusher.subscribe(channelName);
    }
    
    const channel = channels[channelName]
    const eventKey = `${channelName}-${eventName}`

    if (!bindings[channelName]) {
      bindings[eventKey] = channel.bind(eventName, (data: any) => {
        const event = new CustomEvent(eventKey, { detail: { message: data } });
        window.dispatchEvent(event);
      });
    }

    const handleEvent = (event: any) => {
      try{
        const value = JSON.parse(event.detail.message.message.data)
        let messages = eventMessages[eventKey] || []
        messages = [value, ...messages]
        eventMessages[eventKey] = messages
        setData(eventMessages[eventKey]);
      }catch(e){
        console.log(e)
      }
    }

    window.addEventListener(eventKey, handleEvent);

    return () => {
      window.removeEventListener(eventKey, handleEvent);
    };
  }, [channelName, eventName]);

  return state;
};

export default usePusher;
