import React from 'react';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';

import { StyledBox, TabIndicatorProps } from './custom-tabs.styles';
import { CustomTabsProps } from '../../types/types';


const CustomTabs: React.FC<CustomTabsProps> = ({ handleTabChange, tabs }) => {
  return (
    <StyledBox>
      <TabList TabIndicatorProps={TabIndicatorProps} onChange={handleTabChange}>
        {tabs.map(tab => (
          <Tab
            className="tab"
            key={tab}
            label={tab}
            value={tab}
          />
        ))}
      </TabList>
    </StyledBox>
  );
};

export default CustomTabs;
