import React from 'react';
import Box from '@mui/material/Box';

import { HistoryCardStyled } from './history-card.styles';
import ProgressBars from '../progress-bars/progress-bars'
import RenderCallerDetails from '../render-call-details/render-caller-details'
import DoctorDetails from '../doctor-details/doctor-details'
import ActionsBar from '../actions-bar/actions-bar'
import WarningIconLink from '../warning-icon-link/warning-icon-link'
import ProjectStats from '../project-stats/project-stats'
import StatusChip from '../status-chip/status-chip'
import { HistoryCardProps } from '../../../../types/types';

const HistoryCard: React.FC<HistoryCardProps> = ({ data, refetchCalls }) => {
    const className = data.callType === 'ongoing' ? 'container ongoing-call-container' : 'container';
    
    return (
        <HistoryCardStyled>
            <Box className={className}>
                <div className='d-flex'>
                    <ProgressBars level={data.value} />
                    <div className='card-content'>
                        <div className='call-details-wrapper'>
                            <RenderCallerDetails 
                                type={data.callType}
                                isOngoing={data.isOngoing}
                                isAnswered={data.isAnswered}
                                voicemailLeft={data.voicemailLeft}
                                contactName={data.contactName}
                                contactPhoneNumber={data.contactPhoneNumber}
                                callingTime={data.callingTime} 
                            />
                            <DoctorDetails 
                                contactId={data.contactId}
                                doctorId={data.doctorId}
                                officeId={data.officeId}

                                callerPhoneNumber={data.contactPhoneNumber}
                                doctorName={data.doctorName}
                                officeName={data.officeName} 

                                refetchCalls={refetchCalls}
                            />
                            {(data.callType === 'outgoing' || data.callType === 'incoming') && <ActionsBar />}
                            {data.callType === 'missed' && <WarningIconLink />}
                        </div>
                        <hr className='divider' />
                        <div className='d-flex justify-content-between'>
                            <ProjectStats hasAttachment={data.hasAttachment} orderStartedCount={data.orderStartedCount} orderShippedCount={data.orderShippedCount} scanner={data.scanner} />
                            {(data.route || data.cod) && <Box className="d-flex status-chip">
                                {data.route && <StatusChip variant="success" label={data.route} />}
                                {data.cod && <StatusChip variant="danger" label="COD" />}
                            </Box>}
                        </div>
                    </div>
                </div>
            </Box>
        </HistoryCardStyled>
    );
};

export default HistoryCard;
