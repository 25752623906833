import styled from 'styled-components';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';

export const CallsDashboardContainer = styled.div`
  height: 100%;
  padding: 16px;
`;

export const SectionTitle = styled.h1`
  font-weight: 600;
  font-size: 16px;
  color: #404040;
  margin: 0;
  margin-bottom: 12px;
`;

export const HistoryComponent = styled.div`
  margin-top: 34px;
`;

export const HistoryTabs = styled(Box)`
  border-bottom: 1px solid #e0e0e0;
  .tab-list {
    border-bottom: 1px solid #e0e0e0;
  }
  .MuiTab-root {
      font-weight: 500;
      color: #70767B;
      font-size: 12px;
      text-transform: none;
      &.Mui-selected {
          color: #2460B8 !important;
          font-weight: 600 !important;
      }
    }
`;


export const AddIconStyled = styled(AddIcon)`
  color: #70767B;
`;
