import styled from "styled-components";

export const StyledChatButton = styled.div`
  display: flex;
  justify-content: flex-end;

  .chat-wrapper {
    position: fixed;
    bottom: 70px;
    background-color: #fff !important;
    border: 1px solid #DFDFDF !important;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0px 4px 4px 0px #00000040 !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    &[aria-expanded="true"] {
      border: 1px solid #888888 !important;
      box-shadow: 0px 4px 4px 0px #00000040 !important;
      background-color: #E1E1E1 !important;
    }
  }

  .icon-wrapper {
    position: relative;
    svg {
      display: block;
      color: #70767B;
    }
  }

  .chat-circle {
    position: absolute;
    top: -15px;
    right: -16px;
    width: 20px;
    height: 20px;
    background-color: #B83F24;
    border-radius: 50%;
    color: #fff;
    border: 1px solid #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 10px;
  }
`;
