import { graphql as executeGraphQL, buildSchema } from "graphql";
import { HttpResponse, graphql } from "msw";
import { CALLS_LIST_MOCK } from "./mock-data/mock-data";
import { NOTIFICATIONS_LIST_MOCK } from "./mock-data/notifications-mock-data";
import { loginHandler } from "./handlers/login/handler";
import { signupHandler } from "./handlers/signup/handler";
import { addTeamHandler, editTeamHandler } from "./handlers/teams/handler";
import { TEAMS_MOCK } from "./mock-data/teams-mock";
import { createContactHandler } from "./handlers/contacts/handler";

const schema = buildSchema(`
  type StatusChip {
    variant: String!
    label: String!
  }

  type DoctorType {
    id: String!
    doctorName: String!
    doctorLocation: String!
    callerName: String!
    callerPhoneNumber: String!
    callingTime: String!
    statusChips: [StatusChip!]!
    callType: String!
    progressLevel: Int!
    hasAttachment: Boolean!
    orderStartedCount: Int!
    orderShippedCount: Int!
  }

  type Data {
    list: [DoctorType!]
    total: Int!
  }

  type OverviewData {
    ongoingCallsTotal: Int!,
    missedCallsTotal: Int!,
    returnedCallsTotal: Int!,
    incomingCallsTotal: Int!,
    outgoingCallsTotal: Int!
  }
  
  type Notifications {
    id: String!,
    type: String!,
    doctorName: String!,
    phoneNumber: String!,
    time: String!,
    message: String 
  }

  type NotificationsList {
    list: [Notifications!]
    total: Int!
  }

  type Teams {
    id: String!
    name: String!
    type: String!
    locations: String!
    extensions: String!
    accounts: String
  }

  type TeamsList {
    list: [Teams!]
    total: Int!
  }

  type Offices {
    id: ID!
    name: String!
  }

  type Doctors {
    id: ID!
    name: String!
  }

  type Query {
    callsList: Data
    callsOverview: OverviewData
    notificationsList: NotificationsList
    teamsList: TeamsList
    offices: Offices
    doctors: Doctors
  }
`);
export const handlers = [
  graphql.query("callsList", async ({ query, variables }) => {
    const { errors, data } = await executeGraphQL({
      schema,
      source: query,
      variableValues: variables,
      rootValue: {
        callsList: {
          list: CALLS_LIST_MOCK,
          total: CALLS_LIST_MOCK.length,
        },
      },
    });

    return HttpResponse.json({ errors, data });
  }),
  graphql.query("callsOverview", async ({ query, variables }) => {
    const { errors, data } = await executeGraphQL({
      schema,
      source: query,
      variableValues: variables,
      rootValue: {
        callsOverview: {
          ongoingCallsTotal: CALLS_LIST_MOCK.filter(item => item.callType === "ongoing").length,
          missedCallsTotal: CALLS_LIST_MOCK.filter(item => item.callType === "missed").length,
          returnedCallsTotal: CALLS_LIST_MOCK.filter(item => item.callType === "returned").length,
          incomingCallsTotal: CALLS_LIST_MOCK.filter(item => item.callType === "incoming").length,
          outgoingCallsTotal: CALLS_LIST_MOCK.filter(item => item.callType === "outgoing").length,
        },
      },
    });

    return HttpResponse.json({ errors, data });
  }),
  graphql.query("notificationsList", async ({ query, variables }) => {
    const { errors, data } = await executeGraphQL({
      schema,
      source: query,
      variableValues: variables,
      rootValue: {
        notificationsList: {
          list: NOTIFICATIONS_LIST_MOCK,
          total: NOTIFICATIONS_LIST_MOCK.length,
        },
      },
    });

    return HttpResponse.json({ errors, data });
  }),
  graphql.query("teamsList", async ({ query, variables }) => {
    const { errors, data } = await executeGraphQL({
      schema,
      source: query,
      variableValues: variables,
      rootValue: {
        teamsList: {
          list: TEAMS_MOCK,
          total: TEAMS_MOCK.length,
        },
      },
    });

    return HttpResponse.json({ errors, data });
  }),
  graphql.query("offices", async ({ query, variables }) => {
    const { errors, data } = await executeGraphQL({
      schema,
      source: query,
      variableValues: variables,
      rootValue: {
        offices: {
          id: "1",
          name: "Test",
        },
      },
    });

    return HttpResponse.json({ errors, data });
  }),
  graphql.query("doctorsList", async ({ query, variables }) => {
    const { errors, data } = await executeGraphQL({
      schema,
      source: query,
      variableValues: variables,
      rootValue: {
        doctorsList: {
          id: "1",
          name: "Test",
        },
      },
    });

    return HttpResponse.json({ errors, data });
  }),
  loginHandler,
  signupHandler,
  addTeamHandler,
  editTeamHandler,
  createContactHandler
];
