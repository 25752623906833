import styled from "styled-components"

export const TableMenuDropdownStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;
    .cs-dropdown {
        background: none;
        padding: 0;
        justify-content: right;
        gap: 0;
        border-radius: 0;
        box-shadow: none;
        width: 24px;
        height: 24px;
        &.dropdown-opened {
            background: #F0F4FA;
            border-radius: 100%;
            .action-icon {
                color: #2460B8;
            }
        }
        .action-icon {
            color: #70767B;
            width: 18px;
            height: 18px;
            display: block;
            margin: 0 auto;
        }
    }
`