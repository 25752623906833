import styled from "styled-components";

export const BadgeStyled = styled.div`
  font-weight: 600;
  font-size: 11px;
  width: 54px;
  height: 20px;
  border-radius: 99px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.success-badge {
    color: #27b824;
    background-color: #27b8242b;
  }

  &.danger-badge {
    color: #b83f24;
    background-color: #b83f242b;
  }
`;
