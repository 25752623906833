import { useState } from "react";
import { SplitTextIntoPopoverProps } from "../../types/types";
import Popover from '@mui/material/Popover';
import { Typography } from "@mui/material";
import { PopoverStyled, SplitTextIntoPopoverStyled } from "./split-text-into-popover.styles";


const SplitTextIntoPopover: React.FC<SplitTextIntoPopoverProps> = ({ text, length = 4, delimeter = ',' }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const splittedText = text.split(delimeter)
    const mainText = splittedText.slice(0, length).join(delimeter)
    const extraText = splittedText.slice(length).join(delimeter)

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <SplitTextIntoPopoverStyled className={`${Boolean(anchorEl) ? 'popover-opened' : ''}`}>
            {!Boolean(extraText) && mainText}
            {Boolean(extraText) && <>
                <span onClick={handleOpen}>
                    {mainText}
                    <span className="extra-text-counter">&nbsp;+{splittedText.length - length}</span>
                </span>
                <PopoverStyled
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Typography>{text}</Typography>
                </PopoverStyled>
            </>}
        </SplitTextIntoPopoverStyled>
    )
}

export default SplitTextIntoPopover