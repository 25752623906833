import React, { useState, useEffect } from 'react'

import ModalComponent from "../../../../../../components/modal-component/modal-component"
import { Button } from "../../../../../../components/button/button"
import { RevokeAccessModalBodyStyled } from './revoke-access-modal.styles'
import { Typography } from '@mui/material'
import { ACCOUNT_STATUSES } from '../../../../../../constants'
import { useAppDispatch } from '../../../../../../store/hooks'
import { addNotification } from '../../../../../../store/slices/ui-slice'
import { v4 as uuid } from "uuid"
import { RevokeAccessModalProps } from './types'
import { useUpdateUserStatusMutation } from '../../../../../../generated/graphql'


const ModalBody: React.FC<RevokeAccessModalProps> = ({ refetch, data, onClose }) => {
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [updateUserStatus] = useUpdateUserStatusMutation({
        variables: {
            id: `${data.id}`,
            status: ACCOUNT_STATUSES.NOT_ACTIVE
        }
    })

    const handleConfirm = async () => {
        try {
            setIsLoading(true)
            await updateUserStatus()
            refetch && refetch()
            dispatch(
                addNotification({
                    id: uuid(),
                    type: "success",
                    message: "Account has been revoked successfully.",
                })
            )
            onClose && onClose()
        } catch (err: any) {
            dispatch(
                addNotification({
                    id: uuid(),
                    type: "error",
                    message: err?.message,
                })
            )
        } finally {
            setIsLoading(false)
        }
    }
    return (
        <RevokeAccessModalBodyStyled>
            <Typography className='content'>Are you sure to revoke access of {data.first_name || data.last_name ? <b>{`${data.first_name} ${data.last_name}`.trim()}’s</b> : ''} account {data.email}?</Typography>
            <Button onClick={handleConfirm} isLoading={isLoading} className="confirm-button">Confirm</Button>
        </RevokeAccessModalBodyStyled>
    )
}

const RevokeAccessModal: React.FC<RevokeAccessModalProps> = ({ refetch, data, onClose }) => {
    const [openModal, setOpenModal] = useState<boolean>(false)

    useEffect(() => {
        setOpenModal(true)
    }, [])

    const handleClose = () => {
        setOpenModal(false)
        onClose && onClose()
    }

    return (
        <div>
            <ModalComponent
                title="Revoke Access"
                content={<ModalBody data={data} refetch={refetch} onClose={handleClose} />}
                open={openModal}
                onClose={handleClose}
            />
        </div>
    )
}

export default RevokeAccessModal
