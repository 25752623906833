import { TextField } from "@mui/material";
import styled from "styled-components";

export const TagsFieldStyle = styled.div`
  width: 100%;
  margin: 5px 0px;

  .tags-field-wrapper {
    border: 1px solid #C3CCD7;
    min-height: 40px;
    border-radius: 10px;
    padding: 7px 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    box-shadow: 0px 1px 2px 0px #1018280D;
    &:focus-within {
      border-color: #00b0cc !important;
    }
    .tag {
      display: inline-flex;
      background: #F0F4FA;
      border-radius: 8px;
      height: 26px;
      padding: 2.5px 9px 2.5px 8px;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      .MuiChip-label {
        padding: 0;
        color: #404040;
        font-weight: 400;
        font-size: 14px;
      }
      .MuiSvgIcon-root {
        margin: 0;
        color: #555555;
        display: block;
        width: 15px;
        height: 15px;
      }
    }
  }

  .MuiFormHelperText-root {
    margin-left: 3px;
  }
`;

export const TextFieldStyled = styled(TextField)`
  .MuiInputBase-root {
    height: auto !important;
    background: #fff;
    font-size: 12px;
    color: #2b3952;
    &.Mui-disabled {
      cursor: default;
      -webkit-text-fill-color: #b9bec7 !important;
    }
  }
  .MuiInputBase-input {
    z-index: 1;
    padding: 0;
    margin: 0;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }
`