import { styled } from "styled-components";

export const StyledModalBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24px;
  padding: 16px;
  max-width: 318px;
  border-radius: 10px;
  border: none;

  .heading {
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins';
    margin: 0;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;