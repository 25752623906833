import styled from 'styled-components';

export const ProjectStatsStyled = styled.div`
    .project-stats {
        gap: 29px;
    }
    .scanner {
        margin-left: 27px;
    }
    .name {
        padding-left: 6px;
        font-size: 12px;
        font-weight: 500;
        color: #444444;
        display: flex;
        align-items: center;
    }
`