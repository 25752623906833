export const appTheme = {
  palette: {
    primary: {
      main: "#545f71",
      dark: "#00B0CC",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#667085",
      dark: "#B9BEC7",
      contrastText: "#2B3952",
    },
    danger: {
      main: "#EB0000",
      contrastText: "#FFFFFF",
    },
    action: {
      disabled: "#DBDFE6",
    },
  },
  typography: {
    fontFamily: "Raleway, Arial",

    h1: {
      fontSize: 24,
      fontWeight: 600,
    },

    h2: {
      fontSize: 22,
      fontWeight: 600,
    },
    h3: {
      fontSize: 20,
      fontWeight: 600,
    },
    h4: {
      fontSize: 18,
      fontWeight: 600,
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
    },

    subtitle1: {
      fontSize: 8,
    },
    body1: {
      fontSize: 18,
      fontWeight: 400,
    },
    body2: {
      fontSize: 16,
      fontWeight: 400,
    },
    body3: {
      fontSize: 14,
      fontWeight: 400,
    },
    body4: {
      fontSize: 12,
      fontWeight: 400,
    },
    body5: {
      fontSize: 11,
      fontWeight: 400,
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "600",
        },
      },
      variants: [
        {
          props: { variant: "outlined" as const },
          style: {
            backgroundColor: "transparent",
            color: "#301D35",
            border: `1px solid #301D35`,
            "&:hover": {
              backgroundColor: "#301D35",
              color: "white",
              border: `1px solid #301D35`,
            },
          },
        },
        {
          props: { variant: "text" as const },
          style: {
            backgroundColor: "transparent",
            color: "#2460b8",
            border: `none`,
            minWidth: "fit-content",
            // textTransform: "capitalize",
          },
        },
        {
          props: { variant: "contained" as const },
          style: {
            backgroundColor: "#2460b8",
            inlineBorder: `1px solid #301D35`,
            borderRadius: "8px",
            "&:hover": {
              color: "white",
              backgroundColor: "#9298a8",
              inlineBorder: `1px solid #301D35`,
            },
            "&:disabled": {
              backgroundColor: "#2460b8",
              opacity: "0.6",
            },
          },
        },
      ],
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
};

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    body5: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    body5?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
    body5: true;
  }
}
