import styled from "styled-components";

export const StyledNotificationButton = styled.div`
  display: flex;
  justify-content: flex-end;

  .notification-wrapper {
    position: fixed;
    bottom: 20px;
    background-color: white;
    border: 1px solid #70767B;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }

  .icon-wrapper {
    position: relative;
    svg {
      display: block;
      color: #70767B;
    }
  }

  .notification-circle {
    position: absolute;
    top: 1px;
    right: 2px;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
  }
`;
