//lib
import React, { useState } from "react"
import { v4 as uuid } from "uuid"

import { useForm } from "react-hook-form"
import { Typography } from "@mui/material"
import { yupResolver } from "@hookform/resolvers/yup"

//src
import logo from "../../assets/imgs/logo.png"
import { LoginStyled } from "./login.styles"
import { Button } from "../../components/button/button"
import { loginFormValidation } from "../../utils/validations"
import TextInputFormField from "../../components/form-fields/text-input-field/text-input-form-field"
import { useNavigate } from "react-router-dom"
import { setUserInSession } from "../../utils/common-utils"
import { useAppDispatch } from "../../store/hooks"
import { addNotification } from "../../store/slices/ui-slice"
import { useLoginMutation } from "../../generated/graphql"

const Login: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { control, watch, formState } = useForm({
    mode: "all",
    resolver: yupResolver(loginFormValidation()),
  })

  const [login] = useLoginMutation({
    variables: watch(),
  })

  const onLogin = async () => {
    try {
      setIsLoading(true)
      const res = await login()
      if (res?.data?.login && res?.data?.login.token && res?.data?.login.user) {
        setUserInSession(res.data.login)

        dispatch(
          addNotification({
            id: uuid(),
            type: "success",
            message: "Successfully logged in.",
          })
        )

        navigate('/dashboard')
      } else {
        const error = res?.data?.login.error
        dispatch(
          addNotification({
            id: uuid(),
            type: "error",
            message: error ? error : "Something went wrong, please try again.",
          })
        )
      }
    } catch (err: any) {
      dispatch(
        addNotification({
          id: uuid(),
          type: "error",
          message: err?.message,
        })
      )
    } finally {
      setIsLoading(false)
    }
  }
  const onForgotPasswordClick = () => {
    navigate("/forgot-password")
  }
  const isValid = formState?.isValid
  return (
    <LoginStyled>
      <div className="logo">
        <img src={logo} alt={"asd"} />
      </div>
      <div className="heading">
        <Typography
          variant="h1"
          color={"primary"}
          sx={{ margin: "35px 0px 20px 0px" }}
        >
          Login
        </Typography>
      </div>

      <div className="form">
        <TextInputFormField
          name="email"
          label="Email"
          control={control}
          placeholder="Enter here"
        />
        <TextInputFormField
          name="password"
          label="Password"
          control={control}
          inputType="password"
          placeholder="Enter here"
        />
      </div>
      <div className="forgot-cta">
        <Button
          variant="text"
          className="btn-style"
          onClick={onForgotPasswordClick}
        >
          Forgot Password?
        </Button>
      </div>

      <div className="btn">
        <Button sx={{ width: "100%" }} isLoading={isLoading} disabled={!isValid} onClick={onLogin}>
          Login
        </Button>
      </div>
    </LoginStyled>
  )
}

export default Login
