import React, { useState, KeyboardEvent, ChangeEvent, useEffect } from 'react'
import { TagsFieldStyle, TextFieldStyled } from './tags-input-field.style'
import { Typography, Chip, Box } from '@mui/material'
import { Close } from '@mui/icons-material'
import { TagsFieldProps } from '../common-types'


const TagsInputField: React.FC<TagsFieldProps> = (props) => {
  const {
    name,
    label,
    value,
    error,
    disabled,
    className,
    placeholder,
    onChange,
    helperText = null,
    InputProps,
    delimeter = ","
  } = props

  const [tags, setTags] = useState<string[]>([])
  const [inputValue, setInputValue] = useState<string>('')

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      event.preventDefault()
      if (!tags.includes(inputValue.trim())) {
        const newTags = [...tags, inputValue.trim()]
        setTags(newTags)
        setInputValue('')
        onChange && onChange(newTags?.join(delimeter))
      }
    } else if (event.key === 'Backspace' && !inputValue.trim() && tags.length > 0) {
      event.preventDefault()
      const newTags = tags.slice(0, -1)
      setTags(newTags)
      onChange && onChange(newTags?.join(delimeter))
    }
  }

  const handleDelete = (tagToDelete: string) => () => {
    const newTags = tags.filter((tag) => tag !== tagToDelete)
    setTags(newTags)
    onChange && onChange(newTags?.join(delimeter))
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  useEffect(() => {
    if (typeof value === 'string') {
      setTags(value.split(delimeter).map((x: any) => x.trim()).filter(x => x))
    } else if (typeof value === 'object') {
      setTags(value.map((x: any) => x.trim()).filter(x => x))
    }
  }, [])

  return (
    <TagsFieldStyle className={className}>
      <div>
        {label && (
          <Typography
            color={'primary'}
            variant="body3"
            style={{ fontWeight: '600' }}
          >
            {label}
          </Typography>
        )}
        <Box className="tags-field-wrapper">
          {tags.map((tag, index) => (
            <Chip
              className="tag"
              key={index}
              label={tag}
              onDelete={handleDelete(tag)}
              deleteIcon={<Close />}
            />
          ))}
          <TextFieldStyled
            value={inputValue}
            id={name}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            disabled={disabled}
            error={!!error}
            autoComplete="off"
            helperText={error?.message || helperText || null}
            InputProps={InputProps}
            sx={{ flexGrow: 1, minWidth: '120px' }}
          />
        </Box>
        <input type="hidden" name={name} value={tags.join(delimeter)} />
      </div>
    </TagsFieldStyle>
  )
}

export default TagsInputField
