import styled from "styled-components";

export const StyledNotifications = styled.div`
  padding: 20px 12px;
  border-radius: 10px;
  background: #fff;
  z-index: 9;
  &.notification {
    width: 268px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 100%;
  }

  .notification-wrapper {
    gap: 12px;
    margin-top: 16px;
  }

  .notifications-title {
    font-size: 16px;
    font-weight: 600;
    font-family: "Poppins";
    color: #404040;
  }

  .notification-item {
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 0;

    &:last-child {
      border-bottom: none;
    }

    .notification-title {
      font-size: 18px;
      font-weight: 500;
    }

    .notification-description {
      font-size: 14px;
      color: #555;
    }
  }
`;
