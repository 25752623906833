import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import ModalComponent from "../../../../../../components/modal-component/modal-component"
import { Button } from "../../../../../../components/button/button"
import { inviteUsersToAccountsFormValidation } from "../../../../../../utils/validations/index"
import ModalForm from '../../../../../../components/modal-form/modal-form'
import { ModalFormButtonsBox } from '../../../../../../components/modal-form/modal-form.styles'
import TextInputFormField from '../../../../../../components/form-fields/text-input-field/text-input-form-field'
import { addNotification } from '../../../../../../store/slices/ui-slice'
import { v4 as uuid } from "uuid"
import { useAppDispatch } from '../../../../../../store/hooks'
import { getSessionUser } from '../../../../../../utils/common-utils'
import { InviteUsersModalProps } from './types'
import { useInviteeMutation } from '../../../../../../generated/graphql'

const ModalBody: React.FC<InviteUsersModalProps> = ({ refetch, onClose }) => {
    const auth = getSessionUser()
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const { control, watch, formState } = useForm({
        mode: "all",
        resolver: yupResolver(inviteUsersToAccountsFormValidation()),
    })

    const [invitee] = useInviteeMutation({
        variables: { ...watch(), invited_by: Number(auth?.user?.id) }
    })

    const handleSubmit = async () => {
        try {
            setIsLoading(true)
            const res = await invitee()
            refetch && refetch()
            dispatch(
                addNotification({
                    id: uuid(),
                    type: "success",
                    message: res?.data?.invitee?.message || "Invitation has been sent successfully.",
                })
            )
            onClose && onClose()
        } catch (err: any) {
            dispatch(
                addNotification({
                    id: uuid(),
                    type: "error",
                    message: err?.message,
                })
            )
        } finally {
            setIsLoading(false)
        }
    }

    const isValid = formState?.isValid

    return (
        <ModalForm>
            <TextInputFormField
                name="invitee"
                label="Enter email"
                control={control}
                placeholder="Enter here"
            />
            <ModalFormButtonsBox>
                <Button disabled={!isValid} isLoading={isLoading} onClick={handleSubmit}>Confirm</Button>
            </ModalFormButtonsBox>
        </ModalForm>
    )
}

const InviteUsersModal: React.FC<InviteUsersModalProps> = ({ refetch, onClose }) => {
    const [openModal, setOpenModal] = useState<boolean>(false)

    useEffect(() => {
        setOpenModal(true)
    }, [])

    const handleClose = () => {
        setOpenModal(false)
        onClose && onClose()
    }

    return (
        <div>
            <ModalComponent
                title="Invite User"
                content={<ModalBody refetch={refetch} onClose={handleClose} />}
                open={openModal}
                onClose={handleClose}
            />
        </div>
    )
}

export default InviteUsersModal
