import styled from "styled-components";

export const PhoneNumberFieldStyled = styled.div`
  width: 100%;
  margin: 5px 0px;

  .form-control {
    width: 100%;
  }
  .open {
    ul {
      width: 460px;
    }
  }

  .react-tel-input .form-control:focus {
    border-color: #00b0cc !important;
    box-shadow: 0px 0px 0px 1px #00b0cc;
  }
  .react-tel-input .form-control:hover {
    border-color: #2b3952 !important;
    border-width: 1px;
  }
`;
