//lib
import React from "react";
import { useForm } from "react-hook-form";
import { IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

//src
import logo from "../../assets/imgs/logo.png";
import { ForgotPasswordStyled } from "./forgot-password.styles";
import { Button } from "../../components/button/button";
import TextInputFormField from "../../components/form-fields/text-input-field/text-input-form-field";
import { forgotPasswordValidation } from "../../utils/validations";
import { useResetPasswordMutation } from "../../generated/graphql";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const { control, watch, formState } = useForm({
    mode: "all",
    resolver: yupResolver(forgotPasswordValidation()),
  });

  const [resetPassword] = useResetPasswordMutation({
    variables: { email: watch()?.email },
  });

  const onBackClick = () => {
    navigate("/login");
  };

  const onReset = async () => {
    try {
      const res = await resetPassword();
      console.log({ res });
    } catch (err) {
      console.log({ err });
    }
  };

  const isValid = formState?.isValid;
  return (
    <ForgotPasswordStyled>
      <div className="logo">
        <img src={logo} alt={"asd"} />
      </div>
      <div className="heading">
        <IconButton onClick={onBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h1"
          color={"primary"}
          sx={{ margin: "35px 0px 20px 0px" }}
        >
          Forgot Password
        </Typography>
      </div>

      <div className="form">
        <TextInputFormField
          name="email"
          label="Email"
          control={control}
          placeholder="Enter here"
        />
      </div>

      <div className="btn">
        <Button sx={{ width: "100%" }} disabled={!isValid} onClick={onReset}>
          Send reset password link
        </Button>
      </div>
    </ForgotPasswordStyled>
  );
};

export default ForgotPassword;
