export enum ACTION_TYPES {
    SHOW_DETAILS = "SHOW_DETAILS",
    EDIT = "EDIT",
    ADD = "ADD",
    ADD_ACCOUNT = "ADD_ACCOUNT",
    DELETE = "DELETE",
    INVITE_USER = "INVITE_USER",
    REVOKE_ACCESS = "REVOKE_ACCESS",
    GIVE_ACCESS = "GIVE_ACCESS",
}

export const CONTACT_TYPES = [
    "Front Desk",
    "Personal Cell",
    "Assistant"
]

export enum ACCOUNT_STATUSES {
    PENDING = "pending",
    ACTIVE = "active",
    NOT_ACTIVE = "not active"
}