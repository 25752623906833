import { MenuItem, Select, Typography } from "@mui/material";
import { SelectFieldStyled } from "./select-field.styles";
import { SelectFieldProps } from "../common-types";

const SelectField = (props: SelectFieldProps) => {
  const {
    name,
    label,
    value,
    error,
    disabled,
    options,
    multiple,
    className,
    placeholder,
    onChange,
    InputProps,
  } = props;
  return (
    <SelectFieldStyled>
      <div>
        {label && (
          <Typography
            color={"primary"}
            variant="body3"
            style={{ fontWeight: "600" }}
          >
            {label}
          </Typography>
        )}
        <Select
          sx={{ width: "100%" }}
          value={value}
          id={name}
          onChange={onChange}
          error={!!error}
          multiple={multiple}
          placeholder={placeholder}
          disabled={disabled}
        >
          {options?.map(({ label, value }, index) => {
            return <MenuItem value={value} key={index}>{label}</MenuItem>;
          })}
        </Select>
      </div>
    </SelectFieldStyled>
  );
};

export default SelectField;