import React from "react";
import { TextFieldStyle } from "./text-input-field.style";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { TextFieldProps } from "../common-types";

const TextInputField = (props: TextFieldProps) => {
  const {
    name,
    label,
    value,
    error,
    disabled,
    inputType,
    className,
    placeholder,
    onChange,
    helperText = null,
    InputProps,
  } = props;
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <TextFieldStyle className={className}>
      <div>
        {label && (
          <Typography
            color={"primary"}
            variant="body3"
            style={{ fontWeight: "600" }}
          >
            {label}
          </Typography>
        )}
        <TextField
          sx={{ width: "100%" }}
          value={value}
          id={name}
          type={showPassword ? "text" : inputType}
          onChange={onChange}
          error={!!error}
          placeholder={placeholder}
          helperText={error?.message || helperText || null}
          disabled={disabled}
          autoComplete="off"
          InputProps={{
            ...InputProps,
            endAdornment:
              inputType === "password" ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    disableRipple
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ) : InputProps?.endAdornment ? (
                InputProps.endAdornment
              ) : (
                <span />
              ),
          }}
        />
      </div>
    </TextFieldStyle>
  );
};

export default TextInputField;
