import styled from 'styled-components';

export const ProgressBarsStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    .progress-bar {
        border-radius: 5px;
        width: 4px;
        display: inline-block;
        height: 28.89px;
    }
    .active {
        background-color: #27B824;
    }
    .inactive {
        background-color: #C7C7C7;
    }
`