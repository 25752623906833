export const CALLS_LIST_MOCK = [
  {
    id: 1,
    callerName: "Joshua Davidddd",
    callerPhoneNumber: "(555) 012-3451",
    callingTime: "Today - 12:00 PM",
    statusChips: [{ variant: "danger", label: "COD" }],
    callType: "ongoing",
    doctorName: "Dr. Jane Viago",
    doctorLocation: "Upper East Dental",
    progressLevel: 2,
    hasAttachment: false,
    orderStartedCount: 8,
    orderShippedCount: 26,
  },
  {
    id: 2,
    callerName: "Rile Rossouw",
    callerPhoneNumber: "(123) 422-3451",
    callingTime: "Today - 10:00 AM",
    statusChips: [
      { variant: "success", label: "UFS" },
      { variant: "danger", label: "COD" },
    ],
    callType: "outgoing",
    doctorName: "",
    doctorLocation: "",
    progressLevel: 2,
    hasAttachment: true,
    orderStartedCount: 18,
    orderShippedCount: 10,
  },
  {
    id: 3,
    callerName: "Joshua David",
    callerPhoneNumber: "(555) 012-4567",
    callingTime: "Yesterday - 12:00 PM",
    statusChips: [],
    callType: "incoming",
    doctorName: "Dr. Hanvaf Rodri...",
    doctorLocation: "Polyster Dental...",
    progressLevel: 1,
    hasAttachment: false,
    orderStartedCount: 8,
    orderShippedCount: 18,
  },
  {
    id: 4,
    callerName: "Paul Levesque",
    callerPhoneNumber: "(555) 012-4567",
    callingTime: "14 Apr 2022 - 12:00 PM",
    statusChips: [{ variant: "danger", label: "COD" }],
    callType: "missed",
    doctorName: "Dr. Robert Jean",
    doctorLocation: "Ensure Dental",
    progressLevel: 3,
    hasAttachment: false,
    orderStartedCount: 11,
    orderShippedCount: 33,
  },
  {
    id: 5,
    callerName: "Rile Rossouw",
    callerPhoneNumber: "(123) 422-3451",
    callingTime: "13 Apr 2022 - 10:00 AM",
    statusChips: [
      { variant: "success", label: "UFS" },
      { variant: "danger", label: "COD" },
    ],
    callType: "returned",
    doctorName: "Dr. Aiden Mark",
    doctorLocation: "Citi Hospital",
    progressLevel: 2,
    hasAttachment: true,
    orderStartedCount: 18,
    orderShippedCount: 10,
  },
  {
    id: 6,
    callerName: "Rile Rossouw",
    callerPhoneNumber: "(123) 422-3451",
    callingTime: "13 Apr 2022 - 10:00 AM",
    statusChips: [
      { variant: "success", label: "UFS" },
      { variant: "danger", label: "COD" },
    ],
    callType: "returned",
    doctorName: "Dr. Aiden Mark",
    doctorLocation: "Citi Hospital",
    progressLevel: 2,
    hasAttachment: true,
    orderStartedCount: 18,
    orderShippedCount: 10,
  },
];
