import styled from 'styled-components';

export const ActionsBarStyled = styled.div`
    display: inline-flex;
    align-items: center;
    border-left: 1px solid #CFCFCF;
    padding: 0 0 0 8px;
    gap: 13.66px;

    .play-icon {
        width: 11.83px;
        height: 15.22px;
    }
    .download-con {
        width: 16.67px;
        height: 16.67px;
    }
`
