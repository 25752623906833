import React from 'react';
import { StyledChatButton } from './chat-button.styles'
import chatIcon from "../../../../assets/icons/chat.svg"
import { Button } from '@mui/material';


const ChatButton: React.FC<{ count?: number, onClick: any, open: boolean }> = ({ count, onClick, open }) => {
  return (
    <StyledChatButton>
      <Button
        onClick={onClick}
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        disableFocusRipple
        disableRipple
        disableTouchRipple
        className="chat-wrapper">
        <div className="icon-wrapper">
          <img src={chatIcon} alt="Chat" width={18} className="d-block" />
          {(typeof count != 'undefined' && count > 0) && <div className="chat-circle">{`${count > 9 ? '9+' : count}`}</div>}
        </div>
      </Button>
    </StyledChatButton>
  );
};

export default ChatButton;
