import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import styled from "styled-components";

export const SwipeableDrawerStyled = styled(SwipeableDrawer)`
  > .MuiPaper-root {
    border-radius: 10px;
    box-shadow: 0px 0px 4px 0px #F0F0F066;
    width: 372px;
    padding: 16px;
  }
`

export const EditContactStyled = styled.div`
  .header {
    .title {
      color: #404040;
      font-size: 24px;
      font-weight: 600;
    }
    .close-icon {
      cursor: pointer;
      .MuiSvgIcon-root {
        font-size: 20px;
        path {
          color: #6E7378;
        }
      }
    }
  }

  .fields-wrapper {
    padding: 20px 0;
    > div {
      gap: 20px;
    }
  }
`
