import { Box } from "@mui/material";
import styled from "styled-components";

export const ModalFormStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    > div {
        margin: 0;
        > div {
            margin: 0;
            display: flex;
            flex-direction: column;
            gap: 6px;
            .MuiTypography-root {
                font-weight: 500 !important;
                font-size: 12px;
                color: #444444;
                height: 20px;
                align-content: center;
            }
            .MuiFormControl-root {}
            .MuiInputBase-root {
                height: 40px;
                .MuiInputBase-input {
                    font-size: 14px;
                    font-weight: 400;
                    &::placeholder {
                        color: #606060;
                    }
                }
                .MuiOutlinedInput-notchedOutline {
                    border: 1px solid #C3CCD7;
                    border-radius: 10px;
                    box-shadow: 0px 1px 2px 0px #1018280D;
                }
            }
            .react-tel-input {
                .form-control {
                    width: 100%;
                    height: 44px;
                    border: 1px solid #D0D5DD;
                    border-radius: 10px;
                    box-shadow: 0px 1px 2px 0px #1018280D;
                    font-size: 16px;
                    font-weight: 400;
                    &::placeholder {
                        color: #667085;
                    }
                }
            }
        }
    }
`

export const ModalFormButtonsBox = styled(Box)`
    display: flex;
    gap: 24px;
    padding-top: 12px;
    > button {
        width: 100%;
        height: 44px;
        display: inline-flex;
        align-items: center;
        border-radius: 10px;
        box-shadow: none !important;
        font-weight: 600;
        font-size: 16px;
        &:hover {
            background: #2460B8 !important;
            color: #fff !important;
        }
    }
`