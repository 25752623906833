export const TEAMS_MOCK = [
  {
    id: 1,
    name: "Team 1",
    type: "QA 1",
    locations: "City 1, City 2, City 3",
    extensions: "1, 2, 3, 4",
    accounts: "Account 1, Account 2"
  },
  {
    id: 2,
    name: "North Team 2",
    type: "Quality Assurance 2",
    locations: "City 1, City 2, City 3",
    extensions: "132, 132, 34, 42",
    accounts: "abc@gmail.com, john@example.com, xyz@gmail.com"
  },
  {
    id: 3,
    name: "Team 3",
    type: "QA 3",
    locations: "City 1, City 2, City 3",
    extensions: "1, 2, 3, 4",
    accounts: "Account 1, Account 2"
  },
  {
    id: 4,
    name: "North Team 4",
    type: "Quality Assurance 4",
    locations: "City 1, City 2, City 3",
    extensions: "132, 132, 34, 42",
    accounts: "abc@gmail.com, john@example.com, xyz@gmail.com"
  },
  {
    id: 5,
    name: "Team 5",
    type: "QA 5",
    locations: "City 1, City 2, City 3, Tokiyo, Japan, Indonasia, USA, France",
    extensions: "1, 2, 3, 4",
    accounts: "Account 1, Account 2"
  },
  {
    id: 6,
    name: "North Team 6",
    type: "Quality Assurance 6",
    locations: "City 1, City 2, City 3",
    extensions: "132, 132, 34, 42",
    accounts: "abc@gmail.com, john@example.com, xyz@gmail.com"
  },
]

export let currentId = TEAMS_MOCK.length
