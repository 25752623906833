import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { StyledAppBar, StyledToolbar, Logo } from './header-bar.styles';
import { clearUserSession, getSessionUser } from "../../utils/common-utils";
import logo from "../../assets/imgs/logo.png";
import MenuDropdown from '../menu/menu';
import userPlaceholder from "../../assets/imgs/user-placeholder.png";
import SwitchAccountModal from '../switch-account-modal/switch-account-modal';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const HeaderBar: React.FC = () => {
  const auth = getSessionUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentAccount, setCurrentAccount] = useState({
    name: `${auth?.user?.firstName} ${auth?.user?.lastName}`.trim(),
    email: `${auth?.user?.email}`,
    avatarUrl: 'https://via.placeholder.com/150',
  });
  const [profileMenuOpened, setProfileMenuOpened] = useState<boolean>(false)
  const isDashboard = location.pathname === "/dashboard";
  const isAdmin = location.pathname === "/admin";

  const handleRoleChange = () => {
    let url = ""
    if (isDashboard) {
      url = "/admin"
    } else if (isAdmin) {
      url = "/dashboard"
    }
    window.open(url, "_blank");
  }

  const handleLogout = () => {
    clearUserSession()
    navigate('/login')
  }

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSwitchAccount = (account: { name: string; email: string; avatarUrl: string }) => {
    setCurrentAccount(account);
    handleCloseModal();
  };

  const dropdownData = [
    {
      label: "Edit profile",
      key: "edit-profile",
      isDisabled: false,
      frontIcon: <></>,
      action: () => { }
    },
    {
      label: "Settings",
      key: "settings",
      isDisabled: false,
      frontIcon: <></>,
      action: () => { }
    },
    {
      label: "Switch account",
      key: "switch-account",
      isDisabled: false,
      frontIcon: <></>,
      action: handleOpenModal
    },
    {
      label: isDashboard ? "Go to Admin" : "Go to User",
      key: "role-change",
      isDisabled: false,
      frontIcon: <></>,
      action: handleRoleChange
    },
    {
      label: "Logout",
      key: "logout",
      isDisabled: false,
      frontIcon: <></>,
      action: handleLogout
    }
  ]

  const accounts = [currentAccount];

  const linkToOpen = <>
    <span>
      <img
        src={userPlaceholder}
        className="placeholder-img"
        alt="Placeholder on header bar"
      />
    </span>
    <span className="dropdown-title">{currentAccount.name}</span>
    <span>
      {!profileMenuOpened && <ArrowDropDownIcon className="dropdown-icon" />}
      {profileMenuOpened && <ArrowDropUpIcon className="dropdown-icon" />}
    </span>
  </>

  return (
    <StyledAppBar position="static">
      <StyledToolbar>
        <div className="logo">
          <Logo src={logo} alt="Logo" />
        </div>
        <div className='ml-auto'>
          <MenuDropdown
            items={dropdownData}
            linkToOpen={linkToOpen}
            isOpened={(state: boolean) => { setProfileMenuOpened(state) }}
          />
        </div>
        <SwitchAccountModal
          open={modalOpen}
          onClose={handleCloseModal}
          accounts={accounts}
          currentAccount={currentAccount}
          onSwitchAccount={handleSwitchAccount}
        />
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default HeaderBar;
