import { Box } from "@mui/material";
import styled from "styled-components";

export const TeamDetailsModalContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    .type-and-extension {
        > div {
            width: 50%;
        }
    }
    .title {
        font-weight: 500;
        font-size: 12px;
        color: #444444;
        margin-bottom: 1px;
    }
    .content {
        font-weight: 400;
        font-size: 14px;
        color: #222222;
    }

    .tags {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        padding-top: 7px;
        .tag {
            background: #FFFFFF;
            border-radius: 8px;
            color: #404040;
            font-weight: 400;
            font-size: 14px;
            padding: 2.5px 8px;
        }
    }
`

export const GrayBox = styled(Box)`
    background: #F0F4FA;
    border-radius: 10px;
    padding: 16px;
`

export const ButtonsBox = styled(Box)`
    display: flex;
    gap: 24px;
    padding-top: 20px;
    > button {
        width: 50%;
        height: 44px;
        display: inline-flex;
        align-items: center;
        border-radius: 10px;
        box-shadow: none;
        font-weight: 600;
        font-size: 16px;
        &.MuiButton-outlined {
            border-color: #2460B8;
            color: #2460B8;
            &:hover {
                background: #fff !important;
                border: 1px solid #2460B8;
                color: #2460B8 !important;
            }
        }
        &:hover {
            background: #2460B8 !important;
            color: #fff !important;
            border: 1px solid #2460B8;
        }
    }
`