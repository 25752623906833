import { Controller } from "react-hook-form";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import {
  AdditionalFormFieldProps,
  PhoneNumberFieldProps,
} from "../common-types";
import { PhoneNumberFieldStyled } from "./phone-number-field.style";
import { Typography } from "@mui/material";

const PhoneNumberFormField: React.FC<
  PhoneNumberFieldProps & AdditionalFormFieldProps
> = (props) => {
  const { control, name, label, defaultValue } = props;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { ref, value, ...field }, fieldState: { error } }) => (
        <PhoneNumberFieldStyled>
          <div>
            {label && (
              <Typography
                color={"primary"}
                variant="body3"
                style={{ fontWeight: "600" }}
              >
                {label}
              </Typography>
            )}
            <PhoneInput
              {...field}
              value={value}
              inputProps={{
                ref,
              }}
              country={"us"}
              countryCodeEditable={false}
            />
          </div>
        </PhoneNumberFieldStyled>
      )}
    />
  );
};

export default PhoneNumberFormField;
