import React from 'react';

import { CallCountWithIconContainerStyled } from './call-count-with-icon.styles';

import phoneCall from '../../../../assets/imgs/phone-call.svg';
import outgoingCall from '../../../../assets/imgs/phone-outgoing.svg';
import incomingCall from '../../../../assets/imgs/phone-incoming.svg';
import phoneReturned from '../../../../assets/imgs/phone-returned.svg';
import phoneMissed from '../../../../assets/imgs/phone-missed.svg';


const typeToImageMap: Record<string, string> = {
  ongoing: phoneCall,
  outgoing: outgoingCall,
  incoming: incomingCall,
  returned: phoneReturned,
  missed: phoneMissed,
};

interface CallCountWithIconProps {
  type: string;
  count: number;
}

const CallCountWithIcon: React.FC<CallCountWithIconProps> = ({ type, count }) => {
  const imageSrc = typeToImageMap[type];

  return (
    <CallCountWithIconContainerStyled>
      <img src={imageSrc} alt={`${type} icon`} />
      <span>{count}</span>
    </CallCountWithIconContainerStyled>
  );
};

export default CallCountWithIcon;

