import React from 'react';
import { Button, Typography } from '@mui/material';
import { Card } from './call-card.styles';
import CallMissedIcon from '../../../../assets/imgs/call-missed.svg'

interface CallComponentProps {
    doctorName: string;
    phoneNumber: string;
    callTime: string;
    imageUrl: string;
    onDismiss: () => void;
    onCallback: () => void;
}



const CallComponent: React.FC<CallComponentProps> = ({ doctorName, phoneNumber, callTime, imageUrl, onDismiss, onCallback }) => {
    return (
        <Card>
            <div className="d-flex call-image">
            <img src={CallMissedIcon} alt="Call Missed Icon" width="24" height="24"/>
            </div>
            <div className="card">
                <div className="image" />
                <div className="content">
                    <div className="header">
                        <div className="d-flex flex-column call-detail">
                            <Typography variant="h6" className="doctor-name">{doctorName}</Typography>
                            <Typography variant="body1" className="phone-number">{phoneNumber}</Typography>
                        </div>
                        <Typography variant="body2" className="call-time d-flex align-items-center">{callTime}</Typography>
                    </div>

                    <div className="actions">
                        <Button className="dismiss" variant="outlined" color="secondary" onClick={onDismiss}>Dismiss</Button>
                        <Button className="call-back" variant="contained" color="primary" onClick={onCallback}>Callback</Button>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default CallComponent;
