// SwitchAccountModal.tsx
import React from 'react';
import {
    Modal,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Radio,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';
import {StyledModalBox } from './switch-account-model.styles'

interface Account {
    name: string;
    email: string;
    avatarUrl: string;
}

interface SwitchAccountModalProps {
    open: boolean;
    onClose: () => void;
    currentAccount: Account;
    accounts: Account[];
    onSwitchAccount: (account: Account) => void;
}

const SwitchAccountModal: React.FC<SwitchAccountModalProps> = ({
    open,
    onClose,
    accounts,
    currentAccount,
    onSwitchAccount,
}) => {

    return (
        <Modal
            open={open}
            onClose={onClose}
            id="switch-account"
            aria-labelledby="switch-account-modal-title"
            aria-describedby="switch-account-modal-description"
        >
            <StyledModalBox>
                <div className="modal-header">
                    <p id="switch-account-modal-title" className="heading">
                        Switch accounts
                    </p>
                </div>
                <RadioGroup
                    aria-label="accounts"
                    name="accounts"
                >
                    <List>
                        {accounts.map((account) => (
                            <ListItem key={account.email} onClick={() => onSwitchAccount(account)}>
                                <ListItemAvatar>
                                    <Avatar src={account.avatarUrl} />
                                </ListItemAvatar>
                                <ListItemText primary={account.name} />
                                <FormControlLabel
                                    value={account}
                                    control={<Radio checked={currentAccount.email === account.email} sx={{
                                        color: currentAccount.email === account.email ? 'primary.main' : 'default',
                                        '&.Mui-checked': {
                                            color: '#2460B8',
                                        },
                                    }}/>}
                                    label=""
                                />
                            </ListItem>
                        ))}
                    </List>
                </RadioGroup>
            </StyledModalBox>
        </Modal>
    );
};

export default SwitchAccountModal;
