import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { NotificationType, UiState } from "./ui.slice.type";

const initialState: UiState = {
  notifications: [],
};
export const UISlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addNotification: (state, { payload }: PayloadAction<NotificationType>) => {
      state.notifications.push(payload);
    },
    clearNotifications: (state) => {
      state.notifications = [];
    },
    deleteNotification: (
      state,
      { payload }: PayloadAction<{ id?: string }>
    ) => {
      state.notifications = state.notifications.filter(
        (x) => x.id !== payload?.id
      );
    },
  },
});

export const { addNotification, clearNotifications, deleteNotification } =
  UISlice.actions;

export default UISlice.reducer;
