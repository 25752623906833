import { ModalFormStyled } from "./modal-form.styles"

interface ModalFormProps {
    children: any
}

const ModalForm: React.FC<ModalFormProps> = ({ children }) => {
    return (
        <ModalFormStyled>
            {children}
        </ModalFormStyled>
    )
}

export default ModalForm