import React from 'react';
import { WarningIconIconLinkStyled } from './warning-icon-link.styles';

import warningIconImage from '../../../../assets/icons/warning.svg';

const WarningIconLink: React.FC = () => {
    return (
        <WarningIconIconLinkStyled>
            <a href='#'>
                <img src={warningIconImage} alt="Warning" className="warning-icon" />
            </a>
        </WarningIconIconLinkStyled>
    );
};

export default WarningIconLink;
