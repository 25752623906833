import styled from 'styled-components';

export const DoctorDetailsStyled = styled.div`
    display: inline-flex;
    align-items: center;
    width: 55%;
    border-left: 1px solid #CFCFCF;
    padding: 0 0 0 8px;
    .inner-container {
        gap: 12px;
    }
    .item-container {
        gap: 8px;
    }
    .text {
        font-weight: 500;
        font-size: 12px;
        margin: 0;
        color: #202020;
    }
    .add-to-contacts {
        cursor: pointer;
        color: #2460B8;
        font-size: 14px;
        font-weight: 600;
    }
`