export const columns = [
    {
      id: "name",
      label: "Name",
    },
    {
      id: "email",
      label: "Email"
    },
    {
      id: "telephone",
      label: "Phone No.",
    },
    {
      id: "fax",
      label: "Fax",
    },
    {
      id: "address",
      label: "Address",
    },
    {
      id: "actions",
      label: "",
    },
  ]