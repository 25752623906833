import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Signup from "../pages/sign-up/signup";
import Login from "../pages/login/login";
import ForgotPassword from "../pages/forgot-password/forgot-password";
import AuthLayout from "../layouts/auth-layout";
import { useAppDispatch } from "../store/hooks";
import { setAdmin } from "../store/slices/admin-slice";
import UpdatePassword from "../pages/update-password/update-password";

const GuestRoutes: React.FC = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      setAdmin(false)
    )
  }, [])

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/sign-up" element={
        <AuthLayout>
          <Signup />
        </AuthLayout>
      } />
      <Route path="/login" element={
        <AuthLayout>
          <Login />
        </AuthLayout>
      } />
      <Route path="/forgot-password" element={
        <AuthLayout>
          <ForgotPassword />
        </AuthLayout>
      } />
      <Route path="/update-password" element={
        <AuthLayout>
          <UpdatePassword />
        </AuthLayout>
      } />
    </Routes>
  );
};

export default GuestRoutes;
