import React from 'react';

import { ActionsBarStyled } from './actions-bar.styles';
import playIconImage from '../../../../assets/icons/play.svg'
import downloadIconImage from '../../../../assets/icons/download.svg'

const ActionsBar: React.FC = () => {
    return (
        <ActionsBarStyled>
            <a href='#'>
                <img src={playIconImage} alt="Play" className="play-icon" />
            </a>
            <a href='#'>
                <img src={downloadIconImage} alt="Download" className="download-icon" />
            </a>
        </ActionsBarStyled>
    );
};

export default ActionsBar;
