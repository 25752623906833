import { ButtonStyled } from "./button.style";
import { ButtonProps, CircularProgress } from "@mui/material";
import { ButtonType } from "../../types/types";

export const Button = (props: ButtonType & ButtonProps) => {
  const {
    type = "button",
    size = "small",
    variant = "contained",
    disableRipple = true,
    isLoading,
    startIcon,
    children,
    disabled,
    ...rest
  } = props;

  return (
    <ButtonStyled
      {...rest}
      type={type}
      variant={variant}
      size={size}
      disableRipple={disableRipple}
      startIcon={
        isLoading ? <CircularProgress size={18} color={"inherit"} /> : startIcon
      }
      disabled={disabled || isLoading}
      style={{ textTransform: "none" }}
    >
      {children}
    </ButtonStyled>
  );
};
