import { Box, Popover } from "@mui/material";
import styled from "styled-components";

export const StyledChats = styled.div`
  padding: 20px 12px;
  border-radius: 10px;
  background: #fff;
  z-index: 9;
  &.chat {
    width: 268px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 100%;
  }

  .chat-wrapper {
    gap: 12px;
    margin-top: 16px;
  }

  .chats-title {
    font-size: 16px;
    font-weight: 600;
    font-family: "Poppins";
    color: #404040;
  }

  .chat-item {
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 0;

    &:last-child {
      border-bottom: none;
    }

    .chat-title {
      font-size: 18px;
      font-weight: 500;
    }

    .chat-description {
      font-size: 14px;
      color: #555;
    }
  }
`;

export const PopoverStyled = styled(Popover)`
  .MuiPaper-root {
    width: 310px;
    border-radius: 10px;
    box-shadow: 0px 4px 50px 0px #0000002E;
    padding: 16px 0 34px 0;
    background-color: #fff;
    transform: translateY(-7px) !important;
    height: 542px;
    display: flex;
    flex-direction: column;
    .chats-header {
      padding: 0 12px;
      display: flex;
      flex-direction: column;
      gap: 14px;
      .title {
        color: #404040;
        font-size: 16px;
        font-weight: 600;
      }
      .back-icon, .close-icon, .minimize-icon {
        cursor: pointer;
        .MuiSvgIcon-root {
          display: block;
          font-size: 20px;
          color: #666666;
        }
      }
    }
    .chats-body {
      padding: 12px 11px 0 8px;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
    }
  }
`

export const SearchAndActionStyled = styled.div`
  .search-and-action-root {
    padding: 0;
    .search-input {
      flex-grow: 1;
      height: 32px;
      font-size: 12px;
      font-weight: 400;
    }
  }
`

export const TabsContainer = styled(Box)`
    .tab-list {
        border-bottom: 1px solid #e0e0e0;
        > div {
          flex-grow: 1;
          .MuiTabs-root {
            min-height: auto;
            .MuiTabs-flexContainer {
                .MuiTab-root {
                  font-weight: 600;
                  color: #70767B;
                  font-size: 12px;
                  text-transform: none;
                  padding: 0 10px;
                  height: 42px;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  flex-grow: 1;
                  &.Mui-selected {
                      color: #2460B8 !important;
                  }
              }
            }
          }
        }
    }
    .MuiTabPanel-root {
      padding: 0;
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
`