import { Box } from "@mui/material";
import styled from "styled-components";

export const ModalComponentStyled = styled.div`
`

export const BoxStyled = styled(Box) <{ width?: string }>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${p => (p.width || '418px')};
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(240, 240, 240, 0.4);
    border-radius: 10px;
    padding: 16px 17px 24px 16px;
    outline: none;
    .title-container {
        .MuiTypography-root {
            font-weight: 600;
            font-size: 16px;
            color: #404040;
        }
        .close-icon {
            width: 20px;
            height: 20px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .MuiSvgIcon-root {
                color: #70767B;
                display: block;
                width: 20px;
                height: 20px;
            }
        }
    }
    .content-container {
        padding-top: 20px;
    }
`

export const DeleteModalStyled = styled.div`
    display: flex;
    gap: 28px;
    flex-direction: column;
    .content {
        font-weight: 400;
        font-size: 14px;
        b {
            font-weight: 600;
        }
    }
    .confirm-button {
        height: 44px;
        color: #fff;
        background: #B83F24 !important;
        box-shadow: none !important;
        width: 100%;
        font-weight: 600;
        font-size: 16px;
    }
`