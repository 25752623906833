import styled from 'styled-components';

export const CallCountWithIconContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  img {
    width: 24px;
    height: 24px;
  }

  span {
    font-weight: 600;
    font-size: 16px;
    color: #404040;
  }
`;
