import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import ModalComponent from "../../../../../../components/modal-component/modal-component";
import { Button } from "../../../../../../components/button/button";
import { addAccountFormValidation } from "../../../../../../utils/validations/index";
import ModalForm from "../../../../../../components/modal-form/modal-form";
import { ModalFormButtonsBox } from "../../../../../../components/modal-form/modal-form.styles";
import { useAppDispatch } from "../../../../../../store/hooks";
import { addNotification } from "../../../../../../store/slices/ui-slice";
import SelectFormField from "../../../../../../components/form-fields/select-field/select-form-field";
import { AddAccountsModalProps } from "./types";
import {
  User,
  useUpdateTeamMutation,
} from "../../../../../../generated/graphql";

const ModalBody: React.FC<AddAccountsModalProps> = ({
  data,
  refetch,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { control, watch, formState } = useForm({
    mode: "all",
    resolver: yupResolver(addAccountFormValidation()),
  });

  const [updateTeam] = useUpdateTeamMutation({
    variables: {
      ...data,
      id: `${data.id}`,
      company_id: 1,
      users:
        data.users?.map((x: User) => x.email).filter((x: string) => x) || [],
      administrator:
        watch()
          .administrator?.map((x) => x)
          .filter((x) => x) || [],
    },
  });

  const handleSubmit = async () => {
    try {
      const res = await updateTeam();
      refetch && refetch();
      dispatch(
        addNotification({
          id: uuid(),
          type: "success",
          message:
            res?.data?.updateTeam?.message ||
            "Team admin created successfully.",
        })
      );
    } catch (err: any) {
      dispatch(
        addNotification({
          id: uuid(),
          type: "error",
          message: err?.message,
        })
      );
    } finally {
      setIsLoading(false);
    }
    onClose && onClose();
  };

  const isValid = formState?.isValid;
  const users = data?.users?.map((x: User) => {
    return { label: x.email, value: x.email };
  });
  return (
    <ModalForm>
      <SelectFormField
        name="administrator"
        control={control}
        placeholder="Select here"
        label="Administrator"
        multiple={true}
        options={users}
      />
      <ModalFormButtonsBox>
        <Button
          disabled={!isValid}
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          {"Confirm"}
        </Button>
      </ModalFormButtonsBox>
    </ModalForm>
  );
};

const AddAccountsModal: React.FC<AddAccountsModalProps> = ({
  data,
  onClose,
  refetch,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    setOpenModal(true);
  }, []);

  const handleClose = () => {
    setOpenModal(false);
    onClose && onClose();
  };

  return (
    <div>
      <ModalComponent
        title={`Add Admin to ${data?.name}`}
        content={
          <ModalBody data={data} refetch={refetch} onClose={handleClose} />
        }
        open={openModal}
        onClose={handleClose}
      />
    </div>
  );
};

export default AddAccountsModal;
