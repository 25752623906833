import React, { useState, useEffect } from 'react'
import { v4 as uuid } from "uuid"

import ModalComponent from "../../../../../../components/modal-component/modal-component"
import { Button } from "../../../../../../components/button/button"
import { RemoveTeamModalBodyStyled } from './remove-team-modal.styles'
import { Typography } from '@mui/material'
import { useAppDispatch } from '../../../../../../store/hooks'
import { addNotification } from '../../../../../../store/slices/ui-slice'
import { RemoveTeamModalProps } from './types'
import { useDeleteTeamMutation } from '../../../../../../generated/graphql'


const ModalBody: React.FC<RemoveTeamModalProps> = ({ data, refetch, onClose }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const dispatch = useAppDispatch()

    const [deleteTeam] = useDeleteTeamMutation({
        variables: {
            id: Number(data.id)
        }
    })

    const handleClick = async () => {
        try {
            const res = await deleteTeam()
            refetch && refetch()
            dispatch(
                addNotification({
                    id: uuid(),
                    type: "success",
                    message: res?.data?.deleteTeam?.message || "Team removed successfully.",
                })
            )
        } catch (err: any) {
            dispatch(
                addNotification({
                    id: uuid(),
                    type: "error",
                    message: err?.message,
                })
            )
        } finally {
            setIsLoading(false)
        }
        onClose && onClose()
    }
    return (
        <RemoveTeamModalBodyStyled>
            <Typography className='content'>Are you sure to remove <b>{data.name}</b>?</Typography>
            <Button className="confirm-button" isLoading={isLoading} onClick={handleClick}>Confirm</Button>
        </RemoveTeamModalBodyStyled>
    )
}

const RemoveTeamModal: React.FC<RemoveTeamModalProps> = ({ data, onClose, refetch }) => {
    const [openModal, setOpenModal] = useState<boolean>(false)

    useEffect(() => {
        setOpenModal(true)
    }, [])

    const handleClose = () => {
        setOpenModal(false)
        onClose && onClose()
    }

    return (
        <div>
            <ModalComponent
                title="Remove Team"
                content={<ModalBody data={data} refetch={refetch} onClose={onClose} />}
                open={openModal}
                onClose={handleClose}
            />
        </div>
    )
}

export default RemoveTeamModal
