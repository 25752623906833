import React, { useEffect, useState } from "react"
import SearchAndAction from "../../../../../components/search-and-filter/search-and-filter"
import TableComponent from "../../../../../components/table-component/table-component"
import SplitTextIntoPopover from "../../../../../components/split-text-into-popover/split-text-into-popover"
import ShowDetailsModal from "./show-details-modal/show-details-modal"
import MenuDropdown from "../../../../../components/menu/menu"
import { TableMenuDropdownStyled } from "./teams.styles"
import { ACTION_TYPES } from "../../../../../constants"
import AddEditTeamModal from "./add-edit-team-modal/add-edit-team-modal"
import AddAccountsModal from "./add-accounts-modal/add-accounts-modal"
import RemoveAccountModal from "./remove-team-modal/remove-team-modal"
import { columns } from "./constants"
import { PaginatorInfo } from "../../../../../types/types"
import useDebouncedCallback from "../../../../../hooks/useDebouncedCallback"
import { Team, useTeamsQuery } from "../../../../../generated/graphql"

const Teams: React.FC = () => {
  const [modalData, setModalData] = useState<Team | undefined>()
  const [teamsData, setTeamsData] = useState<any[] | undefined>([])
  const [paginatorInfo, setPaginatorInfo] = useState<PaginatorInfo>()

  const [actionType, setActionType] = useState<string>("")

  const onAddAccount = (data: Team) => {
    setModalData(data)
    setActionType(ACTION_TYPES.ADD_ACCOUNT)
  }
  const onEditTeam = (data: Team) => {
    setModalData(data)
    setActionType(ACTION_TYPES.EDIT)
  }

  const dropdownData = [
    {
      label: "Show details",
      key: "show-details",
      action: (data: Team) => {
        setModalData(data)
        setActionType(ACTION_TYPES.SHOW_DETAILS)
      },
      isDisabled: false,
    },
    {
      label: "Edit",
      key: "edit",
      action: (data: Team) => {
        onEditTeam(data)
      },
      isDisabled: false,
    },
    {
      label: "Add admin",
      key: "add-admin",
      action: (data: Team) => {
        onAddAccount(data)
      },
      isDisabled: false,
    },
    {
      label: "Remove",
      key: "remove",
      action: (data: Team) => {
        setModalData(data)
        setActionType(ACTION_TYPES.DELETE)
      },
      isDisabled: false,
    },
  ]

  const handleAction = () => {
    setModalData(undefined)
    setActionType(ACTION_TYPES.ADD)
  }

  const resetActionType = () => {
    // To make sure fading out effect is completed first
    setTimeout(() => {
      setActionType("")
    }, 500)
  }

  const { loading, error, data, refetch } = useTeamsQuery({
    variables: { first: 10, page: 1 },
    notifyOnNetworkStatusChange: true
  })

  const handleSearch = useDebouncedCallback((e: React.BaseSyntheticEvent) => {
    refetch({
      first: 10,
      page: 1,
      search: e.target.value,
    })
  })


  useEffect(() => {
    if (data) {
      const teamsDataMapped: any[] | undefined = data?.teams?.data.map(team => {
        return {
          name: team.name,
          type: team.type,
          extensions: team.extensions,
          locations: <SplitTextIntoPopover text={team.locations} length={4} />,
          actions: (
            <TableMenuDropdownStyled>
              <MenuDropdown items={dropdownData} data={team} />
            </TableMenuDropdownStyled>
          ),
        }
      })
      setTeamsData(teamsDataMapped)
      setPaginatorInfo({
        ...(data?.teams?.paginatorInfo || {}),
        onPageChange: newPage => {
          refetch({
            first: 10,
            page: newPage
          })
        }
      })
    }
  }, [data])

  if (error) return <p>Error: {error.message}</p>

  return (
    <>
      <SearchAndAction
        showActionButton={true}
        onSearch={handleSearch}
        actionButtonTitle="Add team"
        actionButtonFn={handleAction}
      />
      <TableComponent isLoading={loading} columns={columns} data={teamsData} paginatorInfo={paginatorInfo} />
      {actionType === ACTION_TYPES.SHOW_DETAILS && modalData && (
        <ShowDetailsModal data={modalData} onClose={resetActionType} onAddAccount={onAddAccount} onEditTeam={onEditTeam} />
      )}
      {(actionType === ACTION_TYPES.ADD ||
        actionType === ACTION_TYPES.EDIT) && (
          <AddEditTeamModal refetch={refetch} onClose={resetActionType} data={modalData} />
        )}
      {actionType === ACTION_TYPES.ADD_ACCOUNT && modalData && (
        <AddAccountsModal refetch={refetch} onClose={resetActionType} data={modalData} />
      )}
      {actionType === ACTION_TYPES.DELETE && modalData && (
        <RemoveAccountModal refetch={refetch} onClose={resetActionType} data={modalData} />
      )}
    </>
  )
}

export default Teams
