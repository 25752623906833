//lib
import React from "react";
import { v4 as uuid } from "uuid";
import { useForm } from "react-hook-form";
import { Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";

//src
import logo from "../../assets/imgs/logo.png";
import { UpdatePasswordStyled } from "./update-password.styles";
import { Button } from "../../components/button/button";
import TextInputFormField from "../../components/form-fields/text-input-field/text-input-form-field";
import { updatePasswordValidation } from "../../utils/validations";
import { useUpdatePasswordMutation } from "../../generated/graphql";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addNotification } from "../../store/slices/ui-slice";

const UpdatePassword = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email") || "";
  const token = searchParams.get("token") || "";

  const { control, watch, formState } = useForm({
    mode: "all",
    resolver: yupResolver(updatePasswordValidation()),
  });

  const formValues = watch();

  const [updatePassword] = useUpdatePasswordMutation({
    variables: {
      email: email,
      password: formValues?.password,
      confirmPassword: formValues?.password,
      token: token,
    },
  });

  const handleUpdatePassword = async () => {
    try {
      const res = await updatePassword();

      if (res?.data?.updatePassword?.status === 200) {
        navigate("/login");
        dispatch(
          addNotification({
            id: uuid(),
            type: "success",
            message: "Password has been successfully updated.",
          })
        );
      }

      if (res?.data?.updatePassword?.status === 400) {
        dispatch(
          addNotification({
            id: uuid(),
            type: "error",
            message:
              res?.data?.updatePassword?.message ||
              "Unable to update password. ",
          })
        );
      }
    } catch (err) {}
  };
  const isValid = formState?.isValid;
  return (
    <UpdatePasswordStyled>
      <div className="logo">
        <img src={logo} alt={"asd"} />
      </div>
      <div className="heading">
        <Typography
          variant="h1"
          color={"primary"}
          sx={{ margin: "35px 0px 20px 0px" }}
        >
          Update Password
        </Typography>
      </div>

      <div className="form">
        <TextInputFormField
          name="password"
          label="Password"
          control={control}
          inputType="password"
          placeholder="Enter here"
        />
        <TextInputFormField
          name="confirm"
          label="Confirm Password"
          inputType="password"
          control={control}
          placeholder="Enter here"
        />
      </div>

      <div className="btn">
        <Button
          sx={{ width: "100%" }}
          disabled={!isValid}
          onClick={handleUpdatePassword}
        >
          Update Password
        </Button>
      </div>
    </UpdatePasswordStyled>
  );
};

export default UpdatePassword;
