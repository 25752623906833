import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { StyledNotifications } from './notifications.styles';
import CallComponent from './components/call-card/call-card'
import MessageComponent from './components/message-card/message-card';
// import { useNotificationsListQuery } from "../../generated/graphql"
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import NotificationButton from '../calls-dashboard/components/notification-button/notification-button';
import { Notification } from '../../types/types';


const Notifications: React.FC = () => {

    const [isVisible, setIsVisible] = useState(false)

    // const { loading, error, data } = useNotificationsListQuery();

    // if (loading) return <p>Loading...</p>;
    // if (error) return <p>Error: {error.message}</p>;

    const notificationsData: any = [] //data?.notificationsList?.list;
    const handleDismiss = () => {
        console.log('Dismiss clicked');
    };

    const handleCallback = () => {
        console.log('Callback clicked');
    };
    const handleReply = (replyMessage: string) => {
        console.log('Reply message:', replyMessage);
    };

    const openNotifications = () => {
        setIsVisible(true)
    }

    const closeNotifications = () => {
        setIsVisible(false)
    }

    const DrawerPaperProps: any = {
        style: { position: 'absolute' }
    }

    const DrawerModalProps: any = {
        container: document.querySelector('.app'),
        style: { position: 'absolute' }
    }

    return (
        <>
            <SwipeableDrawer
                anchor="right"
                open={isVisible}
                onClose={closeNotifications}
                onOpen={openNotifications}
                PaperProps={DrawerPaperProps}
                ModalProps={DrawerModalProps}
                transitionDuration={300}
            >
                <StyledNotifications className="notification">
                    <Typography variant="h6" component="div" className="notifications-title">
                        Notifications
                    </Typography>
                    <div className="d-flex flex-column notification-wrapper">
                        {notificationsData?.map((notification: Notification, index: number) => (
                            <React.Fragment key={index}>
                                {notification.type === 'call' && <CallComponent
                                    doctorName={notification.doctorName}
                                    phoneNumber={notification.phoneNumber}
                                    callTime={notification.time}
                                    imageUrl="https://via.placeholder.com/50"
                                    onDismiss={handleDismiss}
                                    onCallback={handleCallback}
                                />}
                                {notification.type === 'message' && <MessageComponent
                                    doctorName={notification.doctorName}
                                    phoneNumber={notification.phoneNumber}
                                    messageTime={notification.time}
                                    message={notification.message}
                                    onDismiss={handleDismiss}
                                    onReply={handleReply}
                                />}
                            </React.Fragment>
                        ))}
                        {!notificationsData.length && <div>No records.</div>}
                    </div>
                </StyledNotifications>
            </SwipeableDrawer>
            <div onClick={openNotifications}>
                <NotificationButton isVisible={notificationsData.length} />
            </div>
        </>
    );
};

export default Notifications;
