import styled from "styled-components";

export const Card = styled.div`
  position: relative;
  .card {
    position: relative;
    border-radius: 10px;
    padding: 9px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #f5f5f5;
  }

  .image {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
  }

  .description {
    font-size: 12px;
    font-weight: 400;
    font-family: "Poppins";
    color: #202020;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .message-detail {
        gap: 2px;
      }
      .doctor-name {
        font-size: 12px;
        font-weight: 500;
        font-family: "Poppins";
        color: #202020;
      }
      .message-time {
        font-size: 10px;
        font-weight: 400;
        font-family: "Poppins";
        color: #6e7378;
      }
      .phone-number {
        font-size: 11px;
        font-weight: 500;
        font-family: "Poppins";
        color: #6e7378;
      }
    }

    .actions {
      margin-top: 8px;
      display: flex;
      gap: 16px;
      width: 100%;

      button {
        text-transform: capitalize;
        padding: 0;
        border: none;
        background: transparent;
        box-shadow: none;
        border-radius: none;
      }
      .dismiss {
        color: #475467;
      }
      .reply-back {
        color: #2460b8;
      }
    }
  }
  .reply-section {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .reply-input {
      margin-bottom: 8px;
    }
  }
`;
