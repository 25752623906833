import React from 'react';
import { ProgressBarsStyled } from './progress-bars.styles';
import { ProgressBarsProps } from '../../../../types/types';

const ProgressBars: React.FC<ProgressBarsProps> = ({ level }) => {
    return (
        <ProgressBarsStyled>

            <div className={`progress-bar ${level === 3 ? 'active' : 'inactive'}`}></div>
            <div className={`progress-bar ${level > 1 && level <= 3 ? 'active' : 'inactive'}`}></div>
            <div className={`progress-bar ${level > 0 ? 'active': 'inactive'}`}></div>
        </ProgressBarsStyled>
    );
};

export default ProgressBars;
