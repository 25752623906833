import React from 'react';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { StyledNotificationButton } from './notification-button.styles'
import { NotificationButtonProps } from '../../../../types/types';


const NotificationButton: React.FC<NotificationButtonProps> = ({isVisible}) => {
  return (
    <StyledNotificationButton>
      <div className="notification-wrapper">
        <div className="icon-wrapper">
          <NotificationsOutlinedIcon />
          {isVisible > 0 && <div className="notification-circle" />}
        </div>
      </div>
    </StyledNotificationButton>
  );
};

export default NotificationButton;
