import React, { useEffect, useState } from "react"
import SearchAndAction from "../../../../../components/search-and-filter/search-and-filter"
import TableComponent from "../../../../../components/table-component/table-component"
import { columns } from "./constants"
import { PaginatorInfo } from "../../../../../types/types"
import useDebouncedCallback from "../../../../../hooks/useDebouncedCallback"
import { useOfficesQuery } from "../../../../../generated/graphql"

const Offices: React.FC = () => {
  const [officesData, setOfficesData] = useState<any[] | undefined>([])
  const [paginatorInfo, setPaginatorInfo] = useState<PaginatorInfo>()

  const { loading, error, data, refetch } = useOfficesQuery({
    variables: { first: 10, page: 1 },
    notifyOnNetworkStatusChange: true
  })

  const handleSearch = useDebouncedCallback((e: React.BaseSyntheticEvent) => {
    refetch({
      page: 1,
      first: 10,
      search: e.target.value
    })
  })

  const parseAddress = (address?: string): string => {
    const addressObj = JSON.parse(address || '{}')
    return Object.values(addressObj)
      .filter((x: any) => x.trim())
      .join(', ')
  }

  useEffect(() => {
    if (data) {
      const officesDataMapped: any[] | undefined = data?.offices?.data?.map((office) => {
        return {
          ...office,
          address: parseAddress(office.address)
        }
      })
      setOfficesData(officesDataMapped)
      setPaginatorInfo({
        ...(data?.offices?.paginatorInfo || {}),
        onPageChange: newPage => {
          refetch({
            first: 10,
            page: newPage
          })
        }
      })
    }
  }, [data])

  if (error) return <p>Error: {error.message}</p>

  return (
    <>
      <SearchAndAction
        showActionButton={true}
        actionButtonTitle="Add office"
        onSearch={handleSearch}
        actionButtonFn={() => { }}
      />
      <TableComponent
        isLoading={loading}
        paginatorInfo={paginatorInfo}
        columns={columns}
        data={officesData}
      />
    </>
  )
}

export default Offices
