import styled from "styled-components";

export const ChatDetailsStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .chats-header {
    border-bottom: 1px solid #CFCFCF;
    padding-bottom: 11px !important;
    .back-and-title-wrapper {
      gap: 8px;
    }
    .minimize-and-close-wrapper {
      gap: 10px;
    }
  }
  .chats-body {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    padding-bottom: 12px !important;
    gap: 23px;
    .message-container {
      display: flex;
      &.sent-message {
        justify-content: end;
        .chat-message {
          .message-body {
            border-top-right-radius: 0;
            border-top-left-radius: 10px;
            background: #2460B8;
            .message-content {
              color: #ffffff;
            }
          }
        }
      }
      .chat-message {
        max-width: 256px;
        .message-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 6px;
          min-width: 150px;
          gap: 8px;
          .sender-name {
            color: #344054;
            font-weight: 500;
            font-size: 14px;
          }
          .sending-time {
            color: #475467;
            font-size: 12px;
            font-weight: 400;
          }
        }
        .message-body {
          background: #F0F4FA;
          padding: 10px 14px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
          .message-content {
            color: #101828;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
    }
    .time-divider {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      span {
        display: block;
        border-bottom: 1px solid #DFDFDF;
        flex-grow: 1;
      }
      .MuiTypography-root {
        color: #475467;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .chats-footer {
    padding: 0 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: auto;
    padding-top: 12px;
    margin-bottom: -12px;
    .message-input {
      outline: none;
      border: 1px solid #D0D5DD;
      box-shadow: 0px 1px 2px 0px #1018280D;
      height: 44px;
      font-weight: 400;
      font-size: 16px;
      border-radius: 10px;
      padding: 10px 14px;
      flex-grow: 1;
      &::placeholder {
        color: #667085;
      }
    }
    .send-message-btn {
      background: #2460B8;
      border: none;
      outline: none;
      width: 44px;
      height: 44px;
      box-shadow: 0px 1px 2px 0px #1018280D;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      cursor: pointer;
      img {
        display: block;
      }
      &:disabled {
        opacity: 0.7;
        cursor: default;
      }
    }
  }
`;
