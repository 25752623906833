import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { DoctorDetailsStyled } from './doctor-details.styles';
import { DoctorDetailsProps } from '../../../../types/types';

import personIcon from '../../../../assets/icons/person.svg'
import buildingIcon from '../../../../assets/icons/building.svg'
import EditContactContext from '../../../../context/edit-contact-context';


const DoctorDetails: React.FC<DoctorDetailsProps> = ({
    contactId,
    doctorId,
    officeId,
    callerPhoneNumber,
    doctorName,
    officeName,
    refetchCalls
}) => {
    const { editContactData, setEditContactData } = useContext(EditContactContext)

    const handleAddToContact = () => {
        setEditContactData({
            id: contactId,
            name: doctorName,
            refetch: refetchCalls,
            callerPhoneNumber,
            doctorId,
            officeId,
        })
    }

    return (
        <DoctorDetailsStyled>
            <Box className="d-flex flex-column inner-container">
                {doctorName && <><Box className="d-flex align-items-center item-container">
                    <img src={personIcon} width='9.33px' height='10.5px' className='d-inline-block' alt="Person" />
                    <Typography className="text">{doctorName}</Typography>
                </Box>
                    <Box className="d-flex align-items-center item-container">
                        <img src={buildingIcon} width='10.5px' height='10.5px' className='d-inline-block' alt="Building" />
                        <Typography className="text">{officeName}</Typography>
                    </Box></>}
                <Box className="d-flex align-items-center item-container">
                    <Typography onClick={handleAddToContact} className="add-to-contacts">{doctorName ? 'Edit Contact' : 'Add to contacts'}</Typography>
                </Box>
            </Box>
        </DoctorDetailsStyled>
    );
};

export default DoctorDetails;
