import { TabContext, TabPanel } from "@mui/lab"
import { AdminDashboardContainer, TabsContainer } from "./admin-dashboard.styles"
import CustomTabs from "../../../components/custom-tabs/custom-tabs"
import { useState } from "react"
import Teams from "./components/teams/teams"
import Accounts from "./components/accounts/accounts"
import Doctors from "./components/doctors/doctors"
import Offices from "./components/offices/offices"

const AdminDashboard: React.FC = () => {
    const [activeTab, setActiveTab] = useState("Teams");

    const tabs = [
        "Teams",
        "Accounts",
        "Doctors",
        "Offices",
        "Contacts"
    ]

    const handleTabChange = (event: React.SyntheticEvent, newActiveTab: string) => {
        setActiveTab(newActiveTab);
    };

    return (<>
        <AdminDashboardContainer>
            <TabsContainer>
                <TabContext value={activeTab}>
                    <div className="tab-list d-flex w-100 align-items-center">
                        <CustomTabs handleTabChange={handleTabChange} tabs={tabs} />
                    </div>
                    <TabPanel className='px-0 py-12px' value="Teams">
                        <Teams />
                    </TabPanel>
                    <TabPanel className='px-0 py-12px' value="Accounts">
                        <Accounts />
                    </TabPanel>
                    <TabPanel className='px-0 py-12px' value="Doctors">
                        <Doctors />
                    </TabPanel>
                    <TabPanel className='px-0 py-12px' value="Offices">
                        <Offices />
                    </TabPanel>
                    <TabPanel className='px-0 py-12px' value="Contacts">
                        Contacts
                    </TabPanel>
                </TabContext>
            </TabsContainer>
        </AdminDashboardContainer >
    </>)
}

export default AdminDashboard