import React, { ReactNode, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from "../store/hooks";
import { isSomething } from "../utils/common-utils";
import { getSessionUser } from '../utils/common-utils';
import NotificaionsWrapper from "../components/notification/notification-wrapper";

interface AuthLayoutProps {
    children: ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
    const auth = getSessionUser();
    const token = auth?.token;
    const location = useLocation();
    const notifications = useAppSelector((state) => state.UI.notifications);
    const isAdmin = useAppSelector((state) => state.admin.isAdmin);

    useEffect(() => {
        if (isAdmin) {
            document.body.classList.add('admin-layout')
        } else {
            document.body.classList.remove('admin-layout')
        }
    }, [isAdmin])

    if (token) {
        return <Navigate to="/dashboard" state={{ from: location }} replace />;
    }

    return (
        <div className="auth-app">
            <div className="auth-app-wrapper">
                {isSomething(notifications) && <NotificaionsWrapper />}
                {children}
            </div>
        </div>
    )
}

export default AuthLayout