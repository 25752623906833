import React, { useState } from "react"

import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import CallTypeOverview from "./components/calls-overview/calls-overview-main-page"
import CustomTabs from "../../components/custom-tabs/custom-tabs"
import {
  CallsDashboardContainer,
  SectionTitle,
  HistoryComponent,
  HistoryTabs,
  AddIconStyled,
} from "./calls-dashboard.styles"
import HistoryCards from "./components/history-cards/history-cards"
import Notifications from "../notifications/notifications"
import Chats from "../chats/chats"
import { useCallsOverViewQuery } from "../../generated/graphql"

const MainPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState("All calls history")

  const tabs = ["All calls history"]

  const handleTabChange = (event: React.SyntheticEvent, newActiveTab: string) => {
    setActiveTab(newActiveTab)
  }

  const { loading, error, data, refetch } = useCallsOverViewQuery()

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error: {error.message}</p>
  const overview = data?.callsOverView[0]
  console.log('overview', overview);
  if (!overview) return <p>No data available</p>

  const callTypeOverviewData: { callType: 'ongoing' | 'missed' | 'returned' | 'incoming' | 'outgoing', count: number }[] = [
    { callType: 'ongoing', count: Number(overview.ongoingCallsTotal) | 0 },
    { callType: 'missed', count: Number(overview.missedCallsTotal) | 0 },
    { callType: 'returned', count: Number(overview.returnedCallsTotal) | 0 },
    { callType: 'incoming', count: Number(overview.incomingCallsTotal) | 0 },
    { callType: 'outgoing', count: Number(overview.outgoingCallsTotal) | 0 },
  ]

  return (
    <>
      <CallsDashboardContainer>
        <SectionTitle>
          Calls Overview
        </SectionTitle>
        <CallTypeOverview data={callTypeOverviewData} />
        <HistoryComponent>
          <HistoryTabs>
            <TabContext value={activeTab}>
              <div className="tab-list d-flex w-100 align-items-center">
                <CustomTabs handleTabChange={handleTabChange} tabs={tabs} />
                <AddIconStyled />
              </div>
              <TabPanel className='px-0 py-12px' value="All calls history">
                <HistoryCards refetchCallsOverview={refetch} />
              </TabPanel>
            </TabContext>
          </HistoryTabs>
        </HistoryComponent>
        <Chats />
        <Notifications />
      </CallsDashboardContainer>
    </>
  )
}

export default MainPage
