import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AdminState {
  isAdmin: boolean
}

const initialState: AdminState = {
  isAdmin: false,
}

const AdminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setAdmin: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload
    }
  },
})

export const { setAdmin } = AdminSlice.actions
export default AdminSlice.reducer
