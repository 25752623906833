export const NOTIFICATIONS_LIST_MOCK = [
  {
    id: 1,
    type: "call",
    doctorName: "Dr. John Doe",
    phoneNumber: "(123) 456-7890",
    time: "Today 10:30 AM",
  },
  {
    id: 2,
    type: "message",
    doctorName: "Dr. Mike Hart",
    phoneNumber: "(123) 456-7890",
    time: "Today 11:30 AM",
    message:
      "Needs an urgent repair on lower bridge for patient John Smith. Please expedite.",
  },
  {
    id: 3,
    type: "call",
    doctorName: "Dr. Edward Butler",
    phoneNumber: "(123) 456-7890",
    time: "Today 01:30 PM",
  },
  {
    id: 4,
    type: "message",
    doctorName: "Dr. Daniel",
    phoneNumber: "(123) 456-7890",
    time: "Yesterday 10:30 AM",
    message:
      "Needs an urgent replacement for patient Matthew Diaz's upper plate.",
  },
  {
    id: 5,
    type: "call",
    doctorName: "Dr. Augustin Beeber",
    phoneNumber: "(123) 456-7890",
    time: "12 July 2024",
  },
  {
    id: 6,
    type: "message",
    doctorName: "Dr. Anna Garcia",
    phoneNumber: "(123) 456-7890",
    time: "7 July 2024",
    message:
      "Inquires about a rush order for patient Robert Martinez's upper plate.",
  },
  {
    id: 7,
    type: "call",
    doctorName: "Dr. Sandra Wright",
    phoneNumber: "(123) 456-7890",
    time: "24 June 2024",
  },
  {
    id: 8,
    type: "message",
    doctorName: "Dr. Daniel",
    phoneNumber: "(123) 456-7890",
    time: "20 June 2024",
    message:
      "Requests a new mold for patient Emma Flores's upper dentures.",
  },
  {
    id: 9,
    type: "call",
    doctorName: "Dr. Augustin Beeber",
    phoneNumber: "(123) 456-7890",
    time: "11 June 2024",
  },
  {
    id: 10,
    type: "message",
    doctorName: "Dr. Vikram",
    phoneNumber: "(123) 456-7890",
    time: "03 June 2024",
    message:
      "Needs an urgent repair on lower bridge for patient John Smith. Please expedite.",
  },
  {
    id: 11,
    type: "message",
    doctorName: "Dr. Avinash",
    phoneNumber: "(123) 456-7890",
    time: "14 April 2024",
    message:
      "Needs an urgent repair on lower bridge for patient John Smith. Please expedite.",
  },
  {
    id: 12,
    type: "message",
    doctorName: "Dr. Anna",
    phoneNumber: "(123) 456-7890",
    time: "10 April 2024",
    message:
      "Needs an urgent repair on lower bridge for patient John Smith. Please expedite.",
  },
];
